import styled from 'styled-components'

export const Container = styled.div`
	align-items: center;
	background-color: #000;
	display: flex;
	font-family: Verdana, sans-serif;
	height: 100vh;
	justify-content: center;
	left: 0;
	margin: 0;
	padding: 0;
	position: relative;
	overflow: hidden;
	text-align: center;
	top: 0;
	width: 100vw;

	p {
		margin: 0px;
		padding: 0px;
	}

	.pageContent {
		height: 1920px;
		width: 1080px;
		/* background-color: #000; */
		background-size: cover !important;
		background-repeat: no-repeat !important;
		background-position: center !important;
		aspect-ratio: 0.5625;
		transform: scale(${(props) => (props.scala ? props.scala : 1)});

		.exclusive {
			margin-top: -22.5%;
		}
	}

	.buttonContent {
		width: 100%;
		display: block;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -29%);
	}

	video {
		position: absolute;
		display: flex;
		z-index: -1;
	}
`

export const HomeContainer = styled.div`
	.header-simple {
		width: 100%;
		height: 20px;
		background-color: #ddd8d2;
		@media (min-width: 1000px) {
			height: 40px;
		}
	}

	.content {
		width: 100%;
		min-height: calc(100vh - 20px);
		/* padding: 24px; */
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		@media (min-width: 1000px) {
			min-height: calc(100vh - 40px);
		}
	}

	.content-wrap {
		height: calc(100vh - 20px);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		max-height: 660px;
		padding: 24px;
		@media (min-width: 1000px) {
			height: calc(100vh - 40px);
			max-height: 800px;
		}
	}

	.logo-simular {
		max-width: 390px;
		width: 100%;
		height: auto;
		margin: 0 auto;
	}

	.description-action {
		text-align: center;
		font-size: 16px;
		font-weight: 500;
		text-transform: uppercase;
		line-height: 1.5;
		margin: 30px 0;
		max-width: 310px;
		b {
			font-weight: 800;
		}
		@media (min-width: 1000px) {
			font-size: 28px;
			max-width: 770px;
		}
	}

	.btn-brand {
		max-width: 324px;
		width: 100%;
		height: 85px;
		border-radius: 100px;
		border: 0;
		margin: 0 auto;
		display: block;
		img {
			width: auto;
		}
		&.portinari-btn {
			background: #ddd8d2;
			img {
				height: 33px;
				@media (min-width: 1000px) {
					height: 45px;
				}
			}
		}
		&.ceusa-btn {
			background: #fe5000;
			img {
				height: 23px;
				@media (min-width: 1000px) {
					height: 35px;
				}
			}
		}
		&.durafloor-btn {
			background: #edef8e;
			img {
				height: 33px;
				@media (min-width: 1000px) {
					height: 45px;
				}
			}
		}
		@media (min-width: 1000px) {
			max-width: 447px;
			height: 117px;
		}
	}
`
