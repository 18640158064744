import styled from 'styled-components'

const Container = styled.div`
	.header-totem {
		height: 150px;
		box-shadow: 0px 10px 44px 0px #00000026;
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			max-width: 300px;
			height: auto;
			width: 100%;
			display: block;
		}
	}

	.header {
		width: 100%;
		height: 40px;
		background-color: #ddd8d2;
		padding: 5px 24px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		@media (max-width: 999px) {
			height: 20px;
		}
		&.header-portinari {
			background-color: #ddd8d2;
		}
		&.header-ceusa {
			background-color: #fe5000;
			.btn-back {
				color: #ffffff;
			}
		}
		&.header-durafloor {
			background-color: #edef8e;
		}
		.btn-back {
			color: #29251f;
			font-size: 14px;
			font-weight: 600;
			text-transform: uppercase;
			@media (max-width: 999px) {
				font-size: 12px;
			}
			span:nth-child(2) {
				display: inline-block;
				margin-left: 5px;
			}
		}
	}

	.message {
		width: 100%;
		height: calc(100vh - 40px);
		min-height: 600px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding: 24px;
		@media (max-width: 999px) {
			min-height: 460px;
		}
		@media (max-width: 524px) {
			min-height: 320px;
		}
		&.totem {
			height: calc(100vh - 150px);
		}
	}

	.content-page {
		text-align: center;
		max-width: 862px;
		max-height: 586px;
		width: 100%;
		height: 100%;
		background-image: url('/404.svg');
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: contain;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		@media (max-width: 999px) {
			max-width: 500px;
			max-height: 340px;
		}
		@media (max-width: 524px) {
			max-width: 320px;
			max-height: 218px;
		}
	}

	.title {
		font-weight: 700;
		font-size: 72px;
		line-height: 84px;
		@media (max-width: 999px) {
			font-size: 36px;
			line-height: 1.2;
		}
		@media (max-width: 524px) {
			font-weight: 700;
			font-size: 26px;
		}
	}

	.description {
		font-weight: 400;
		font-size: 40px;
		line-height: 150%;
		@media (max-width: 999px) {
			font-size: 20px;
			line-height: 1.5;
		}
		@media (max-width: 524px) {
			font-weight: 400;
			font-size: 15px;
		}

		a {
			text-decoration: underline;
			color: #000000;
		}
	}

	.btn-redirect {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 250px;
		height: 50px;
		border-color: #d9d9d9;
		background: #666666;
		border-radius: 10px;
		color: #ffffff;
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 19px;
		transition: all 0.5s ease;
		&:hover {
			background-color: #c1c1c1cc;
		}
		@media (max-width: 999px) {
			font-size: 14px;
			height: 40px;
			width: 220px;
		}
		@media (max-width: 524px) {
			width: 170px;
			height: 30px;
			font-size: 10px;
			line-height: 16px;
			border-radius: 7px;
		}
	}
`

export default Container
