import { ReactComponent as PortinariLogo } from '../../assets/images/marca_porti.svg';
import { ReactComponent as CeusaLogo } from '../../assets/images/marca_ceusa.svg';
import { ReactComponent as DurafloorLogo } from '../../assets/images/marca_dura.svg';

function LogoBrandComponent(props) {
	const { brand } = props;

	const getBrandLogo = () => {
		if (brand === 'portinari') return <PortinariLogo />;
		if (brand === 'ceusa') return <CeusaLogo />;
		if (brand === 'durafloor') return <DurafloorLogo />;
		return '';
	};

	return getBrandLogo();
}

export default LogoBrandComponent;
