import styled from 'styled-components'

const Container = styled.div`
	display: flex;
	flex-wrap: wrap;

	@media (width: 1080px) and (height: 1920px) {
		width: 100%;
		display: flex !important;
		flex-wrap: wrap;
	}

	@media only screen and (max-width: 1919px) and (min-width: 1400px) {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}

	@media only screen and (max-width: 1399px) and (min-width: 1000px) {
		display: block;
	}
`

export default Container
