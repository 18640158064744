import styled from 'styled-components'

export const PopperContainer = styled.div`
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
	border-radius: 5px;
	background-color: white;
	/* padding: 20px; */
	z-index: 9999;
	max-width: 450px;
	width: 100%;
	@media (max-width: 999px) {
		max-width: 280px;
	}

	.close-button-tour {
		cursor: pointer;
	}

	.tour #tour1 {
		transform: translate(911px, 383px) !important;
	}

	#arrow {
		position: absolute;
		width: 10px;
		height: 10px;
		&:after {
			content: ' ';
			background-color: #acacac;
			box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
			position: absolute;
			transform: rotate(45deg);
			width: 10px;
			height: 10px;
		}
	}
	&[data-popper-placement^='top'] > #arrow {
		top: 100%;
		left: 50%;
		transform: translate(-50%, -50%);
		&:after {
			box-shadow: 1px 1px 1px rgb(0 0 0 / 10%);
			background-color: #fff;
		}
	}
	&[data-popper-placement^='bottom'] > #arrow {
		top: 0;
		left: 50%;
		transform: translate(-50%, -50%);
		&:after {
			box-shadow: none;
			background-color: #acacac;
			/* background-color: transparent; */
		}
	}
	&[data-popper-placement^='left'] > #arrow {
		top: 50%;
		left: 100%;
		transform: translate(-50%, -50%);
		&:after {
			box-shadow: 1px -1px 1px rgb(0 0 0 / 10%);
			background-color: #fff;
		}
	}
	&[data-popper-placement^='right'] > #arrow {
		top: 50%;
		left: 0;
		transform: translate(-50%, -50%);
		&:after {
			box-shadow: -1px 1px 1px rgb(0 0 0 / 10%);
			background-color: #fff;
		}
	}

	.text-primary {
		padding: 20px;
		font-weight: 500;
		background: #fff;
		z-index: 1;
		position: relative;
		font-size: 16px;
		line-height: 1.5;
		@media (max-width: 999px) {
			font-weight: 400;
			font-size: 12px;
		}
	}
	p {
		margin-bottom: 0rem;
		font-size: 12px;
		font-family: Roboto;
		color: #fff;
		margin-left: 10px;
	}

	.container-button {
		margin: 10px 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.button-next {
		background: #666;
		border-radius: 10px;
		color: #fff;
		padding: 5px;
		align-items: center;
		display: flex;
		font-weight: 700;
		font-size: 12px;
		text-transform: uppercase;
		border-color: #666;
		max-width: 120px;
		width: 100%;
		height: 40px;
		justify-content: center;
		&:hover {
			background-color: #cdcdcd;
		}
		@media (max-width: 999px) {
			max-width: 90px;
			height: 30px;
		}
	}

	button.ant-btn.ant-btn-default.button-close-tour {
		border: none;
		background-color: transparent;
		padding: 0px;
	}
	.button-close-tour.ant-btn {
		& > span {
			display: inline-block;
			border-bottom: 1.5px solid #a1a1a1;
			color: #a1a1a1;
			i {
				font-style: normal;
				color: #a1a1a1;
			}
		}
		i {
			font-style: normal;
			color: #a1a1a1;
			display: inline-block;
			padding-right: 5px;
			border-bottom: 1.5px solid #a1a1a1;
		}
	}

	&.totem {
		max-width: 560px;
		#arrow {
			width: 20px;
			height: 20px;
			&:after {
				width: 20px;
				height: 20px;
			}
		}
		span.close-button-tour svg {
			width: 25px;
		}
		p {
			font-size: 18px;
			font-weight: 700;
		}
		.text-primary {
			font-size: 22px;
		}
		.container-button {
			justify-content: flex-end;
		}
		.button-next {
			font-size: 20px;
			max-width: 190px;
			height: 50px;
		}
		button.ant-btn.ant-btn-default.button-close-tour {
			font-size: 20px;
			max-width: 190px;
			height: 50px;
			width: 100%;
			border: 1px solid #666666;
			border-radius: 10px;
			text-decoration: none;
			font-weight: 700;
			text-transform: uppercase;
			margin-right: 16px;
			& > span {
				border: 0;
			}
			i {
				display: none;
			}
			&:hover {
				background-color: #666;
				color: #fff;
				& > span {
					color: #fff;
					border: 0;
				}
			}
		}
	}
`

export const HeaderPopper = styled.div`
	background: #acacac;
	display: flex;
	padding: 0px 10px;
	align-items: center;
	justify-content: space-between;
	border-radius: 5px 5px 0px 0px;

	span {
		display: flex;
		align-items: center;
	}
	@media (width: 1080px) and (height: 1920px) {
		padding: 8px 10px !important;
	}
`

export const HeaderPopperClose = styled.div`
	background: #acacac;
	display: flex;
	padding: 4px 10px;
	align-items: center;
	justify-content: end;
	border-radius: 5px 5px 0px 0px;

	span {
		display: flex;
		align-items: center;
	}
	@media (width: 1080px) and (height: 1920px) {
		padding: 8px 10px !important;

		span.close-x svg {
			width: 20px;
		}
	}
`

export const PopperClose = styled.div`
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
	border-radius: 5px;
	background-color: white;
	text-align: center;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 10002;
	max-width: 410px;
	width: 100%;
	@media (max-width: 999px) {
		max-width: 240px;
	}
	.close-x {
		cursor: pointer;
	}

	h2 {
		font-weight: 700;
		font-size: 28px;
		margin: 10px auto 5px;
		@media (max-width: 999px) {
			font-size: 16px;
		}
	}

	.text-primary-close {
		padding: 0 20px;
		font-weight: 400;
		font-size: 22px;
		@media (max-width: 999px) {
			font-size: 12px;
		}
	}
	p {
		margin-bottom: 0rem;
		font-size: 12px;
		font-family: Roboto;
		color: #fff;
		margin-left: 10px;
	}

	.container-button {
		margin: 10px 30px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.button-next,
	.button-close-tour {
		width: 130px;
		height: 40px;
		border-radius: 10px;
		padding: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 700;
		text-transform: uppercase;
		font-size: 12px;
		line-height: 1.5;
		@media (max-width: 999px) {
			width: 90px;
			height: 30px;
		}
	}
	.button-next {
		background: #666;
		margin-left: 10px;
		border: 1px solid #666;
		color: #fff;
		& span {
			color: #fff;
		}
		&:hover {
			background-color: #cdcdcd;
		}
	}
	.button-close-tour {
		border: 0.5px solid #666;
		background-color: transparent;
		margin-right: 10px;
		color: #666;
		&:hover {
			background-color: #666;
			color: #fff;
		}
	}
	@media (width: 1080px) and (height: 1920px) {
		max-width: 560px !important;
		.close-x {
			min-height: 28px;
		}
		h2 {
			font-weight: 700;
			font-size: 36px;
			margin: 10px 0 5px;
		}
		button.ant-btn.ant-btn-default.button-close-tour {
			padding: 32px 32px;
		}
		.text-primary-close {
			font-weight: 400;
			font-size: 32px;
		}
		.container-button {
			margin: 25px 30px;
		}
		.button-next,
		.button-close-tour {
			width: 200px;
			height: 65px;
			font-weight: 700;
			font-size: 20px;
			justify-content: center;
			text-transform: uppercase;
		}
	}
`
