import React, { useState } from 'react'
import { Col, Row, message } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
// import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux'
// import TagManager from 'react-gtm-module';
import Container from './style'
import 'antd/dist/antd.css'
import background from '../../assets/images/bg_upload.png'
import { sendRoom } from '../../services/TotemApi/index'
import { postRoom } from '../../services/BFF/index'
import Intl from '../../utils/IntlMessages'
import { ReactComponent as LogoSimularPT } from '../../assets/images/marca_simu_B.svg'
import { ReactComponent as LogoSimularES } from '../../assets/images/logo-esp-black-simular.svg'
import { ReactComponent as LogoSimularEN } from '../../assets/images/logo-english-black-simular.svg'

function RoomUpload() {
	const {
		lang,
		// tour,
		// step
	} = useSelector((state) => state.sharedServiceReducer)

	// http://localhost:3000/share?id=b59a0a5d0a8641f2a5d846156686f9f2&pdv=00&lang=pt
	const queryParams = new URLSearchParams(window.location.search)
	const visitorParamId = queryParams.get('id')
	const companyParam = queryParams.get('company')
	// const pdvParam = queryParams.get('pdv');
	// const totem = queryParams.get('pdv') ? '1' : '0';
	const [, /* imgLoaded */ setImgLoaded] = useState(false)
	const [loading, setLoading] = useState(false)
	const [errorUpload, setErrorUpload] = useState(false)
	// const [imgData, setImgData] = useState();
	// const intl = useIntl()

	const sendMsg = (msg) => {
		sendRoom({ msg, visitorId: visitorParamId })
	}

	const onSelectFile = async (event) => {
		setLoading(true)
		setErrorUpload(false)
		try {
			let res = null
			if (event.target.files && event.target.files[0]) {
				res = await postRoom(event.target.files[0], event.target.files[0].name, visitorParamId, companyParam)
			}
			if (res && res.id) {
				setImgLoaded(true)
				sendMsg(res.id)
				message.success('Seu ambiente foi adicionado com sucesso!')
			}
			if (!res.id) {
				message.error('Tivemos um problema ao fazer o upload do seu ambiente. Por favor tente novamente.')
				throw new Error('MissingParameters')
			}
			setLoading(false)
		} catch (error) {
			setLoading(false)
			setErrorUpload(true)
		}
	}

	return (
		<Container background={background} pointer={loading}>
			<input type='file' onChange={onSelectFile} style={{ display: 'none' }} accept='image/*' id='input-file' />
			{/* <Row justify="center" className="row__logo">
				<img
					className="logo__simular"
					src={intl.formatMessage({ id: 'roomUpload.logo' })}
					alt="Logo Simular"
				/>
			</Row> */}
			<Row justify='center' className='row__logo'>
				<Col className='logo__simular'>
					{lang === 'pt' && <LogoSimularPT />}
					{lang === 'es' && <LogoSimularES />}
					{lang === 'en' && <LogoSimularEN />}
				</Col>
			</Row>
			<Row justify='center' className='row__upload'>
				<div className='upload'>
					{loading ? (
						<LoadingOutlined style={{ fontSize: 50, color: '#fff' }} spin />
					) : (
						<PlusOutlined
							style={{ fontSize: '45px', color: '#fff' }}
							onClick={() => document.getElementById('input-file').click()}
						/>
					)}
				</div>
			</Row>
			<Row justify='center' className='row__text'>
				{!errorUpload ? (
					<div className='col-12 text-center'>
						{loading ? (
							<>
								<h5 className='title'>Carregando</h5>
								<h6 className='sub-title'>Estamos fazendo o upload do seu ambiente.</h6>
							</>
						) : (
							<>
								<h5 className='title'>
									<Intl id='roomUpload.title' />
								</h5>
								<h6 className='sub-title'>
									<Intl id='roomUpload.subtitle' />
								</h6>
							</>
						)}
					</div>
				) : (
					<div className='col-12 text-center'>
						<h5 className='title' hidden=''>
							{' '}
							<Intl id='roomUpload.error1' />
							<br /> <Intl id='roomUpload.error2' />
						</h5>
					</div>
				)}
			</Row>
		</Container>
	)
}

export default RoomUpload
