import { combineReducers, configureStore } from '@reduxjs/toolkit';

import {
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from 'redux-persist';

import storage from 'redux-persist/lib/storage';

import sharedServiceReducer from './reducers/sharedService.reducer';

const persistConfig = {
	key: 'root',
	version: 1,
	storage,
};

const reducers = combineReducers({
	sharedServiceReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
	reducer: persistedReducer,
	// extraReducers: (builder) => {
	// 	builder.addCase(PURGE, (state) => {
	// 		customEntityAdapter.removeAll(state)
	// 	})
	// },

	// devTools: process.env.NODE_ENV !== 'production',

	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}),
});

export default store;

// import { configureStore } from '@reduxjs/toolkit';
// import sharedServiceReducer from './reducers/sharedService.reducer';

// export default configureStore({
// 	reducer: {
// 		sharedService: sharedServiceReducer,
// 	},
// });
