import React from 'react';
import { Button } from 'antd';
import ButtonContainer from './style';

function Halfpill(props) {
	const {
		className,
		href,
		onClick,
		width,
		height,
		borderColor,
		fontSize,
		direction,
		text,
		bgTheme,
		icon,
		iconColor,
		effectsColor,
	} = props;

	return (
		<ButtonContainer
			bgTheme={bgTheme}
			borderColor={borderColor}
			direction={direction}
			iconColor={iconColor}
			effectsColor={effectsColor}
		>
			<Button
				className={className}
				href={href}
				onClick={onClick}
				style={{
					width,
					height,
					backgroundColor: bgTheme,
					borderColor,
					fontSize,
				}}
				icon={icon}
			>
				{text}
			</Button>
		</ButtonContainer>
	);
}

export default Halfpill;
