import TagManager from 'react-gtm-module'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Col, Card, Row } from 'antd'
import { ReactComponent as CloseButton } from '../../../assets/icons/svg-X.svg'
import welcomeTourImg from '../../../assets/images/welcome_tour.png'
import Container from './style'
import { tourReducer, initialTourReducer } from '../../../store/reducers/sharedService.reducer'
import Intl from '../../../utils/IntlMessages'

function TourPresentation(props) {
	const { sku } = props
	const { totem, company, lang, pdv } = useSelector((state) => state.sharedServiceReducer)

	const dispatch = useDispatch()

	const closeTour = (pressedButton) => {
		if (pressedButton) {
			const dataLayer = {
				event: 'first_time_screen_delay',
				eventModel: {
					brand: company,
					pdv,
					language: lang,
					device: totem === '1',
					source_product_site: sku !== null,
				},
			}
			TagManager.dataLayer({
				dataLayer,
			})
		}
		dispatch(initialTourReducer(false))
	}

	const openTour = () => {
		const dataLayer = {
			event: 'first_time_screen_next',
			eventModel: {
				brand: company,
				pdv,
				language: lang,
				device: totem === '1',
				source_product_site: sku !== null,
			},
		}
		TagManager.dataLayer({
			dataLayer,
		})
		closeTour(false)
		dispatch(tourReducer(true))
	}

	return (
		<Container>
			<div className={totem === '1' ? 'welcome-tour totem' : 'welcome-tour'}>
				<Card className='card' cover={<img alt='example' src={welcomeTourImg} />}>
					<Button
						tabIndex={0}
						className='icon-x-close'
						onClick={() => closeTour(true)}
						onKeyPress={() => closeTour(true)}
					>
						<CloseButton />
					</Button>
					<p className='title'>
						<Intl id='presentation.title' />
						<b>
							Simular {company && company.replace(/(^\w{1})|(\s+\w{1})/g, (firstLetter) => firstLetter.toUpperCase())}!
						</b>
					</p>
					<p className='description'>
						<Intl id='presentation.description' />
					</p>
					<Row>
						<Col className='container-button-welcome-tour'>
							<Button className='button-close-welcome-tour' onClick={() => closeTour(true)}>
								<span>
									<Intl id='presentation.next1' />
								</span>
								<span>
									<Intl id='presentation.next2' />
								</span>
							</Button>
							<Button className='button-next' onClick={openTour}>
								<span>
									<Intl id='presentation.go1' />
								</span>
								<span>
									<Intl id='presentation.go2' />
								</span>
							</Button>
						</Col>
					</Row>
				</Card>
			</div>
		</Container>
	)
}

export default TourPresentation
