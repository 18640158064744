import { Select } from 'antd'
import { Container, LabelAndCounter } from './style'

function SelectCustom(props) {
	const { value, onChange, placeholder, className, options, counter, label } = props
	const dropdownCSS = {
		background: '#F7F6F6',
		border: '1px solid rgba(178, 177, 176, 0.4)',
		boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.25)',
		borderRadius: '10px',
		fontSize: '26px',
		lineHeight: '26px',
	}
	return (
		<Container>
			<Select
				value={value}
				onChange={onChange}
				mode='multiple'
				showArrow
				showSearch={false}
				dropdownStyle={dropdownCSS}
				placeholder={placeholder}
				className={className}
			>
				{options}
			</Select>
			<LabelAndCounter counter={counter} className='span-filter'>
				{label}
			</LabelAndCounter>
		</Container>
	)
}

export default SelectCustom
