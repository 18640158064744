import styled from 'styled-components'

const RoomsContainer = styled.div`
	font-family: Roboto;

	.header {
		height: 42px;
		width: 100%;
		font-size: 14px;
		font-weight: bold;
		position: fixed;
		z-index: 999;

		a {
			color: #29251f;
			display: flex;
		}
	}

	.portinari-header,
	.portinari-rooms-brand-bullet {
		background-color: #ddd8d2;
	}

	.ceusa-header,
	.ceusa-rooms-brand-bullet {
		background-color: #fe5000;

		a {
			color: white;
		}
		svg path {
			fill: white;
		}
	}

	.durafloor-header {
		background-color: #f2f2f2;
	}

	.durafloor-rooms-brand-bullet {
		background-color: #edef8e;
	}

	.back-to-site {
		margin-right: 30px;

		span {
			margin-right: 10px;
			line-height: 42px;
		}

		svg {
			height: 17px;
			margin-top: 11px;
		}
	}

	.logo-simular {
		height: 83px;
		margin-top: 117px;
		margin-bottom: 48px;
		max-width: calc(100vw - 20px);
	}

	.logo-simular-totem {
		height: 130px;
		margin-top: 180px;
		margin-bottom: 40px;
	}

	h2 {
		font-size: 28px;
		margin-bottom: 32px;
	}

	.tour-span {
		font-size: 22px;
		color: #656565;
		margin-right: 11px;
	}

	.tour-btn {
		background-color: #666666;
		border: #d9d9d9;
		border-radius: 10px;
		color: white;
		font-weight: 700;
		font-size: 16px;
		height: 49px;
		width: 144px;
		margin-bottom: 10px;
		&.portinari-tour-btn {
			background-color: #ddd8d2;
			color: #3e4345;
		}
		&.durafloor-tour-btn {
			background-color: #edef8e;
			color: #3e4345;
		}
		&.ceusa-tour-btn {
			background-color: #fe5000;
		}
	}

	[ant-click-animating-without-extra-node='true']::after {
		display: none;
	}

	.room-selection {
		height: 170px;
		width: 268px;
		margin-right: 40px;
		margin-bottom: 29px;
		border: unset;
		cursor: pointer;
		background-size: contain;
		background-repeat: round;

		span {
			color: white;
			text-transform: uppercase;
			font-size: 24px;
			font-weight: 600;
			position: relative;
			top: 132px;
			left: 11px;
		}
	}

	.room-selection-totem.right-box,
	.room-selection.right-box {
		margin-right: 0px;
		background-size: contain;
		background-repeat: round;
	}

	.room-selection-totem,
	.room-upload-totem {
		height: 255px;
		width: 390px;
		margin-right: 121px;
		margin-bottom: 97px;
		background-size: cover;
		border: unset;
		cursor: pointer;
		background-size: contain;
		background-repeat: round;

		span {
			color: #29251f;
			text-transform: uppercase;
			font-size: 30px;
			font-weight: 600;
			position: relative;
			top: 258px;
			left: 11px;
		}
	}

	.room-upload {
		height: 170px;
		width: 268px;
		margin-right: 40px;
		margin-bottom: 29px;
		/* background: rgba(196, 196, 196, 0.4); */
		background-color: #fafafa;
		padding: 0;
		border: unset;
		border-radius: 0;
		cursor: pointer;
		color: rgb(47, 47, 47);

		&:hover {
			color: inherit;
			background-color: #fafafa;
		}
		.ant-btn[disabled],
		.ant-btn[disabled]:hover {
			color: inherit;
			background-color: inherit;
		}

		.ant-upload.ant-upload-drag {
			border-color: transparent;
		}

		.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
			border-color: #40a9ff;
			border-style: dashed;
		}

		svg {
			position: relative;
			left: 84px;
		}

		.anticon-spin svg {
			position: static;
			height: 70px;
			width: 70px;
		}

		.room-upload-load {
			position: relative;
			left: 84px;
		}

		.room-upload-span {
			color: #2f2f2f;
			text-transform: uppercase;
			font-size: 24px;
			font-weight: 600;
			position: relative;
			top: 44px;
			left: -75px;
		}
	}

	.rooms-brand-bullet {
		width: 800px;
		height: 200px;
		border-radius: 100px;
		margin-bottom: 70px;
		position: relative;

		svg {
			position: absolute;
			top: calc(50%);
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.rooms-exit-button {
		margin-top: 40px;
		margin-right: 40px;
		width: 178px;
		height: 76px;
		border-radius: 38px;
		background: #ffffff;
		border: 2px solid #53565a;
		color: #53565a;
		font-size: 30px;
		font-weight: 600;

		svg {
			fill: #53565a;
			height: 23px;
			width: 30px;
			margin-left: 20px;
			position: relative;

			path {
				fill: #53565a;
			}
		}
	}

	.rooms-qr-banner {
		width: 990px;
		height: 267px;
		background: #e5e8e8;
		border-radius: 0px 100px 0px 0px;
		margin-bottom: 54px;
		font-size: 30px;
		text-transform: uppercase;
		color: #29251f;

		.rooms-qr-banner-code {
			margin-left: 100px;
			margin-right: 48px;
			margin-top: 35px;
		}

		.rooms-qr-banner-title {
			font-weight: 700;
		}

		.rooms-qr-banner-text-container {
			margin-top: 76px;
		}
	}

	.rooms-component-container-totem {
		max-height: 710px;
		overflow: scroll;
	}

	@media (max-width: 999px) {
		.header {
			height: 36px;
			font-size: 10px;
		}

		.back-to-site {
			margin-right: 16px;

			span {
				margin-right: 5px;
				line-height: 36px;
			}

			svg {
				height: 13.5px;
				margin-top: 10.5px;
			}
		}

		.logo-simular {
			height: 88px;
			margin-top: 56px;
			margin-bottom: 89px;
		}

		h2 {
			font-size: 16px;
			max-width: 275px;
			text-align: center;
			margin-bottom: 27px;
		}

		.tour-btn.ant-btn:active,
		.tour-btn.ant-btn:hover,
		.tour-btn.ant-btn:focus {
			border: #d9d9d9;
			font-size: 12px;
			height: 42px;
			width: 128px;
		}

		.tour-span {
			font-size: 14px;
		}

		.room-upload {
			height: 107px;
			width: 167px;
			margin-right: 16px;
			margin-bottom: 15px;

			svg,
			.anticon-spin svg {
				height: 44px;
				width: 44px;
				left: 48.5px;
			}

			.room-upload-load {
				left: 48.5px;
			}

			.room-upload-span {
				font-size: 14px;
				top: 29px;
				left: -51px;
			}
		}

		.room-selection {
			height: 107px;
			width: 167px;
			margin-right: 16px;
			margin-bottom: 15px;

			span {
				font-size: 14px;
				top: 86px;
				left: 4px;
			}
		}
	}
`
export default RoomsContainer
