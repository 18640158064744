import styled from 'styled-components'

const Container = styled.div`
	font-family: Roboto;
	box-sizing: border-box;
	height: 100vh !important;
	background: url(${(props) => props.background});
	/* background-position-x: -568px; */
	background-repeat: no-repeat;
	background-size: cover;

	.row__logo {
		padding-top: 184px;
	}
	.row__logo .logo__simular {
		min-width: 155px;
		height: 60px;
		box-sizing: border-box;

		svg {
			width: inherit;
			height: inherit;
		}
	}

	.row__upload {
		padding-top: 86px;
	}

	.upload {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 93px;
		height: 93px;
		border-radius: 93px;
		background: #29251f;
		fill: none;
		cursor: ${(props) => (props.pointer ? 'unset' : 'pointer')};

		.cls-2 {
			fill: #fff;
		}

		.base-loading {
			color: #fff;
		}
	}

	.row__text {
		padding-top: 30px;
	}

	h5 {
		font-size: 1.25rem;
		text-align: center;
	}

	.text-center {
		text-align: center;
	}

	input {
		font-size: 10px;
		background: transparent;
		border: none;
	}
`

export default Container
