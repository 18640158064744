import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { LeftOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import Container from './style'
import Intl from '../../utils/IntlMessages'

function NotFound() {
	const { totem, company } = useSelector((state) => state.sharedServiceReducer)
	const intl = useIntl()

	return (
		<Container>
			{totem === '1' ? (
				<div className='header-totem'>
					<Link to='/rooms'>
						<img src={intl.formatMessage({ id: 'notFound.logo' })} alt='Logo Simular' className='logo-simular' />
					</Link>
				</div>
			) : (
				<div className={company ? `header header-${company}` : 'header'}>
					<Link to='/rooms' className='btn-back'>
						<LeftOutlined />
						<span>
							<Intl id='notFound.changeEnv' />{' '}
						</span>
					</Link>
				</div>
			)}

			<div className={totem === '1' ? 'message totem' : 'message'}>
				<div className='content-page'>
					<div className='title'>
						<Intl id='notFound.title' />
					</div>
					<div className='description'>
						{totem === '1' ? (
							<Intl id='notFound.totemMessage' />
						) : (
							<>
								<Intl id='notFound.message1' />{' '}
								<Link to='/rooms'>
									<Intl id='notFound.message2' />
								</Link>
								.
							</>
						)}
					</div>
				</div>
				{totem !== '1' && (
					<Link to='/rooms' className='btn-redirect'>
						<Intl id='notFound.otherProducts' />
					</Link>
				)}
			</div>
		</Container>
	)
}

export default NotFound
