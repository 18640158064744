import { CloseOutlined } from '@ant-design/icons'
import Intl from '../../utils/IntlMessages'
import Select from '../Select'
import Container from './style'

function FilterComponent({ filterOptions, onFilterChange, clearFilter, filterSelected, company }) {
	return (
		<>
			<Container>
				<Select
					options={filterOptions.categoria}
					value={filterSelected.categoria}
					onChange={(e) => onFilterChange(e, 'categoria')}
					label={<Intl id='visualization.categories' />}
					counter={filterSelected.categoria?.length}
					className='visualization-filter-select'
				/>
				<Select
					options={filterOptions.cor}
					value={filterSelected.cor}
					onChange={(e) => onFilterChange(e, 'cor')}
					label={<Intl id='visualization.colors' />}
					counter={filterSelected.cor?.length}
					className='visualization-filter-select'
				/>
				{company !== 'durafloor' && (
					<Select
						options={filterOptions.visual}
						value={filterSelected.visual}
						onChange={(e) => onFilterChange(e, 'visual')}
						label={<Intl id='visualization.visuals' />}
						counter={filterSelected.visual?.length}
						className='visualization-filter-select'
					/>
				)}
				{company === 'durafloor' && (
					<Select
						options={filterOptions.forma}
						value={filterSelected.forma}
						onChange={(e) => onFilterChange(e, 'forma')}
						label={<Intl id='visualization.shapes' />}
						counter={filterSelected.forma?.length}
						className='visualization-filter-select'
					/>
				)}
				{company !== 'durafloor' && (
					<Select
						options={filterOptions.formato}
						value={filterSelected.formato}
						onChange={(e) => onFilterChange(e, 'formato')}
						label={<Intl id='visualization.formats' />}
						counter={filterSelected.formato?.length}
						className='visualization-filter-select'
					/>
				)}
				<Select
					options={filterOptions.colecao}
					value={filterSelected.colecao}
					onChange={(e) => onFilterChange(e, 'colecao')}
					label={<Intl id='visualization.collections' />}
					counter={filterSelected.colecao?.length}
					className='visualization-filter-select'
				/>
			</Container>
			<div
				className='visualization-filter-footer'
				onClick={() => clearFilter(false)}
				onKeyDown={() => {}}
				role='button'
				tabIndex={-1}
			>
				<CloseOutlined className='filter-clear-icon' />
				<Intl id='visualization.remove' />
			</div>
		</>
	)
}

export default FilterComponent
