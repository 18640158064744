/* eslint-disable */
import { io } from 'socket.io-client';
import { getTotemApi } from '../../env';

const url = getTotemApi();
// const url = 'http://localhost:3001/'
const IOEventName = {
	MESSAGE: 'message',
	RESPONSE: 'response',
};

const socket = io.connect(url);

export const setupSocketConnection = async () => {
	try {
		socket.emit(IOEventName.MESSAGE, 'Hello there from Totem.');
	} catch (error) {
		console.log(error);
	}
};

export const sendRoom = async (room) => {
	try {
		socket.emit(IOEventName.MESSAGE, room);
	} catch (error) {
		console.log(error);
	}
};

export const getMessage = () => {
	try {
		return socket.on(IOEventName.RESPONSE, (data) => data);
	} catch (error) {
		console.log(error);
	}
};
