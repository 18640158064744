import React, { useEffect } from 'react'
import saveAs from 'file-saver'
import { getNewVisualization } from '../../services/BFF'

function NewVisualization() {
	const { search } = window.location
	const params = new URLSearchParams(search)
	const roomId = params.get('room')
	const vendorId = params.get('vendor')
	const floorId = params.get('floor')
	const floorOrientation = params.get('floorO')
	const wallId = params.get('wall')
	const wallOrientation = params.get('wallO')
	const instances = params.get('instances')

	const instancesArray = instances ? instances.split(',') : []
	useEffect(() => {
		if (roomId && vendorId) {
			getNewVisualization(
				roomId,
				vendorId,
				floorId,
				floorOrientation,
				wallId,
				wallOrientation,
				instancesArray.length
					? instancesArray.map((instance) => {
							const res = {
								productType: 5,
								instance,
							}
							return res
					  })
					: []
			).then((data) => {
				saveAs(`data:image/jpeg;base64,${data}`, 'Minha simulação.jpg')
				setTimeout(() => {
					window.opener = null
					window.open('', '_self')
					window.close()
				}, 1000)
			})
		}
	}, [])

	return <div />
}

export default NewVisualization
