/* eslint-disable */
import axios from 'axios';
import { getBff, getTotemApi } from '../../env';

const url = getBff();
const urlTotem = getTotemApi();
let endpoint;

const vendor_url_path = {
	ceusa: 'ceusacombr',
	portinari: 'ceramicaportinaricombr',
	durafloor: 'meupisoidealcombr',
};

const defaultLang = 'pt';

//colocar no redux
let visitor_id;
let vendor_id;
let product;
//colocar no redux Filtros Piso
let floor_categoriesOptions;
let floor_colorsOptions;
let floor_speciesOptions;
let floor_widthOptions;
let floor_collectionsOptions;
let floor_shapesOptions;
//colocar no redux Filtros Piso Comparação
let floor_categoriesOptionsCompare;
let floor_colorsOptionsCompare;
let floor_speciesOptionsCompare;
let floor_widthOptionsCompare;
let floor_collectionsOptionsCompare;
let floor_shapesOptionsCompare;
//colocar no redux Filtros Parede
let wall_categoriesOptions;
let wall_colorsOptions;
let wall_speciesOptions;
let wall_widthOptions;
let wall_collectionsOptions;
let wall_shapesOptions;
//colocar no redux Filtro Parede Comparação
let wall_categoriesOptionsCompare;
let wall_colorsOptionsCompare;
let wall_speciesOptionsCompare;
let wall_widthOptionsCompare;
let wall_collectionsOptionsCompare;
let wall_shapesOptionsCompare;
//colocar no redux Filtros Piso
let categoriesOptions;
let colorsOptions;
let speciesOptions;
let widthOptions;
let collectionsOptions;
let shapesOptions;
//colocar no redux Filtros Piso Comparação
let categoriesOptionsCompare;
let colorsOptionsCompare;
let speciesOptionsCompare;
let widthOptionsCompare;
let collectionsOptionsCompare;
let shapesOptionsCompare;

// Endpoint que traz os dados iniciais.
export const init = async (company, lang) => {
	try{
		const vendorData = await getInitial(vendor_url_path[company], lang);
		return vendorData;
	} catch  (error) {
		console.log(error);
	}
};

export const getInitV2 = async (company) => {
	try {
		const endpoint = `visitor/${company}?format=json_camel_case`
		const res = await getDataV2(endpoint)
		return res.data
	} catch (error) {
		console.log(error)
	}
}

export const getMetaDataV2 = async (company, lang, productTypes) => {
	try {
		const endpoint = `list-metadata/${company}?lang=${lang}&product_types=${productTypes}&format=json_camel_case`
		const res = await getDataV2(endpoint)
		return res.data
	} catch (error) {
		console.log(error)
	}
}

// Endpoint que traz os dados iniciais.
// export const init = async () => {
// 	try {
// 		// const endpoint = 'v1/proxy-get/get_static_visualization?room_id=f45c30f718c047a6a314f8f737b6c6c3&product_id=2d633345&product_orientation=0&product_instance=1&display_width=1024&display_height=512&img_format=base64&add_watermark=false&last_modified_timestamp=0&vendor=5e20ecb749ce43bf8125b3e73c1a37ae'

// 		// const res = await getData(endpoint)
// 		let resultCeusa = await getInitial(vendor_url_path.ceusa);
// 		let resultPortinari = await getInitial(vendor_url_path.portinari);
// 		let resultDurafloor = await getInitial(vendor_url_path.durafloor);
// 		let vendor = {};

// 		vendor['ceusa'] = resultCeusa;
// 		vendor['portinari'] = resultPortinari;
// 		vendor['durafloor'] = resultDurafloor;
// 		return vendor;
// 	} catch (error) {
// 		console.log(error);
// 	}
// };

// Endpoint que traz os dados iniciais de cada vendor.
export const getInitial = async (company, lang) => {
	try {
		endpoint = '/get_initial_my_properties?formdata=true';
		const formdata = new FormData();
		formdata.append('vendor_url_path', company);
		formdata.append('is_in_top_window', 'true');
		formdata.append('domain', 'www.roomvo.com');
		formdata.append('locale', `${lang ? `${lang}-${`${lang==='en'?`${company==='ceusa'?'us':'gb'}`:lang}`}` : `pt-pt`}`);
		const res = await postData(endpoint, formdata);

		return res.data;
	} catch (error) {
		console.log(error);
	}
};

// Endpoint que traz os dados iniciais de cada vendor.
export const vendor = async (vendorData) => {
	try {
		// const vendor_data = await init()

		// visitor_id = vendor_data[company].visitor_id;
		visitor_id = vendorData.visitor_id;
		// vendor_id = vendor_data[company].vendor_id;
		vendor_id = vendorData.vendor_id;

		// product = vendor_data[company].products;
		product = vendorData.products;

		// const floor_product_metadatas = vendor_data[company].product_metadatas[0];
		const floor_product_metadatas = vendorData.product_metadatas[0];
		// const wall_product_metadatas = vendor_data[company].product_metadatas[1];
		const wall_product_metadatas = vendorData.product_metadatas[1];

		floor_categoriesOptions = floor_product_metadatas.categories.map(
			(category) => ({
				id: category,
				name: category.toLowerCase(),
				check: false,
			})
		);
		floor_colorsOptions = floor_product_metadatas.colors.map((color) => ({
			id: color,
			name: color.toLowerCase(),
			check: false,
		}));
		if (floor_product_metadatas.species)
			floor_speciesOptions = floor_product_metadatas.species.map((specie) => ({
				id: specie,
				name: specie.toLowerCase(),
				check: false,
			}));
		if (floor_product_metadatas.widthBuckets)
			floor_widthOptions = floor_product_metadatas.widthBuckets.map(
				(width) => ({ id: width, name: width.toLowerCase(), check: false })
			);
		if (floor_product_metadatas.collections)
			floor_collectionsOptions = floor_product_metadatas.collections.map(
				(collection) => ({
					id: collection,
					name: collection.toLowerCase(),
					check: false,
				})
			);
		if (floor_product_metadatas.shapes)
			floor_shapesOptions = floor_product_metadatas.shapes.map((shapes) => ({
				id: shapes,
				name: shapes.toLowerCase(),
				check: false,
			}));

		// floor_categoriesOptionsCompare = Array.isArray(floor_categoriesOptions) ? JSON.parse(JSON.stringify(floor_categoriesOptions)) : undefined
		// floor_colorsOptionsCompare = Array.isArray(floor_colorsOptions) ? JSON.parse(JSON.stringify(floor_colorsOptions)) : undefined
		// floor_speciesOptionsCompare = Array.isArray(floor_speciesOptions) ? JSON.parse(JSON.stringify(floor_speciesOptions)) : undefined
		// floor_widthOptionsCompare = Array.isArray(floor_widthOptions) ? JSON.parse(JSON.stringify(floor_widthOptions)) : undefined
		// floor_collectionsOptionsCompare = Array.isArray(floor_collectionsOptions) ? JSON.parse(JSON.stringify(floor_collectionsOptions)) : undefined
		// floor_shapesOptionsCompare = Array.isArray(floor_shapesOptions) ? JSON.parse(JSON.stringify(floor_shapesOptions)) : undefined

		if (wall_product_metadatas) {
			wall_categoriesOptions = wall_product_metadatas.categories.map(
				(category) => ({
					id: category,
					name: category.toLowerCase(),
					check: false,
				})
			);
			wall_colorsOptions = wall_product_metadatas.colors.map((color) => ({
				id: color,
				name: color.toLowerCase(),
				check: false,
			}));
			if (wall_product_metadatas.species)
				wall_speciesOptions = wall_product_metadatas.species.map((specie) => ({
					id: specie,
					name: specie.toLowerCase(),
					check: false,
				}));
			if (wall_product_metadatas.widthBuckets)
				wall_widthOptions = wall_product_metadatas.widthBuckets.map(
					(width) => ({ id: width, name: width.toLowerCase(), check: false })
				);
			if (wall_product_metadatas.collections)
				wall_collectionsOptions = wall_product_metadatas.collections.map(
					(collection) => ({
						id: collection,
						name: collection.toLowerCase(),
						check: false,
					})
				);
			if (wall_product_metadatas.shapes)
				wall_shapesOptions = wall_product_metadatas.shapes.map((shapes) => ({
					id: shapes,
					name: shapes.toLowerCase(),
					check: false,
				}));

			//   wall_categoriesOptionsCompare = Array.isArray(wall_categoriesOptions) ? JSON.parse(JSON.stringify(wall_categoriesOptions)) : undefined
			//   wall_colorsOptionsCompare = Array.isArray(wall_colorsOptions) ? JSON.parse(JSON.stringify(wall_colorsOptions)) : undefined
			//   wall_speciesOptionsCompare = Array.isArray(wall_speciesOptions) ? JSON.parse(JSON.stringify(wall_speciesOptions)) : undefined
			//   wall_widthOptionsCompare = Array.isArray(wall_widthOptions) ? JSON.parse(JSON.stringify(wall_widthOptions)) : undefined
			//   wall_collectionsOptionsCompare = Array.isArray(wall_collectionsOptions) ? JSON.parse(JSON.stringify(wall_collectionsOptions)) : undefined
			//   wall_shapesOptionsCompare = Array.isArray(wall_shapesOptions) ? JSON.parse(JSON.stringify(wall_shapesOptions)) : undefined
		}

		categoriesOptions = floor_categoriesOptions;
		colorsOptions = floor_colorsOptions;
		speciesOptions = floor_speciesOptions;
		widthOptions = floor_widthOptions;
		collectionsOptions = floor_collectionsOptions;
		shapesOptions = floor_shapesOptions;

		// categoriesOptionsCompare = floor_categoriesOptionsCompare
		// colorsOptionsCompare = floor_colorsOptionsCompare
		// speciesOptionsCompare = floor_speciesOptionsCompare
		// widthOptionsCompare = floor_widthOptionsCompare
		// collectionsOptionsCompare = floor_collectionsOptionsCompare
		// shapesOptionsCompare = floor_shapesOptionsCompare

		const floorOptions = {
			categoriesOptions: floor_categoriesOptions,
			colorsOptions: floor_colorsOptions,
			speciesOptions: floor_speciesOptions,
			widthOptions: floor_widthOptions,
			collectionsOptions: floor_collectionsOptions,
			shapesOptions: floor_shapesOptions,
		};

		const wallOptions = {
			categoriesOptions: wall_categoriesOptions,
			colorsOptions: wall_colorsOptions,
			speciesOptions: wall_speciesOptions,
			widthOptions: wall_widthOptions,
			collectionsOptions: wall_collectionsOptions,
			shapesOptions: wall_shapesOptions,
		};

		return {
			floorOptions,
			wallOptions,
		};
	} catch (error) {
		console.log(error);
	}
};

export const getFirstProducts = async (
	lang,
	vendor,
	company
) => {
	const pages={
		ceusa:
			{
				floor: 8,
				wall: 14
			},
		portinari:
			{
				floor: 16,
				wall: 24
			},
		durafloor:
			{
				floor: 4,
				wall: 0
			},
	}
	try {
		let promises = []
		for (let index = 0; index < pages[company].floor; index++) {
			// promises.push(
			// 	getData(
			// 		`/products?
			// 		is_hidden=0
			// 		&page=${index+1}
			// 		&locale=${lang ? `&locale=${lang}-${`${lang==='en'?`${company==='ceusa'?'us':'gb'}`:lang}`}` : `locale=pt-pt`};
			// 		&product_type=1
			// 		&vendor=${vendor}`
			// 	).then((data)=>data.data.results)
			// )
			promises.push(
				getDataV2(
					`list-products/${company}?lang=${lang}&format=json_camel_case&product_type=1&page=${index+1}`
				).then((data) => data.data.results)
			)
		}

		for (let index = 0; index < pages[company].wall; index++) {
			// promises.push(
			// 	getData(
			// 		`/products?
			// 		is_hidden=0
			// 		&page=${index+1}
			// 		&locale=${lang ? `&locale=${lang}-${`${lang==='en'?`${company==='ceusa'?'us':'gb'}`:lang}`}` : `pt-pt`};
			// 		&product_type=5
			// 		&vendor=${vendor}`
			// 	).then((data)=> data.data.results)
			// )
			promises.push(
				getDataV2(
					`list-products/${company}?lang=${lang}&format=json_camel_case&product_type=5&page=${index+1}`
				).then((data) => data.data.results)
			)
		}
		const products = await Promise.all(promises)
		
		const productsData = Array.prototype.concat.apply([], products);

		return productsData;
	} catch (error) {
		console.log(error);
	}
};

//Endpoit para Pegar os produtos
export const getProducts = async (
	lang,
	page,
	type,
	vendor,
	visitor,
	productId
) => {
	try {
		const relevantToProduct = `&relevant_to_product=${productId}`;
		const excludeRelevantToProduct = `&exclude_relevant_to_product=${productId}`;
		const mainParams = products(lang, page, type, vendor, visitor);
		const params = mainParams + relevantToProduct;
		const exclude = mainParams + excludeRelevantToProduct;

		const productsIn = await getData(params);
		const productsEx = await getData(exclude);

		const productsData = {
			results: await productsIn.data.results.concat(
				await productsEx.data.results
			),
			nextIn: productsIn.data.next,
			nextEx: productsIn.data.next,
			previousIn: productsIn.data.previous,
			previousEx: productsEx.data.previous,
		};

		return productsData;
	} catch (error) {
		console.log(error);
	}
};

//Endpoit para Procurar algum produto
export const searchProducts = async (
	lang,
	page,
	type,
	vendor,
	visitor,
	search
) => {
	try {
		//searchProducts('pt', '1', '1', '5e20ecb749ce43bf8125b3e73c1a37ae', '3b727a7743ab4dbca619d1508b0c1278', 'azul')
		const searchQuery = `&search=${search}`;
		const mainParams = products(lang, page, type, vendor, visitor);
		const params = mainParams + searchQuery;
		const res = await getData(params);
		const productsData = {
			results: res.data.results,
			next: res.data.next,
			previous: res.data.previous,
		};
		return productsData;
	} catch (error) {
		console.log(error);
	}
};

//Endpoint para Filtrar produtos
export const filterProducts = async (
	lang,
	page,
	type,
	vendor,
	visitor,
	filters
) => {
	const {
		categoriesOptions,
		collectionsOptions,
		colorsOptions,
		shapesOptions,
		speciesOptions,
		widthOptions,
	} = filters;
	try {
		//filterProducts('pt', '1', '1', '5e20ecb749ce43bf8125b3e73c1a37ae', '3b727a7743ab4dbca619d1508b0c1278', {categories: ["PIETRA PORTINARI"], colors:["azul"]})
		// getProductsIn FILTROS
		const mainParams = products(lang, page, type, vendor, visitor);
		let params = mainParams;

		//categorias
		// if (filters.categories && filters.categories.length > 0) {
		//     const categoryIn = `&category__in=${JSON.stringify(filters.categories)}`
		//     params += categoryIn
		// }
		if (categoriesOptions && categoriesOptions.length > 0) {
			const categoryIn = `&category__in=${JSON.stringify(categoriesOptions)}`;
			params += categoryIn;
		}

		//coleções
		// if (filters.collections && filters.collections.length > 0) {
		//     const collectionsIn = `&collection__in=${JSON.stringify(filters.collections)}`
		//     params += collectionsIn
		// }
		if (collectionsOptions && collectionsOptions.length > 0) {
			const collectionsIn = `&collection__in=${JSON.stringify(
				collectionsOptions
			)}`;
			params += collectionsIn;
		}

		//cores colorsOptions
		// if (filters.colors && filters.colors.length > 0) {
		//     const colorsIn = `&colors__in=${JSON.stringify(filters.colors)}`
		//     params += colorsIn
		// }
		if (colorsOptions && colorsOptions.length > 0) {
			const colorsIn = `&colors__in=${JSON.stringify(colorsOptions)}`;
			params += colorsIn;
		}

		//visuais
		// if (filters.species && filters.species.length > 0) {
		//     const speciesIn = `&species__in=${JSON.stringify(filters.species)}`
		//     params += speciesIn
		// }
		if (speciesOptions && speciesOptions.length > 0) {
			const speciesIn = `&species__in=${JSON.stringify(speciesOptions)}`;
			params += speciesIn;
		}

		//formatos
		// if (filters.widths && filters.widths.length > 0) {
		//     const widthIn = `&width_bucket__in=${JSON.stringify(filters.widths)}`
		//     params += widthIn
		// }
		if (widthOptions && widthOptions.length > 0) {
			const widthIn = `&width_bucket__in=${JSON.stringify(widthOptions)}`;
			params += widthIn;
		}

		//forma shapesOptions
		// if (filters.shapes && filters.shapes.length > 0) {
		//     const shapesIn = `&shape__in=${JSON.stringify(filters.shapes)}`
		//     params += shapesIn
		// }
		if (shapesOptions && shapesOptions.length > 0) {
			const shapesIn = `&shape__in=${JSON.stringify(shapesOptions)}`;
			params += shapesIn;
		}
		const res = await getData(params);
		const productsData = {
			results: res.data.results,
			next: res.data.next,
			previous: res.data.previous,
		};
		return productsData;
	} catch (error) {
		console.log(error);
	}
};

//Gera o padrão de request para funções relacionadas com o Produtos
export const products = (lang, page, type, vendor, visitor) => {
	try {
		endpoint = '/products';
		// Parametros necessários
		const isHidden = '?is_hidden=0';
		const locale = lang ? `&locale=${lang}-${`${lang==='en'?`${company==='ceusa'?'us':'gb'}`:lang}`}` : `locale=pt-pt`;
		const pagination = page ? `&page=${page}` : `page=1`;
		const productType = `&product_type=${type}`;
		const vendorId = `&vendor=${vendor}`;
		const visitorId = `&visitor=${visitor}`;
		let mainParams;
		if (type) {
			mainParams =
				endpoint +
				isHidden +
				locale +
				pagination +
				productType +
				vendorId +
				visitorId;
		}
		if (!type) {
			mainParams =
				endpoint + isHidden + locale + pagination + vendorId + visitorId;
		}
		return mainParams;
	} catch (error) {
		console.log(error);
	}
};

// Endpoint para conseguir a listagem de rooms
export const oldGetRooms = async (company, visitorId) => {
	try {
		//getRooms('pt', '1,5', '3b727a7743ab4dbca619d1508b0c1278')
		if (!company | !visitorId) throw new Error('ERROR Missing Parameters');
		// let langEndpoint = lang ? lang : defaultLang;
		let product_type = company === 'durafloor' ? '=1' : '__in=1,5';
		endpoint = `/rooms?product_type${product_type}&locale=${`pt`}-${`BR`}&visitor=${visitorId}`;
		const res = await getData(endpoint);
		return res.data;
	} catch (error) {
		console.log(error);
	}
};

export const getRooms = async (lang, company, visitorId) => {
	try {
		//getRooms('pt', '1,5', '3b727a7743ab4dbca619d1508b0c1278')
		if (!company | !visitorId) throw new Error('ERROR Missing Parameters');
		let langEndpoint = lang ? lang : defaultLang;
		let product_type = company === 'durafloor' ? '=1' : '__in=1,5';
		// endpoint = `/rooms?product_type${product_type}&locale=${langEndpoint}-${`${langEndpoint==='en'?`${company==='ceusa'?'us':'gb'}`:langEndpoint}`}&visitor=${visitorId}`;
		// const res = await getData(endpoint);
		endpoint = `list-rooms/${company}?visitor=${visitorId}&locale=${langEndpoint}`
		let res = await getDataV2(endpoint);

		await res.data.results.forEach(async (room) => {
			delete await Object.assign(room, {['productInstances']: room['product_instances'] })['product_instances']
			delete await Object.assign(room, {['isSample']: room['is_sample'] })['is_sample']
			delete await Object.assign(room, {['thumbnailUrl']: room['thumbnail_url'] })['thumbnail_url']
		})

		return res.data;
	} catch (error) {
		console.log(error);
	}
};

// Endpoint para enviar foto e criar um room
export const postRoom = async (picture, filename, visitorId, company, lang, acceptedConsentUpload, email) => {
	try {
		if (!picture | !filename | !visitorId)
			throw new Error('ERROR Missing Parameters');
		endpoint = '/create_room_from_picture?formdata=true';

		const formdata = new FormData();
		formdata.append('visitor_id', visitorId);
		formdata.append('product_types', '1');
		formdata.append('picture_file', picture, filename); // (binary)
		formdata.append('company', company);
		formdata.append('lang', lang)
		formdata.append('acceptedConsentUpload', acceptedConsentUpload)
		formdata.append('email', email)
		const res = await postDataV2(endpoint, formdata);
		delete await Object.assign(res.data, {['product_instances']: res.data['productInstances'] })['productInstances'];
		delete await Object.assign(res.data, {['is_sample']: res.data['isSample'] })['isSample'];
		delete await Object.assign(res.data, {['thumbnail_url']: res.data['thumbnailUrl'] })['thumbnailUrl'];

		return res.data;
	} catch (error) {
		console.log(error);
		throw new Error();
	}
};

export const getFirstVisualization = async (roomId,vendor, lang) => {
	const query = `?room_id=${roomId}
				&display_width=1024
				&display_height=1024
				&img_format=base64
				&is_for_download=0
				&vendor=${vendor}
				&locale=${lang ? `&locale=${lang}-${`${lang==='en'?`${company==='ceusa'?'us':'gb'}`:lang}`}` : `pt-pt`}
				&add_watermark=false`
	endpoint = '/get_static_visualization'
	const res = await getData(endpoint + query);
	return res.data;			
}

export const getVisualizationV2 = async (
	roomId,
	company,
	floorId,
	floorOrientation,
	wallId,
	wallOrientation,
	instances,
	visitorId
) => {
	try {
		const body = {
			product_placements:[],
			room_id: roomId,
			visitor_id: visitorId
		}

		if (floorId){
			const floorQuery = {
				product_instance:1,
				product_type: 1,
				product_id: floorId,
				product_orientation: floorOrientation
			}
			body.product_placements.push(floorQuery)
		}

		if (wallId) {
			for (let i = 0; i < instances.length; i++) {
				if (instances[i].productType === 5 || instances[i].product_type === 5) {
					const wallQuery = {
						product_instance: instances[i].instance,
						product_type: 5,
						product_id: wallId,
						product_orientation: wallOrientation
					}
					body.product_placements.push(wallQuery)
				}
			}
		}

		const config = {
			method: 'post',
			headers: { 
				'Content-Type': 'application/json'
			},
			url: `${url}v1/create-paint-image/${company}?format=json_camel_case`,
			data: JSON.stringify(body),
		};

		const res = await axios(config);

		return res.data;
	} catch (error) {
		console.log(error);
	}
};

export const getNewVisualization = async (
	roomId,
	vendorId,
	floorId,
	floorOrientation,
	wallId,
	wallOrientation,
	instances,
	lang
) => {
	try {
		endpoint = '/get_static_visualization'
		const baseQuery = `?room_id=${roomId}
				&display_width=1024
				&display_height=1024
				&img_format=base64
				&is_for_download=0
				&vendor=${vendorId}
				&locale=${lang ? `&locale=${lang}-${`${lang==='en'?`${company==='ceusa'?'us':'gb'}`:lang}`}` : `pt-pt`}
				&add_watermark=false`
		let floorQuery = ''
		let wallQuery = ''
		if (floorId){
			floorQuery = `&floor_product_id=${floorId}
				&floor_product_orientation=${floorOrientation}
				&floor_product_instance=1`
		}

		if (wallId) {
			for (let i = 0; i < instances.length; i++) {
				if (instances[i].productType === 5 || instances[i].product_type === 5) {
					wallQuery = `${wallQuery}&wall_product_id_${i}=${wallId}&wall_product_instance_${i}=${instances[i].instance}&wall_product_orientation_${i}=${wallOrientation}`;
				}
			}
		}
		const res = await getData(`${endpoint}${baseQuery}${floorQuery}${wallQuery}`);
		return res.data;
	} catch (error) {
		console.log(error);
	}
};



// Endpoint para pegar a foto do ambiente simulado
export const getVisualization = async (
	roomId,
	fProductId,
	fOrientation,
	vendorId,
	wProductId,
	wOrientation,
	wInstances
) => {
	if (!fProductId && !wProductId) return;
	try {
		//getVisualization(false, 'f45c30f718c047a6a314f8f737b6c6c3', '3291916d', 0, '5e20ecb749ce43bf8125b3e73c1a37ae')
		endpoint = '/get_static_visualization';
		const roomEndpointId = `room_id=${roomId}`;

		const displayWidth = `display_width=1024`;
		const displayHeight = `display_height=512`;
		const imgFormat = `img_format=base64`;
		const addWatermark = `add_watermark=false`;
		const lastModifiedTimestamp = `last_modified_timestamp=0`;
		const vendor = `vendor=${vendorId}`;
		let params = `?${roomEndpointId}&${displayWidth}&${displayHeight}&${imgFormat}&${addWatermark}&${lastModifiedTimestamp}&${vendor}`;

		if (fProductId) {
			const floorId = `floor_product_id=${fProductId}`;
			const floorOrientation = `floor_product_orientation=${fOrientation}`;
			const floorInstance = `floor_product_instance=1`;
			params = `${params}&${floorId}&${floorOrientation}&${floorInstance}`;
		}

		if (wProductId) {
			// getVisualization(false, 'f45c30f718c047a6a314f8f737b6c6c3', '3291916d', 0, '5e20ecb749ce43bf8125b3e73c1a37ae', '598641952', 0, [
			//     {productType: 1,instance: 1,markerGroup: {},surfaceType: null,anchorNormalizedRow: 0.5023584961891174,anchorNormalizedCol: 0.518666684627533},
			//     {productType: 2,instance: 1,markerGroup: {},surfaceType: null,anchorNormalizedRow: 0.5023584961891174,anchorNormalizedCol: 0.518666684627533},
			//     {productType: 5,instance: 4,markerGroup: {},surfaceType: null,anchorNormalizedRow: 0.25471699237823486,anchorNormalizedCol: 0.4569999873638153},
			//     {productType: 5,instance: 5,markerGroup: {},surfaceType: null,anchorNormalizedRow: 0.23443396389484406,anchorNormalizedCol: 0.13833333551883698}
			// ])
			const wallId = wProductId;
			const wallOrientation = wOrientation;
			const wallInstances = wInstances;
			for (let i = 0; i < wallInstances.length; i++) {
				if (wallInstances[i].productType === 5) {
					params = `${params}&wall_product_id_${i}=${wallId}&wall_product_instance_${i}=${wallInstances[i].instance}&wall_product_orientation_${i}=${wallOrientation}`;
				}
			}
		}

		const res = await getData(endpoint + params);
		return res.data;
	} catch (error) {
		console.log(error);
	}
};

// Endpoint para criar o email
export const createEmail = async (
	vendor_id,
	email,
	image_file,
	share_code,
	product_name,
	product_link,
	company,
	visitor_id,
	lang
) => {
	try {
		// endpoint = `/v1/send-email/${company}`;
		endpoint = `send-email/${company}`;

		const subject = 'Veja o seu ambiente simulado!';
		const message = `Confira no anexo a imagem do ambiente com o produto selecionado! 

        Confira detalhes do produto escolhido: 
        
        ${product_link}`;

		const formdata = new FormData();
		formdata.append('vendor', vendor_id);
		formdata.append('email', email);
		formdata.append('subject', subject);
		formdata.append('message', message);
		formdata.append('image_file', image_file, product_name + '.jpg'); // (binary)
		formdata.append('share_code', share_code);
		formdata.append('visitor', visitor_id);
		formdata.append('lang', lang);

		// const res = await postData(endpoint, formdata);
		const res = await postDataV2(endpoint, formdata);
		return res.data;
	} catch (error) {
		console.log(error);
	}
};

// Endpoint para criar o email jaiminho
export const createEmailJaiminho = async (
	productFloorFirstSelected,
	productWallFirstSelected,
	productFloorSecondSelected,
	productWallSecondSelected,
	spaces,
	company,
	roomName,
	roomId,
	visitor_id,
	email,
	// link_text,
	// text,
	// urlLogo,
	acceptedConsentEmail,
	attachments,
) => {	
	try {
		endpoint = `send-email-jaiminho`;
	
		const body = {
			"products":[
				[
					{
						"floor_name": productFloorFirstSelected? productFloorFirstSelected.FirstFloor.floor_name: '',
						"floor_thumbnail": productFloorFirstSelected? productFloorFirstSelected.FirstFloor.floor_thumbnail: '',
						"floor_link": productFloorFirstSelected ? productFloorFirstSelected.FirstFloor.floor_link: '',
						"floor_type": productFloorFirstSelected ? productFloorFirstSelected.FirstFloor.floor_type = "Piso": '',
						"floor_format": productFloorFirstSelected ? productFloorFirstSelected.FirstFloor.floor_format:'',
						"wall_name": productWallFirstSelected ? productWallFirstSelected.FirstWall.wall_name: '',
						"wall_thumbnail": productWallFirstSelected ? productWallFirstSelected.FirstWall.wall_thumbnail: '',
						"wall_link": productWallFirstSelected ? productWallFirstSelected.FirstWall.wall_link:'',
						"wall_type": productWallFirstSelected ? productWallFirstSelected.FirstWall.wall_type = "Parede":'',
						"wall_format": productWallFirstSelected ? productWallFirstSelected.FirstWall.wall_format: ''
					},
					{
						"floor_name": productFloorSecondSelected ? productFloorSecondSelected.SecondFloor.floor_name: '',
						"floor_thumbnail": productFloorSecondSelected ? productFloorSecondSelected.SecondFloor.floor_thumbnail:'',
						"floor_link": productFloorSecondSelected ? productFloorSecondSelected.SecondFloor.floor_link: '',
						"floor_type": productFloorSecondSelected ? productFloorSecondSelected.SecondFloor.floor_type = "Piso":'',
						"floor_format": productFloorSecondSelected ? productFloorSecondSelected.SecondFloor.floor_format:'',
						"wall_name": productWallSecondSelected ? productWallSecondSelected.SecondWall.wall_name: '',
						"wall_thumbnail": productWallSecondSelected ? productWallSecondSelected.SecondWall.wall_thumbnail:'',
						"wall_link": productWallSecondSelected ? productWallSecondSelected.SecondWall.wall_link: '',
						"wall_type": productWallSecondSelected ? productWallSecondSelected.SecondWall.wall_type = "Parede": '',
						"wall_format": productWallSecondSelected ? productWallSecondSelected.SecondWall.wall_format: ''
					}
				]
			],
			"spaces": spaces,
			"email": email,
			// "link_text": link_text,
			// "text": text,
			"company": company,
			"room_name": roomName,
			"room_id": roomId,
			"visitor_id": visitor_id,
			// "urlLogo": urlLogo,
			"acceptedConsentEmail": acceptedConsentEmail,
			"attachments": attachments
		}

		const data = JSON.stringify(body)
		const res = await postDataV2(endpoint, data);
		return res.data;
	} catch (error) {
		console.log(error);
	}
};

//Endpoint para criar o sharecode (acho)
export const postRoomShares = async (
	roomId,
	visitorId,
	fProductId,
	fOrientation,
	wProductId,
	wOrientation,
	wInstances
) => {
	try {
		// postRoomShares('f45c30f718c047a6a314f8f737b6c6c3', 'eb75e2ca23894320ac80d439eb818db4', '48151941', 0, '268547239', 0, [
		//     {productType: 1,instance: 1,markerGroup: {},surfaceType: null,anchorNormalizedRow: 0.5023584961891174,anchorNormalizedCol: 0.518666684627533},
		//     {productType: 2,instance: 1,markerGroup: {},surfaceType: null,anchorNormalizedRow: 0.5023584961891174,anchorNormalizedCol: 0.518666684627533},
		//     {productType: 5,instance: 4,markerGroup: {},surfaceType: null,anchorNormalizedRow: 0.25471699237823486,anchorNormalizedCol: 0.4569999873638153},
		//     {productType: 5,instance: 5,markerGroup: {},surfaceType: null,anchorNormalizedRow: 0.23443396389484406,anchorNormalizedCol: 0.13833333551883698}
		// ])
		endpoint = '/room_shares';
		let productPlacements = [];
		if(fProductId && fProductId !== undefined) productPlacements.push({
			instance: 1,
			product: fProductId,
			rotation: fOrientation,
		})

		if (wProductId) {
			const wallInstances = wInstances;
			for (let i = 0; i < wallInstances.length; i++) {
				// if (wallInstances[i].productType === 5) {
				if (wallInstances[i].product_type === 5) {
					productPlacements.push({
						instance: wallInstances[i].instance,
						product: wProductId,
						rotation: wOrientation,
					});
				}
			}
		}

		const body = {
			visitor: visitorId,
			room: roomId,
			productPlacements: productPlacements,
		};

		// const body = {
		//     "visitor": "909a2104183a46649f6127272068b543",
		//     "room": "f45c30f718c047a6a314f8f737b6c6c3",
		//     "productPlacements": [{"instance": 1, "product": "0d919124", "rotation": 0}]
		// }

		const res = await postData(endpoint, body);
		return res.data;
	} catch (error) {
		console.log(error);
	}
};

//Tratativa de erros
export const errors = (status) => {
	if (status == 301)
		throw new Error(`${response.status} ERROR Moved Permanently`);
	if (status == 401) throw new Error(`${response.status} ERROR Unauthorized`);
	if (status == 404) throw new Error(`${response.status} ERROR Not Found`);
	if (status == 410) throw new Error(`${response.status} ERROR Gone`);
	if (status == 500)
		throw new Error(`${response.status} ERROR Internal Server Error`);
	if (status == 502) throw new Error(`${response.status} ERROR Bad Gateway`);
};

//Proxy para as requests GET
export const getData = async (endpoint) => {
	const response = await axios.get(`${url}v1/proxy-get${endpoint}`);
	errors(response.status);
	return response;
};

export const getDataV2 = async (endpoint) => {
	const response = await axios.get(`${url}v1/${endpoint}`);
	errors(response.status);
	return response;
}

//Proxy para as requests POST
export const postData = async (endpoint, data) => {
	const config = {
		method: 'post',
		url: `${url}v1/proxy-post${endpoint}`,
		data: data,
	};

	if (endpoint.includes('create_room_from_picture'))
		config.url = `${urlTotem}/upload`;

	const response = await axios(config);
	errors(response.status);
	return response;
};

//Proxy para as requests POSTv2
export const postDataV2 = async (endpoint, data) => {
	const config = {
		method: 'post',
		url: `${url}v1/${endpoint}`,
		data: data,
	};

	if (endpoint.includes('create_room_from_picture'))
		config.url = `${urlTotem}/v1/upload`;

	const response = await axios(config);
	errors(response.status);
	return response;
};

//Proxy para as requests POST
export const createShareCode = async (company, roomId, visitorId, wInstances, wProductId, fProductId, wOrientation, fOrientation) => { 

	let productPlacements = []

	if(fProductId && fProductId !== undefined) productPlacements.push({
		instance: 1,
		product: fProductId,
		rotation: fOrientation,
	})

	if (wProductId) {
		const wallInstances = wInstances;
		for (let i = 0; i < wallInstances.length; i++)
			if (wallInstances[i].product_type === 5) {
				productPlacements.push({
					instance: wallInstances[i].instance,
					product: wProductId,
					rotation: wOrientation,
				});
			}
	}

	const body = {
		productPlacements: productPlacements,
		room: roomId,
		visitor: visitorId,
	};

	const config = {
		method: 'post',
		 url: `${url}v1/list-share-code/${company}?format=json_camel_case`,
		data: body,
	};

	const response = await axios(config);
	errors(response.status);
	return response.data;
}