import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import { useIntl } from 'react-intl'
import { Row, Col, Image, Button, Carousel, Input, Select, message, Checkbox } from 'antd'
import {
	LeftOutlined,
	CloseOutlined,
	ArrowLeftOutlined,
	ZoomInOutlined,
	MailOutlined,
	/* DownloadOutlined, */ MenuOutlined,
} from '@ant-design/icons'
import { ImgComparisonSlider } from '@img-comparison-slider/react'
// import saveAs from 'file-saver'
// import QRCode from 'react-qr-code'
import { ReactComponent as PhotoLibrary } from '../../assets/images/ic_outline-photo-library.svg'
import { ReactComponent as LeftArrow } from '../../assets/icons/left-arrow.svg'
import { ReactComponent as RightArrow } from '../../assets/icons/right-arrow.svg'
import { ReactComponent as CloseIcon } from '../../assets/icons/close-button.svg'
import { ReactComponent as TrashIcon } from '../../assets/images/trash-undo-alt.svg'
import { ReactComponent as ShareIcon } from '../../assets/icons/Share-alt.svg'
import { ReactComponent as Redo } from '../../assets/images/redo_icon.svg'
import { ReactComponent as ArrowCircleRight } from '../../assets/images/arrow_circle_right_icon.svg'
import { ReactComponent as SearchIcon } from '../../assets/images/search_icon.svg'
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg'
// import { ReactComponent as ObsDownload } from '../../assets/icons/obs-download.svg'
import {
	VisualizationContainer,
	ProductsModal,
	ShareModal,
	TotemDrawer,
	TotemShareModal,
	ProductsTotemModal,
	EmptyMessageComponent,
} from './style'
import {
	// getNewVisualization,
	getVisualizationV2,
	getFirstProducts,
	// postRoomShares,
	// createEmail,
	createEmailJaiminho,
	// createShareCode,
} from '../../services/BFF'
// import { getEnvDNS } from '../../env'
import LogoBrand from '../../components/LogoBrand'
import LoadingComponent from '../../components/Loading'
import { productListReducer } from '../../store/reducers/sharedService.reducer'
import TourWrap from '../../components/TourWrap'
import Intl from '../../utils/IntlMessages'
// import { getInitialRoomImage } from '../../utils/initialRoomImage'
import FilterComponent from '../../components/FilterComponent'
import PrivacyPolicyModal from '../../components/PrivacyPolicyModal'
import linksDSR from '../../utils/linksDSR'

const { Search } = Input

function Visualization() {
	const {
		vendor_id,
		totem,
		company,
		lang,
		productList,
		rooms,
		vendor_data,
		visitor_id,
		pdv,
		tour,
		step,
		room: roomRedux,
	} = useSelector((state) => state.sharedServiceReducer)
	const { search } = window.location
	const params = new URLSearchParams(search)
	const roomId = params.get('room')
	const sku = params.get('sku')
	const [loading, setLoading] = useState(false)
	const [firstSlotImg, setFirstSlotImg] = useState()
	const [secondSlotImg, setSecondSlotImg] = useState()
	const [width, setWidth] = useState(window.innerWidth)
	const [scale, setScale] = useState()
	const [onCompare, setOnCompare] = useState(false)
	const [secondEnv, setSecondEnv] = useState(false)
	const [floor, setFloor] = useState(true)
	const [productsModalVisible, setProductsModalVisible] = useState(false)
	const [shareModalVisible, setShareModalVisible] = useState(false)
	const [searchOn, setSearchOn] = useState(false)
	const [filterOn, setFilterOn] = useState(false)
	const [toggleEmail, setToggleEmail] = useState(false)
	const [totemShareModalVisible, setTotemShareModalVisible] = useState(false)
	const [emailLoading, setEmailLoading] = useState()
	const [filterOptions, setFilterOpttions] = useState({
		floor: {
			categoria: null,
			cor: null,
			visual: null,
			formato: null,
			colecao: null,
			forma: null,
		},
		wall: {
			categoria: null,
			cor: null,
			visual: null,
			formato: null,
			colecao: null,
			forma: null,
		},
	})
	const [filterSelected, setFilterSelected] = useState({
		floor: {
			categoria: [],
			cor: [],
			visual: [],
			formato: [],
			colecao: [],
			forma: [],
		},
		wall: {
			categoria: [],
			cor: [],
			visual: [],
			formato: [],
			colecao: [],
			forma: [],
		},
	})
	const [searchValues, setSearchValues] = useState({
		firstSlot: {
			floor: {
				value: '',
				on: false,
			},
			wall: {
				value: '',
				on: false,
			},
		},
		secondSlot: {
			floor: {
				value: '',
				on: false,
			},
			wall: {
				value: '',
				on: false,
			},
		},
	})
	const [email, setEmail] = useState()
	const [productModalSelection, setProductModalSelection] = useState({
		id: null,
		format: null,
	})
	const [productsSelected, setProductsSelected] = useState({
		firstSlot: {
			floor: {
				id: null,
				format: 0,
				orientation: 0,
			},
			wall: {
				id: null,
				format: 0,
				orientation: 0,
			},
		},
		secondSlot: {
			floor: {
				id: null,
				format: 0,
				orientation: 0,
			},
			wall: {
				id: null,
				format: 0,
				orientation: 0,
			},
		},
	})
	const [menuOpen, setMenuOpen] = useState({
		firstSlot: false,
		secondSlot: false,
	})
	const [productsLists, setProductsLists] = useState()
	const dispatch = useDispatch()
	const calcScale = () => {
		const height = window.innerHeight
		const innerwidth = window.innerWidth
		setScale(innerwidth / height >= 0.5625 ? height / 1920 : innerwidth / 1080)
		setWidth(innerwidth)
	}
	const intl = useIntl()

	/*
	const vendorList = {
		portinari: '5e20ecb749ce43bf8125b3e73c1a37ae',
		ceusa: 'abe0fad0d59d4c70bff4579344d82e64',
		durafloor: 'b1b9cc2bdfb649e68e2ac9b3959c6b39',
	}
	*/

	const roomvoPoweredLinks = {
		portinari: 'https://get.roomvo.com/?from=ceramicaportinaricombr',
		ceusa: 'https://get.roomvo.com/?from=ceusacombr',
		durafloor: 'https://get.roomvo.com/?from=meupisoidealcombr',
	}

	const [acceptedConsentEmail, setAcceptedConsentEmail] = useState(false)
	const [privacyPolicyModalVisible, setPrivacyPolicyModalVisible] = useState(false)
	const [openedPrivacyPolicy, setOpenedPrivacyPolicy] = useState(false)

	useEffect(() => {
		window.addEventListener('resize', calcScale, false)
		calcScale()

		const getProductSKU = (data) => {
			if (sku) {
				const product = data.find((item) => {
					if (item.formats.length) {
						item.formats.some((format) => format.value === sku)
					}
					return item.id === sku
				})
				if (product) {
					setFirstSlotImg(null)
					if (product.productType === 5) {
						setFloor(false)
					}
					setProductsSelected({
						...productsSelected,
						firstSlot: {
							floor:
								product.productType === 1
									? {
											id: sku,
											format: product.formats.length ? product.formats.findIndex((format) => format.value === sku) : 0,
											orientation: 0,
									  }
									: productsSelected.firstSlot.floor,
							wall:
								product.productType === 5
									? {
											id: sku,
											format: product.formats.length ? product.formats.findIndex((format) => format.value === sku) : 0,
											orientation: 0,
									  }
									: productsSelected.firstSlot.wall,
						},
					})
				}
			}
		}

		if (productList && sku) getProductSKU(productList)

		if (!productList) {
			getFirstProducts(lang, vendor_id, company).then((data) => {
				dispatch(productListReducer(data))
				// const floorList = data?.filter((item)=>item.productType===1)
				// const wallList = data?.filter((item)=>item.productType===5)
				const floorList = data.filter((item) => item.productType === 1)
				const wallList = data.filter((item) => item.productType === 5)
				setProductsLists({
					firstSlot: {
						floor: floorList,
						wall: wallList,
					},
					secondSlot: {
						floor: floorList,
						wall: wallList,
					},
				})

				if (sku) getProductSKU(data)
			})
		}
		const metadata = vendor_data[company].product_metadatas
		const floorMeta = metadata.filter((data) => data.productType === 1)[0]
		const wallMeta = metadata.filter((data) => data.productType === 5)[0]
		setFilterOpttions({
			floor: {
				categoria: floorMeta.categories.map((item) => (
					<Select.Option key={item} value={item}>
						{item}
					</Select.Option>
				)),
				cor: floorMeta.colors.map((item) => (
					<Select.Option key={item} value={item}>
						{item}
					</Select.Option>
				)),
				visual: floorMeta.species.map((item) => (
					<Select.Option key={item} value={item}>
						{item}
					</Select.Option>
				)),
				formato: floorMeta.widthBuckets.map((item) => (
					<Select.Option key={item} value={item}>
						{item}
					</Select.Option>
				)),
				colecao: floorMeta.collections.map((item) => (
					<Select.Option key={item} value={item}>
						{item}
					</Select.Option>
				)),
				forma: floorMeta.shapes.map((item) => (
					<Select.Option key={item} value={item}>
						{item}
					</Select.Option>
				)),
			},
			wall: {
				categoria:
					company === 'durafloor'
						? null
						: wallMeta.categories.map((item) => (
								<Select.Option key={item} value={item}>
									{item}
								</Select.Option>
						  )),
				cor:
					company === 'durafloor'
						? null
						: wallMeta.colors.map((item) => (
								<Select.Option key={item} value={item}>
									{item}
								</Select.Option>
						  )),
				visual:
					company === 'durafloor'
						? null
						: wallMeta.species.map((item) => (
								<Select.Option key={item} value={item}>
									{item}
								</Select.Option>
						  )),
				formato:
					company === 'durafloor'
						? null
						: wallMeta.widthBuckets.map((item) => (
								<Select.Option key={item} value={item}>
									{item}
								</Select.Option>
						  )),
				colecao:
					company === 'durafloor'
						? null
						: wallMeta.collections.map((item) => (
								<Select.Option key={item} value={item}>
									{item}
								</Select.Option>
						  )),
				forma:
					company === 'durafloor'
						? null
						: wallMeta.shapes.map((item) => (
								<Select.Option key={item} value={item}>
									{item}
								</Select.Option>
						  )),
			},
		})
	}, [])

	useEffect(() => {
		if (productList) {
			const floorList = productList.filter((item) => item.productType === 1)
			const wallList = productList.filter((item) => item.productType === 5)
			setProductsLists({
				firstSlot: {
					floor: floorList,
					wall: wallList,
				},
				secondSlot: {
					floor: floorList,
					wall: wallList,
				},
			})
		}
	}, [productList])

	useEffect(() => {
		setLoading(true)
		// if(!productsSelected.firstSlot.floor.id && !productsSelected.firstSlot.wall.id){
		// 	const initialRoomImage = getInitialRoomImage(company, roomId)

		// 	if (initialRoomImage) {
		// 		setFirstSlotImg(`data:image/jpeg;base64,${initialRoomImage}`)
		// 		setSecondSlotImg(`data:image/jpeg;base64,${initialRoomImage}`)
		// 		setLoading(false);
		// 		return;
		// 	}
		// }
		// getNewVisualization(
		// 	roomId,
		// 	vendor_id,
		// 	secondEnv?productsSelected.secondSlot.floor.id:productsSelected.firstSlot.floor.id,
		// 	secondEnv?productsSelected.secondSlot.floor.orientation:productsSelected.firstSlot.floor.orientation,
		// 	secondEnv?productsSelected.secondSlot.wall.id:productsSelected.firstSlot.wall.id,
		// 	secondEnv?productsSelected.secondSlot.wall.orientation:productsSelected.firstSlot.wall.orientation,
		// 	// rooms.find((room)=>room.id===roomId).productInstances
		// 	rooms.find((room)=>room.id===roomId).product_instances
		// 	).then((data)=> {
		// 		if(onCompare && (productsSelected.firstSlot.floor.id || productsSelected.firstSlot.wall.id || productsSelected.secondSlot.floor.id || productsSelected.secondSlot.wall.id)){
		// 			if (secondEnv){
		// 				setSecondSlotImg(`data:image/jpeg;base64,${data}`)
		// 			}
		// 			if (!secondEnv){
		// 				setFirstSlotImg(`data:image/jpeg;base64,${data}`)
		// 			}
		// 		}
		// 		if(!onCompare && (productsSelected.firstSlot.floor.id || productsSelected.firstSlot.wall.id)){
		// 			setFirstSlotImg(`data:image/jpeg;base64,${data}`)
		// 		}
		// 		if(!productsSelected.firstSlot.floor.id && !productsSelected.firstSlot.wall.id && !productsSelected.secondSlot.floor.id && !productsSelected.secondSlot.wall.id){
		// 			setSecondSlotImg(`data:image/jpeg;base64,${data}`)
		// 			setFirstSlotImg(`data:image/jpeg;base64,${data}`)
		// 		}

		// 	}).finally(()=>{
		// 		setLoading(false)
		// 	})
		getVisualizationV2(
			roomId,
			company,
			secondEnv ? productsSelected.secondSlot.floor.id : productsSelected.firstSlot.floor.id,
			secondEnv ? productsSelected.secondSlot.floor.orientation : productsSelected.firstSlot.floor.orientation,
			secondEnv ? productsSelected.secondSlot.wall.id : productsSelected.firstSlot.wall.id,
			secondEnv ? productsSelected.secondSlot.wall.orientation : productsSelected.firstSlot.wall.orientation,
			rooms.find((room) => room.id === roomId).productInstances,
			visitor_id
		)
			.then((data) => {
				if (
					onCompare &&
					(productsSelected.firstSlot.floor.id ||
						productsSelected.firstSlot.wall.id ||
						productsSelected.secondSlot.floor.id ||
						productsSelected.secondSlot.wall.id)
				) {
					if (secondEnv) {
						setSecondSlotImg(`data:image/jpeg;base64,${data}`)
					}
					if (!secondEnv) {
						setFirstSlotImg(`data:image/jpeg;base64,${data}`)
					}
				}
				if (!onCompare && (productsSelected.firstSlot.floor.id || productsSelected.firstSlot.wall.id)) {
					setFirstSlotImg(`data:image/jpeg;base64,${data}`)
				}
				if (
					!productsSelected.firstSlot.floor.id &&
					!productsSelected.firstSlot.wall.id &&
					!productsSelected.secondSlot.floor.id &&
					!productsSelected.secondSlot.wall.id
				) {
					setSecondSlotImg(`data:image/jpeg;base64,${data}`)
					setFirstSlotImg(`data:image/jpeg;base64,${data}`)
				}
			})
			.finally(() => {
				setLoading(false)
			})
	}, [productsSelected])

	const eventCloseModal = () => {
		const dataLayer = {
			event: 'close_modal',
			eventModel: {
				modal: 'products',
				modal_position: secondEnv ? 'right' : 'left',
				brand: company,
				device: totem === '1',
				pdv,
				language: lang,
				room_name: roomRedux?.name,
				room_code: roomRedux?.id ? roomRedux?.id : false,
				source_product_site: sku !== null,
			},
		}
		TagManager.dataLayer({
			dataLayer,
		})
	}

	const eventOpenModal = () => {
		const dataLayer = {
			event: 'open_modal',
			eventModel: {
				modal: 'products',
				modal_position: secondEnv ? 'right' : 'left',
				brand: company,
				device: totem === '1',
				pdv,
				language: lang,
				room_name: roomRedux?.name,
				room_code: roomRedux?.id ? roomRedux?.id : false,
				source_product_site: sku !== null,
			},
		}
		TagManager.dataLayer({
			dataLayer,
		})
	}

	const eventOpenModalShare = () => {
		let floorProduct
		let wallProduct
		if (!secondEnv) {
			floorProduct = productsSelected.firstSlot.floor.id
			wallProduct = productsSelected.firstSlot.wall.id
		}
		if (secondEnv) {
			floorProduct = productsSelected.secondSlot.floor.id
			wallProduct = productsSelected.secondSlot.wall.id
		}
		const dataLayer = {
			event: 'click_share',
			eventModel: {
				modal: 'share',
				modal_position: secondEnv ? 'right' : 'left',
				brand: company,
				device: totem === '1',
				pdv,
				room_name: roomRedux?.name,
				room_code: roomRedux?.id ? roomRedux?.id : false,
				floor_product: floorProduct,
				wall_product: wallProduct,
				tour,
				tour_step: step,
				source_product_site: sku !== null,
			},
		}
		TagManager.dataLayer({
			dataLayer,
		})
		if (totem === '1') setTotemShareModalVisible(true)
		if (totem === '0') setShareModalVisible(true)
	}

	const eventCompareOpen = () => {
		const dataLayer = {
			event: 'select_comparison_open',
			eventModel: {
				brand: company,
				device: totem === '1',
				pdv,
				language: lang,
				room_name: roomRedux?.name,
				room_code: roomRedux?.id ? roomRedux?.id : false,
				tour,
				tour_step: step,
				source_product_site: sku !== null,
			},
		}
		TagManager.dataLayer({
			dataLayer,
		})
	}

	const eventCompareClose = () => {
		const dataLayer = {
			event: 'select_comparison_close',
			eventModel: {
				brand: company,
				device: totem === '1',
				pdv,
				language: lang,
				room_name: roomRedux?.name,
				room_code: roomRedux?.id ? roomRedux?.id : false,
				tour,
				tour_step: step,
				source_product_site: sku !== null,
			},
		}
		TagManager.dataLayer({
			dataLayer,
		})
	}

	const eventQRCode = () => {
		let floorProduct
		let wallProduct
		if (!secondEnv) {
			floorProduct = productsSelected.firstSlot.floor.id
			wallProduct = productsSelected.firstSlot.wall.id
		}
		if (secondEnv) {
			floorProduct = productsSelected.secondSlot.floor.id
			wallProduct = productsSelected.secondSlot.wall.id
		}
		const dataLayer = {
			event: 'download_qrcode',
			eventModel: {
				modal: 'share',
				modal_position: secondEnv ? 'right' : 'left',
				brand: company,
				device: totem === '1',
				pdv,
				language: lang,
				room_name: roomRedux?.name,
				room_code: roomRedux?.id ? roomRedux?.id : false,
				floor_product: floorProduct,
				wall_product: wallProduct,
				source_product_site: sku !== null,
			},
		}
		TagManager.dataLayer({
			dataLayer,
		})
	}

	const eventChangeRoom = () => {
		const dataLayer = {
			event: 'change_room',
			eventModel: {
				brand: company,
				device: totem === '1',
				pdv,
				language: lang,
				room_name: roomRedux?.name || roomRedux?.name !== '' ? roomRedux?.name : 'meu ambiente',
				room_code: roomRedux?.id ? roomRedux?.id : false,
				tour,
				tour_step: step,
				source_product_site: sku !== null,
			},
		}
		TagManager.dataLayer({
			dataLayer,
		})
	}

	const eventToogleProductModal = (isOpen) => {
		if (isOpen) {
			const dataLayer = {
				event: 'open_modal',
				eventModel: {
					modal: 'products',
					modal_position: secondEnv ? 'right' : 'left',
					brand: company,
					device: totem === '1',
					pdv,
					language: lang,
					room_name: roomRedux?.name,
					room_code: roomRedux?.id ? roomRedux?.id : false,
					source_product_site: sku !== null,
				},
			}
			TagManager.dataLayer({
				dataLayer,
			})
		}
		if (!isOpen) {
			const dataLayer = {
				event: 'close_modal',
				eventModel: {
					modal: 'products',
					modal_position: secondEnv ? 'right' : 'left',
					brand: company,
					device: totem === '1',
					pdv,
					language: lang,
					room_name: roomRedux?.name,
					room_code: roomRedux?.id ? roomRedux?.id : false,
					source_product_site: sku !== null,
				},
			}
			TagManager.dataLayer({
				dataLayer,
			})
		}
	}

	// const eventDownload = () =>{
	// 	if(!secondEnv) saveAs(firstSlotImg, 'Ambiente 1.jpg');
	// 	if(secondEnv) saveAs(secondSlotImg, 'Ambiente 2.jpg');
	// 	let floorProduct
	// 	let wallProduct
	// 	if(!secondEnv) {
	// 		floorProduct = productsSelected.firstSlot.floor.id
	// 		wallProduct = productsSelected.firstSlot.wall.id
	// 	}
	// 	if(secondEnv) {
	// 		floorProduct = productsSelected.secondSlot.floor.id
	// 		wallProduct = productsSelected.secondSlot.wall.id
	// 	}
	// 	const dataLayer = {
	// 		event: "download_room",
	// 		eventModel:{
	// 			brand: company,
	// 			device: totem === '1',
	// 			pdv,
	// 			language: lang,
	// 			room_name: roomRedux?.name,
	// 			room_code: roomRedux?.id ? roomRedux?.id : false,
	// 			tour,
	// 			tour_step: step,
	// 			floor_product: floorProduct,
	// 			wall_product: wallProduct,
	// 		}
	// 	}
	// 	TagManager.dataLayer({
	// 		dataLayer
	// 	})

	// }

	const onSearchChange = (e) => {
		setSearchValues({
			...searchValues,
			[secondEnv ? 'secondSlot' : 'firstSlot']: {
				floor: floor
					? {
							value: e,
							on: false,
					  }
					: searchValues[secondEnv ? 'secondSlot' : 'firstSlot'].floor,
				wall: !floor
					? {
							value: e,
							on: false,
					  }
					: searchValues[secondEnv ? 'secondSlot' : 'firstSlot'].wall,
			},
		})
	}

	const clearFilter = (isOther) => {
		const dataLayer = {
			event: isOther ? 'other_products' : 'remove_filters',
			eventModel: {
				modal: 'products',
				modal_position: secondEnv ? 'right' : 'left',
				brand: company,
				device: totem === '1',
				pdv,
				language: lang,
				room_name: roomRedux?.name ? roomRedux?.name : false,
				room_code: roomRedux?.id ? roomRedux?.id : false,
				source_product_site: sku !== null,
			},
		}
		TagManager.dataLayer({
			dataLayer,
		})

		setFilterSelected({
			...filterSelected,
			[floor ? 'floor' : 'wall']: {
				categoria: [],
				cor: [],
				visual: [],
				formato: [],
				colecao: [],
				forma: [],
			},
		})
		onSearchChange('')
	}

	const productSelection = (id) => {
		const productSelected = productList.find((product) => product.id === id)
		const productSize = productSelected?.formats.map((size) => size.name)
		const filterObj = filterSelected[floor ? 'floor' : 'wall']
		const newFilterObj = {}
		newFilterObj.categories = filterObj.categoria && filterObj.categoria.length > 0 && filterObj.categoria.join('|')
		newFilterObj.collections = filterObj.colecao && filterObj.colecao.length > 0 && filterObj.colecao.join('|')
		newFilterObj.colors = filterObj.cor && filterObj.cor.length > 0 && filterObj.cor.join('|')
		newFilterObj.width = filterObj.formato && filterObj.formato.length > 0 && filterObj.formato.join('|')
		newFilterObj.species = filterObj.visual && filterObj.visual.length > 0 && filterObj.visual.join('|')
		newFilterObj.shapes = filterObj.forma && filterObj.forma.length > 0 && filterObj.forma.join('|')

		const dataLayer = {
			event: 'select_product',
			eventModel: {
				modal: 'products',
				modal_position: secondEnv ? 'right' : 'left',
				brand: company,
				device: totem === '1',
				pdv,
				language: lang,
				room_name: roomRedux?.name ? roomRedux?.name : false,
				room_code: roomRedux?.id ? roomRedux?.id : false,
				type: floor ? 'piso' : 'parede',
				product: productSelected?.name,
				sku: productSelected?.sku,
				categories: productSelected?.categories.join('|'),
				collections: productSelected?.collections.join('|'),
				colors: productSelected?.colors.join('|'),
				colorFamilies: productSelected?.colorFamilies.join('|'),
				size: productSize?.join('|'),
				link: productSelected?.link,
				species: productSelected?.species.join('|'),
				shade: productSelected?.shade,
				shapes: productSelected?.shapes,
				thumbnail: productSelected?.thumbnail,
				filter_type: { ...newFilterObj },
				source_product_site: sku !== null,
			},
		}
		TagManager.dataLayer({
			dataLayer,
		})

		setProductsSelected({
			...productsSelected,
			[secondEnv ? 'secondSlot' : 'firstSlot']: {
				floor: floor
					? {
							id,
							format: 0,
							orientation: 0,
					  }
					: productsSelected[secondEnv ? 'secondSlot' : 'firstSlot'].floor,
				wall: !floor
					? {
							id,
							format: 0,
							orientation: 0,
					  }
					: productsSelected[secondEnv ? 'secondSlot' : 'firstSlot'].wall,
			},
		})
	}

	const zoomProduct = (selection) => {
		const productSelected = productList.find((product) => product.id === selection?.id)
		const dataLayer = {
			event: 'select_zoom',
			eventModel: {
				modal: 'products',
				modal_position: secondEnv ? 'right' : 'left',
				brand: company,
				device: totem === '1',
				pdv,
				language: lang,
				room_name: roomRedux?.name,
				room_code: roomRedux?.id ? roomRedux?.id : false,
				type: floor ? 'piso' : 'parede',
				product: productSelected?.name,
				sku: productSelected?.sku,
				categories: productSelected?.categories.join('|'),
				collections: productSelected?.collections.join('|'),
				colors: productSelected?.colors.join('|'),
				colorFamilies: productSelected?.colorFamilies.join('|'),
				size: productSelected?.formats.map((size) => size.name).join('|'), // format
				link: productSelected?.link,
				species: productSelected?.species.join('|'),
				shade: productSelected?.shade,
				shapes: productSelected?.shapes,
				thumbnail: productSelected?.thumbnail,
				source_product_site: sku !== null,
			},
		}
		TagManager.dataLayer({
			dataLayer,
		})
		setProductModalSelection(selection)
		setProductsModalVisible(true)
	}

	const formatSelection = (direction, nFormats) => {
		let newFormat = productsSelected[secondEnv ? 'secondSlot' : 'firstSlot'][floor ? 'floor' : 'wall'].format
		if (direction === 'right') {
			newFormat = newFormat === nFormats ? 0 : newFormat + 1
		}
		if (direction === 'left') {
			newFormat = newFormat === 0 ? nFormats : newFormat - 1
		}
		const productIdSelect = productsSelected[secondEnv ? 'secondSlot' : 'firstSlot'][floor ? 'floor' : 'wall'].id
		const productFiltered = productList.find((product) => product.id === productIdSelect)
		const dataLayer = {
			event: 'select_format',
			eventModel: {
				modal: 'products',
				modal_position: secondEnv ? 'right' : 'left',
				brand: company,
				device: totem === '1',
				pdv,
				language: lang,
				room_name: roomRedux?.name,
				room_code: roomRedux?.id ? roomRedux?.id : false,
				type: floor ? 'piso' : 'parede',
				product: productFiltered?.name,
				sku: productFiltered?.sku,
				categories: productFiltered?.categories.join('|'),
				collections: productFiltered?.collections.join('|'),
				colors: productFiltered?.colors.join('|'),
				colorFamilies: productFiltered?.colorFamilies.join('|'),
				size: productFiltered?.formats.map((size) => size.name).join('|'),
				link: productFiltered?.link,
				species: productFiltered?.species.join('|'),
				shade: productFiltered?.shade,
				shapes: productFiltered?.shapes,
				thumbnail: productFiltered?.thumbnail,
				selected_size_slider: productFiltered?.formats[newFormat].name,
				source_product_site: sku !== null,
			},
		}
		TagManager.dataLayer({
			dataLayer,
		})

		setProductsSelected({
			...productsSelected,
			[secondEnv ? 'secondSlot' : 'firstSlot']: {
				floor: floor
					? {
							id: productList.find((product) =>
								product.formats.find(
									(format) =>
										format.value ===
										productsSelected[secondEnv ? 'secondSlot' : 'firstSlot'][floor ? 'floor' : 'wall'].id
								)
							).formats[newFormat].value,
							format: newFormat,
							orientation: 0,
					  }
					: productsSelected[secondEnv ? 'secondSlot' : 'firstSlot'].floor,
				wall: !floor
					? {
							id: productList.find((product) =>
								product.formats.find(
									(format) =>
										format.value ===
										productsSelected[secondEnv ? 'secondSlot' : 'firstSlot'][floor ? 'floor' : 'wall'].id
								)
							).formats[newFormat].value,
							format: newFormat,
							orientation: 0,
					  }
					: productsSelected[secondEnv ? 'secondSlot' : 'firstSlot'].wall,
			},
		})
	}

	const orientationSelection = () => {
		const dataLayer = {
			event: 'select_rotation',
			eventModel: {
				brand: company,
				modal_position: secondEnv ? 'right' : 'left',
				device: totem === '1',
				pdv,
				language: lang,
				room_name: roomRedux?.name ? roomRedux?.name : false,
				room_code: roomRedux?.id ? roomRedux?.id : false,
				source_product_site: sku !== null,
			},
		}
		TagManager.dataLayer({
			dataLayer,
		})
		const { id, format, orientation } =
			productsSelected[secondEnv ? 'secondSlot' : 'firstSlot'][floor ? 'floor' : 'wall']
		const newOrientation = orientation === 270 ? 0 : orientation + 90

		setProductsSelected({
			...productsSelected,
			[secondEnv ? 'secondSlot' : 'firstSlot']: {
				floor: floor
					? {
							id,
							format,
							orientation: newOrientation,
					  }
					: productsSelected[secondEnv ? 'secondSlot' : 'firstSlot'].floor,
				wall: !floor
					? {
							id,
							format,
							orientation: newOrientation,
					  }
					: productsSelected[secondEnv ? 'secondSlot' : 'firstSlot'].wall,
			},
		})
	}

	const clearVisualization = () => {
		const dataLayer = {
			event: 'select_delete',
			eventModel: {
				brand: company,
				modal_position: secondEnv ? 'right' : 'left',
				device: totem === '1',
				pdv,
				language: lang,
				room_name: roomRedux?.name ? roomRedux?.name : false,
				room_code: roomRedux?.id ? roomRedux?.id : false,
				source_product_site: sku !== null,
			},
		}
		TagManager.dataLayer({
			dataLayer,
		})
		setProductsSelected({
			firstSlot: {
				floor: {
					id: null,
					format: 0,
					orientation: 0,
				},
				wall: {
					id: null,
					format: 0,
					orientation: 0,
				},
			},
			secondSlot: {
				floor: {
					id: null,
					format: 0,
					orientation: 0,
				},
				wall: {
					id: null,
					format: 0,
					orientation: 0,
				},
			},
		})
	}

	const getActualList = () => {
		let actualList = productsLists[secondEnv ? 'secondSlot' : 'firstSlot'][floor ? 'floor' : 'wall']
		const { categoria, cor, visual, formato, colecao, forma } = filterSelected[floor ? 'floor' : 'wall']

		const unique = (value, index, self) => self.indexOf(value) === index

		if (sku) {
			const skuProduct = actualList?.filter((product) => product.id === sku)
			actualList = [...skuProduct, ...actualList].filter(unique)
		}

		if (searchValues[secondEnv ? 'secondSlot' : 'firstSlot'][floor ? 'floor' : 'wall'].on) {
			const value = searchValues[secondEnv ? 'secondSlot' : 'firstSlot'][floor ? 'floor' : 'wall'].value.toUpperCase()

			const auxListName = actualList.filter((product) => product.name.toUpperCase().includes(value))
			const auxListColor = actualList.filter((product) =>
				product.colors.some((color) => color.toUpperCase().includes(value))
			)
			const auxListCategories = actualList.filter((product) =>
				product.categories.some((categorie) => categorie.toUpperCase().includes(value))
			)
			const auxListSpecies = actualList.filter((product) =>
				product.species.some((specie) => specie.toUpperCase().includes(value))
			)
			const auxListCollection = actualList.filter((product) =>
				product.collections.some((collection) => collection.toUpperCase().includes(value))
			)

			actualList = [
				...auxListName,
				...auxListColor,
				...auxListCategories,
				...auxListSpecies,
				...auxListCollection,
			].filter(unique)
		}

		if (categoria.length && actualList.length) {
			actualList = actualList.filter((product) => product.categories.some((categorie) => categoria.includes(categorie)))
		}

		if (cor.length && actualList.length) {
			actualList = actualList.filter((product) => product.colors.some((color) => cor.includes(color)))
		}

		if (visual.length && actualList.length) {
			actualList = actualList.filter((product) => product.species.some((specie) => visual.includes(specie)))
		}

		if (formato.length && actualList.length) {
			const withFormat = actualList.filter((product) => product.formats.length)
			actualList = withFormat.filter((product) =>
				product.formats.some((format) => formato.map((f) => f.toUpperCase()).includes(format.name.toUpperCase()))
			)
		}

		if (colecao.length && actualList.length) {
			actualList = actualList.filter((product) =>
				product.collections.some((collection) => colecao.includes(collection))
			)
		}

		if (forma.length && actualList?.length) {
			actualList = actualList.filter((product) => [product.shape].some((shap) => forma.includes(shap)))
		}

		if (actualList.length) {
			const formats = actualList
				.map((product) => product.formats.map((format) => format.value))
				.map((values) => values.join())

			const listFinal = []
			formats.forEach((res, index) => formats.indexOf(res) === index && listFinal.push(actualList[index]))

			actualList = listFinal
		}

		return actualList
	}

	const isSelected = (id) =>
		id === productsSelected[secondEnv ? 'secondSlot' : 'firstSlot'][floor ? 'floor' : 'wall'].id

	/*
	const getQrCode = () => {
		const selection = productsSelected[secondEnv ? 'secondSlot' : 'firstSlot']
		const instances = rooms
			.find((room) => room.id === roomId)
			.productInstances.filter((instance) => instance.productType === 5 || instance.product_type === 5)
		if (!instances) return 0

		let instancesQuery = ''
		for (let index = 0; index < instances.length; index += 1) {
			instancesQuery = `${instancesQuery}${index === 0 ? '' : ','}${instances[index].instance}`
		}
		const baseUrl = `${getEnvDNS()}download`
		const baseQuery = `?room=${roomId}&vendor=${vendorList[company]}`
		const floorQuery = selection.floor.id ? `&floor=${selection.floor.id}&floorO=${selection.floor.orientation}` : ''
		const wallQuery = selection.wall.id
			? `&wall=${selection.wall.id}&wallO=${selection.wall.orientation}&instances=${instancesQuery}`
			: ''

		return `${baseUrl}${baseQuery}${floorQuery}${wallQuery}`
	}
	*/

	/* const sendEmail = async () => {
		let floorProduct
		let wallProduct
		if (!secondEnv) {
			floorProduct = productsSelected.firstSlot.floor.id
			wallProduct = productsSelected.firstSlot.wall.id
		}
		if (secondEnv) {
			floorProduct = productsSelected.secondSlot.floor.id
			wallProduct = productsSelected.secondSlot.wall.id
		}
		const dataLayer = {
			event: 'sent_email',
			eventModel: {
				modal: 'share',
				modal_position: secondEnv ? 'right' : 'left',
				brand: company,
				device: totem === '1',
				pdv,
				language: lang,
				room_name: roomRedux?.name,
				room_code: roomRedux?.id ? roomRedux?.id : false,
				floor_product: floorProduct,
				wall_product: wallProduct,
				source_product_site: sku !== null,
			},
		}
		TagManager.dataLayer({
			dataLayer,
		})
		setEmailLoading(true)

		const isEmailValid = email
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			)

		if (!isEmailValid) {
			message.error(intl.formatMessage({ id: 'visualization.invalidEmail' }))
			setEmailLoading(false)
			return null
		}

		if (!acceptedConsentEmail) {
			message.error('É necessário estar ciente com o vínculo do seu e-mail.')
			setEmailLoading(false)
			return null
		}

		try {
			let file

			if (!secondEnv) file = await fetch(firstSlotImg)
			if (secondEnv) file = await fetch(secondSlotImg)

			const imageFile = await file.blob()

			const fProductId = secondEnv ? productsSelected.secondSlot.floor.id : productsSelected.firstSlot.floor.id
			const fOrientation = secondEnv
				? productsSelected.secondSlot.floor.orientation
				: productsSelected.firstSlot.floor.orientation
			const wProductId = secondEnv ? productsSelected.secondSlot.wall.id : productsSelected.firstSlot.wall.id
			const wOrientation = secondEnv
				? productsSelected.secondSlot.wall.orientation
				: productsSelected.firstSlot.wall.orientation
			const wInstances = rooms.find((room) => room.id === roomId).productInstances

			if (!(fProductId || wProductId)) {
				message.error(intl.formatMessage({ id: 'visualization.surfaceNotSelected' }))
				setEmailLoading(false)
				return null
			}

			const productFloorCurrentSelected = productList.find((item) => item.id === fProductId)
			const productWallCurrentSelected = productList.find((item) => item.id === wProductId)

			// const result = await createShareCode(company, roomId, visitor_id, wInstances, wProductId, fProductId, wOrientation, fOrientation, roomRedux);
			const result = await createShareCode(
				company,
				roomId,
				visitor_id,
				wInstances,
				wProductId,
				fProductId,
				wOrientation,
				fOrientation
			)

			// const result = await postRoomShares(
			// 	roomId,
			// 	visitor_id,
			// 	fProductId,
			// 	fOrientation,
			// 	wProductId,
			// 	wOrientation,
			// 	wInstances
			// );

			const { shareCode } = result
			const productName = productFloorCurrentSelected?.name || productWallCurrentSelected?.name
			const productLink = productFloorCurrentSelected?.link || productWallCurrentSelected?.link

			await createEmail(vendor_id, email, imageFile, shareCode, productName, productLink, company, visitor_id, lang)

			message.success(intl.formatMessage({ id: 'visualization.emailSuccess' }))
			setEmailLoading(false)
			setToggleEmail(false)
			setEmail('')
		} catch (e) {
			message.error(intl.formatMessage({ id: 'visualization.emailError' }))
			setEmailLoading(false)
		}

		return null
	} */

	const sendEmailJaiminho = async () => {
		const dataLayerSentEmail = {
			event: 'sent_email',
			eventModel: {
				modal: 'share',
				modal_position: secondEnv ? 'right' : 'left',
				brand: company,
				device: totem === '1',
				pdv,
				language: lang,
				room_name: roomRedux?.name ? roomRedux.name : 'Seu Ambiente',
				room_code: roomRedux?.id ? roomRedux?.id : false,
				floor_product_1: productsSelected.firstSlot.floor.id ? productsSelected.firstSlot.floor.id : '',
				wall_product_1: productsSelected.firstSlot.wall.id ? productsSelected.firstSlot.wall.id : '',
				floor_product_2: productsSelected.secondSlot.floor.id ? productsSelected.secondSlot.floor.id : '',
				wall_product_2: productsSelected.secondSlot.wall.id ? productsSelected.secondSlot.wall.id : '',
				source_product_site: sku !== null,
			},
		}
		TagManager.dataLayer({
			dataLayer: dataLayerSentEmail,
		})

		setEmailLoading(true)

		const isEmailValid = email
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			)

		if (!isEmailValid) {
			message.error(intl.formatMessage({ id: 'visualization.invalidEmail' }))
			setEmailLoading(false)
			return null
		}

		if (!acceptedConsentEmail) {
			message.error('É necessário estar ciente com o vínculo do seu e-mail.')
			setEmailLoading(false)
			return null
		}

		try {
			const floorProductFirst = productsSelected.firstSlot.floor.id
			const wallProductFirst = productsSelected.firstSlot.wall.id
			const floorProductSecond = productsSelected.secondSlot.floor.id
			const wallProductSecond = productsSelected.secondSlot.wall.id

			const returnFloorFirst = productsLists.firstSlot.floor.find((element) => element.id === floorProductFirst)
			const returnWallFirst = productsLists.firstSlot.wall.find((element) => element.id === wallProductFirst)
			const returnFloorSecond = productsLists.secondSlot.floor.find((element) => element.id === floorProductSecond)
			const returnWallSecond = productsLists.secondSlot.wall.find((element) => element.id === wallProductSecond)

			const productFloorFirstSelected = returnFloorFirst
				? {
						FirstFloor: {
							floor_name: returnFloorFirst.name,
							floor_thumbnail: returnFloorFirst.thumbnail,
							floor_link: returnFloorFirst.link,
							floor_type: returnFloorFirst.productType,
							floor_format: returnFloorFirst.formats[0].name,
						},
				  }
				: ''
			const productWallFirstSelected = returnWallFirst
				? {
						FirstWall: {
							wall_name: returnWallFirst.name,
							wall_thumbnail: returnWallFirst.thumbnail,
							wall_link: returnWallFirst.link,
							wall_type: returnWallFirst.productType,
							wall_format: returnWallFirst.formats[0].name,
						},
				  }
				: ''
			const productFloorSecondSelected = returnFloorSecond
				? {
						SecondFloor: {
							floor_name: returnFloorSecond.name,
							floor_thumbnail: returnFloorSecond.thumbnail,
							floor_link: returnFloorSecond.link,
							floor_type: returnFloorSecond.productType,
							floor_format: returnFloorSecond.formats[0].name,
						},
				  }
				: ''
			const productWallSecondSelected = returnWallSecond
				? {
						SecondWall: {
							wall_name: returnWallSecond.name,
							wall_thumbnail: returnWallSecond.thumbnail,
							wall_link: returnWallSecond.link,
							wall_type: returnWallSecond.productType,
							wall_format: returnWallSecond.formats[0].name,
						},
				  }
				: ''

			if (
				!(
					productFloorFirstSelected ||
					productWallFirstSelected ||
					productFloorSecondSelected ||
					productWallSecondSelected
				)
			) {
				message.error(intl.formatMessage({ id: 'visualization.surfaceNotSelected' }))
				setEmailLoading(false)
				return null
			}

			const spaces = [
				floorProductFirst || wallProductFirst ? 'Ambiente 1' : '',
				floorProductSecond || wallProductSecond ? 'Ambiente 2' : '',
			]
			const attachments = [
				floorProductFirst || wallProductFirst ? firstSlotImg.toString().substring(22) : '',
				floorProductSecond || wallProductSecond ? secondSlotImg.toString().substring(22) : '',
			]

			const roomName = roomRedux.name
			// const link_text = "Mais detalhes"
			// const text = "Confira no anexo a imagem do ambiente com o produto selecionado!"
			// const urlLogo = `${getEnvDNS()}images/${company === 'portinari' && 'marca_porti_small_new.png' || company === 'ceusa' && 'marca_ceusa_small.png' || company === 'durafloor' && 'marca_dura_small.png'}`

			await createEmailJaiminho(
				productFloorFirstSelected,
				productWallFirstSelected,
				productFloorSecondSelected,
				productWallSecondSelected,
				spaces,
				company,
				roomName,
				roomId,
				visitor_id,
				email,
				// link_text,
				// text,
				// urlLogo,
				acceptedConsentEmail,
				attachments
			)

			const dataLayer = {
				event: 'lgpd_share',
				eventModel: {
					modal: 'share',
					brand: company,
					source_product_site: sku !== null,
					device: totem === '1',
					pdv,
					language: lang,
					room_code: roomId || false,
					room_name: roomName || 'Seu Ambiente',
					floor_product_1: productsSelected.firstSlot.floor.id ? productsSelected.firstSlot.floor.id : '',
					wall_product_1: productsSelected.firstSlot.wall.id ? productsSelected.firstSlot.wall.id : '',
					floor_product_2: productsSelected.secondSlot.floor.id ? productsSelected.secondSlot.floor.id : '',
					wall_product_2: productsSelected.secondSlot.wall.id ? productsSelected.secondSlot.wall.id : '',
					acceptedConsentEmail,
				},
				// gtm.uniqueEventId: 158
			}

			TagManager.dataLayer({
				dataLayer,
			})

			message.success(intl.formatMessage({ id: 'visualization.emailSuccess' }))
			setEmailLoading(false)
			setToggleEmail(false)
			setEmail('')
		} catch (e) {
			message.error(intl.formatMessage({ id: 'visualization.emailError' }))

			setEmailLoading(false)
		}
		// message.error(intl.formatMessage({ id: 'visualization.emailOff' }))

		return null
	}

	const searchAction = (e) => {
		const dataLayer = {
			event: 'use_search',
			eventModel: {
				modal: 'products',
				modal_position: secondEnv ? 'right' : 'left',
				query: e,
				brand: company,
				device: totem === '1',
				pdv,
				language: lang,
				room_name: roomRedux?.name,
				room_code: roomRedux?.id ? roomRedux?.id : false,
				source_product_site: sku !== null,
			},
		}
		TagManager.dataLayer({
			dataLayer,
		})
		if (e) {
			setSearchValues({
				...searchValues,
				[secondEnv ? 'secondSlot' : 'firstSlot']: {
					floor: floor
						? {
								value: searchValues[secondEnv ? 'secondSlot' : 'firstSlot'].floor.value,
								on: true,
						  }
						: searchValues[secondEnv ? 'secondSlot' : 'firstSlot'].floor,
					wall: !floor
						? {
								value: searchValues[secondEnv ? 'secondSlot' : 'firstSlot'].wall.value,
								on: true,
						  }
						: searchValues[secondEnv ? 'secondSlot' : 'firstSlot'].wall,
				},
			})
			setSearchOn(false)
		}
	}

	const horizontalCarouselCards = () => {
		const cards = []
		const list = getActualList()
		if (list.length) {
			for (let index = 0; index < list.length; index += 1) {
				const { format } = productsSelected[secondEnv ? 'secondSlot' : 'firstSlot'][floor ? 'floor' : 'wall']
				const selected =
					list[index].formats.length === 0
						? isSelected(list[index].id)
						: list[index].formats.some((item) => isSelected(item.value))
				const id = list[index].formats.length === 0 ? list[index].id : list[index].formats[selected ? format : 0].value
				const thumbnail =
					list[index].formats.length === 0
						? `url(${list[index].thumbnail ? `https://www.roomvo.com${list[index].thumbnail}` : ''})`
						: `url(${
								list[index].formats[selected ? format : 0].thumbnailUrl
									? `https://www.roomvo.com${list[index].formats[selected ? format : 0].thumbnailUrl}`
									: ''
						  })`
				cards.push(
					<div key={list[index].id}>
						<div
							className={`horizontal-carousel-card${selected ? '-selected' : ''}`}
							onClick={() => (!selected ? productSelection(id) : {})}
							onKeyDown={() => {}}
							role='button'
							tabIndex={-1}
						>
							<div className='horizontal-carousel-card-img' style={{ backgroundImage: thumbnail }}>
								{selected && (
									<div
										className='horizontal-carousel-zoom'
										onClick={() =>
											zoomProduct(productsSelected[secondEnv ? 'secondSlot' : 'firstSlot'][floor ? 'floor' : 'wall'])
										}
										onKeyDown={() => {}}
										role='button'
										tabIndex={-1}
									>
										<ZoomInOutlined />
									</div>
								)}
							</div>
							<div className='horizontal-carousel-text'>{list[index].name}</div>
							{selected && (
								<div
									className={`horizontal-carousel-measures ${
										list[index].formats.length <= 1 ? '' : 'horizontal-carousel-measures-bordered'
									}`}
								>
									{list[index].formats.length <= 1 ? (
										<span className='horizontal-carousel-single-measure'>
											{list[index].formats.length === 0 ? (
												<Intl id='visualization.notInformed' />
											) : (
												list[index].formats[0].name
											)}
										</span>
									) : (
										<Row justify='space-between'>
											<Col>
												<LeftArrow onClick={() => formatSelection('left', list[index].formats.length - 1)} />
											</Col>
											<Col>
												{
													list[index].formats[
														productsSelected[secondEnv ? 'secondSlot' : 'firstSlot'][floor ? 'floor' : 'wall'].format
													].name
												}
											</Col>
											<Col>
												<RightArrow onClick={() => formatSelection('right', list[index].formats.length - 1)} />
											</Col>
										</Row>
									)}
								</div>
							)}
						</div>
					</div>
				)
			}
		}

		if (cards.length) {
			const cardRef = cards.filter(
				(card) => card.key === productsSelected[secondEnv ? 'secondSlot' : 'firstSlot'][floor ? 'floor' : 'wall'].id
			)
			const initialSlide = cards.indexOf(cardRef[0])

			return (
				<Carousel
					initialSlide={(initialSlide <= 0 && 1) || initialSlide}
					dots={false}
					centerMode
					infinite={false}
					swipeToSlide
					slidesToShow={width > 500 ? 5 : 2}
				>
					{cards}
				</Carousel>
			)
		}

		return (
			<EmptyMessageComponent>
				<Row justify='center'>
					<Col>
						<div className='title'>
							<strong>Oops</strong> <Intl id='visualization.productNotFound' />
						</div>
						<Button className={company} onClick={() => clearFilter(true)}>
							<Intl id='visualization.otherProducts' />
						</Button>
					</Col>
				</Row>
			</EmptyMessageComponent>
		)
	}

	const verticalCards = () => {
		const cards = []
		const list = getActualList()

		if (list.length) {
			for (let index = 0; index < list.length; width < 1920 ? (index += 2) : (index += 3)) {
				let secondId
				let secondThumbnail
				let secondSelected
				let thirdId
				let thirdThumbnail
				let thirdSelected
				const firstSelected =
					list[index].formats.length === 0
						? isSelected(list[index].id)
						: list[index].formats.some((item) => isSelected(item.value))
				const { format } = productsSelected[secondEnv ? 'secondSlot' : 'firstSlot'][floor ? 'floor' : 'wall']
				const firstId =
					list[index].formats.length === 0 ? list[index].id : list[index].formats[firstSelected ? format : 0].value
				const firstThumbnail =
					list[index].formats.length === 0
						? `url(${list[index].thumbnail ? `https://www.roomvo.com${list[index].thumbnail}` : ''})`
						: `url(${
								list[index].formats[firstSelected ? format : 0].thumbnailUrl
									? `https://www.roomvo.com${list[index].formats[firstSelected ? format : 0].thumbnailUrl}`
									: ''
						  })`

				if (index !== list.length - 1) {
					secondSelected =
						list[index + 1].formats.length === 0
							? isSelected(list[index + 1].id)
							: list[index + 1].formats.some((item) => isSelected(item.value))
					secondId =
						list[index + 1].formats.length === 0
							? list[index + 1].id
							: list[index + 1].formats[secondSelected ? format : 0].value
					secondThumbnail =
						list[index + 1].formats.length === 0
							? `url(${list[index + 1].thumbnail ? `https://www.roomvo.com${list[index + 1].thumbnail}` : ''})`
							: `url(${
									list[index + 1].formats[secondSelected ? format : 0].thumbnailUrl
										? `https://www.roomvo.com${list[index + 1].formats[secondSelected ? format : 0].thumbnailUrl}`
										: ''
							  })`
				}

				if (index < list.length - 2) {
					thirdSelected =
						list[index + 2].formats.length === 0
							? isSelected(list[index + 2].id)
							: list[index + 2].formats.some((item) => isSelected(item.value))
					thirdId =
						list[index + 2].formats.length === 0
							? list[index + 2].id
							: list[index + 2].formats[thirdSelected ? format : 0]?.value
					thirdThumbnail =
						list[index + 2].formats.length === 0
							? `url(${list[index + 2]?.thumbnail ? `https://www.roomvo.com${list[index + 2]?.thumbnail}` : ''})`
							: `url(${
									list[index + 2].formats[thirdSelected ? format : 0]?.thumbnailUrl
										? `https://www.roomvo.com${list[index + 2].formats[thirdSelected ? format : 0]?.thumbnailUrl}`
										: ''
							  })`
				}

				cards.push(
					<Row justify='space-around' key={list[index].id}>
						<Col>
							<div
								key={list[index].id}
								className={`horizontal-carousel-card${firstSelected ? '-selected' : ''}`}
								onClick={() => (!firstSelected ? productSelection(firstId) : {})}
								onKeyDown={() => {}}
								role='button'
								tabIndex={-1}
							>
								<div className='horizontal-carousel-card-img' style={{ backgroundImage: firstThumbnail }} />
								<div className='horizontal-carousel-text'>{list[index].name}</div>
								{firstSelected && (
									<div
										className={`horizontal-carousel-measures ${
											list[index].formats.length <= 1 ? '' : 'horizontal-carousel-measures-bordered'
										}`}
									>
										{list[index].formats.length <= 1 ? (
											<span className='horizontal-carousel-single-measure'>
												{list[index].formats.length === 0 ? (
													<Intl id='visualization.notInformed' />
												) : (
													list[index].formats[0].name
												)}
											</span>
										) : (
											<Row justify='space-between'>
												<Col>
													<LeftArrow onClick={() => formatSelection('left', list[index].formats.length - 1)} />
												</Col>
												<Col>
													{
														list[index].formats[
															productsSelected[secondEnv ? 'secondSlot' : 'firstSlot'][floor ? 'floor' : 'wall'].format
														].name
													}
												</Col>
												<Col>
													<RightArrow onClick={() => formatSelection('right', list[index].formats.length - 1)} />
												</Col>
											</Row>
										)}
									</div>
								)}
							</div>
						</Col>
						{index !== list.length - 1 && (
							<Col>
								<div
									key={list[index].id}
									className={`horizontal-carousel-card${secondSelected ? '-selected' : ''}`}
									onClick={() => (!secondSelected ? productSelection(secondId) : {})}
									onKeyDown={() => {}}
									role='button'
									tabIndex={-1}
								>
									<div className='horizontal-carousel-card-img' style={{ backgroundImage: secondThumbnail }} />
									<div className='horizontal-carousel-text'>{list[index + 1].name}</div>
									{secondSelected && (
										<div
											className={`horizontal-carousel-measures ${
												list[index + 1].formats.length <= 1 ? '' : 'horizontal-carousel-measures-bordered'
											}`}
										>
											{list[index + 1].formats.length <= 1 ? (
												<span className='horizontal-carousel-single-measure'>
													{list[index + 1].formats.length === 0 ? (
														<Intl id='visualization.notInformed' />
													) : (
														list[index + 1].formats[0].name
													)}
												</span>
											) : (
												<Row justify='space-between'>
													<Col>
														<LeftArrow onClick={() => formatSelection('left', list[index + 1].formats.length - 1)} />
													</Col>
													<Col>
														{
															list[index + 1].formats[
																productsSelected[secondEnv ? 'secondSlot' : 'firstSlot'][floor ? 'floor' : 'wall']
																	.format
															].name
														}
													</Col>
													<Col>
														<RightArrow onClick={() => formatSelection('right', list[index + 1].formats.length - 1)} />
													</Col>
												</Row>
											)}
										</div>
									)}
								</div>
							</Col>
						)}
						{width >= 1920 && index < list.length - 2 && (
							<Col>
								<div
									key={list[index].id}
									className={`horizontal-carousel-card${thirdSelected ? '-selected' : ''}`}
									onClick={() => (!thirdSelected ? productSelection(thirdId) : {})}
									onKeyDown={() => {}}
									role='button'
									tabIndex={-1}
								>
									<div className='horizontal-carousel-card-img' style={{ backgroundImage: thirdThumbnail }} />
									<div className='horizontal-carousel-text'>{list[index + 2].name}</div>
									{thirdSelected && (
										<div
											className={`horizontal-carousel-measures ${
												list[index + 2].formats.length <= 1 ? '' : 'horizontal-carousel-measures-bordered'
											}`}
										>
											{list[index + 2].formats.length <= 1 ? (
												<span className='horizontal-carousel-single-measure'>
													{list[index + 2].formats.length === 0 ? (
														<Intl id='visualization.notInformed' />
													) : (
														list[index + 2].formats[0].name
													)}
												</span>
											) : (
												<Row justify='space-between'>
													<Col>
														<LeftArrow onClick={() => formatSelection('left', list[index + 2].formats.length - 1)} />
													</Col>
													<Col>
														{
															list[index + 2].formats[
																productsSelected[secondEnv ? 'secondSlot' : 'firstSlot'][floor ? 'floor' : 'wall']
																	.format
															].name
														}
													</Col>
													<Col>
														<RightArrow onClick={() => formatSelection('right', list[index + 2].formats.length - 1)} />
													</Col>
												</Row>
											)}
										</div>
									)}
								</div>
							</Col>
						)}
					</Row>
				)
			}
		}

		if (cards.length) return cards

		return (
			<EmptyMessageComponent>
				<Row justify='center'>
					<Col>
						<div className='title'>
							<strong>Oops</strong> <Intl id='visualization.productNotFound' />
						</div>
						<Button className={company} onClick={() => clearFilter(true)}>
							<Intl id='visualization.otherProducts' />
						</Button>
					</Col>
				</Row>
			</EmptyMessageComponent>
		)
	}

	const totemCards = (firstSlot) => {
		const cards = []
		const list = getActualList()

		if (list.length) {
			for (let index = 0; index < list.length; index += 3) {
				let secondId
				let secondThumbnail
				let secondSelected
				let thirdId
				let thirdThumbnail
				let thirdSelected
				const firstSelected =
					list[index].formats.length === 0
						? isSelected(list[index].id)
						: list[index].formats.some((item) => isSelected(item.value))
				const { format } = productsSelected[!firstSlot ? 'secondSlot' : 'firstSlot'][floor ? 'floor' : 'wall']
				const firstId =
					list[index].formats.length === 0 ? list[index].id : list[index].formats[firstSelected ? format : 0]?.value
				const firstThumbnail =
					list[index]?.formats.length === 0
						? `url(${list[index]?.thumbnail ? `https://www.roomvo.com${list[index]?.thumbnail}` : ''})`
						: `url(${
								list[index].formats[firstSelected ? format : 0]?.thumbnailUrl
									? `https://www.roomvo.com${list[index].formats[firstSelected ? format : 0]?.thumbnailUrl}`
									: ''
						  })`

				if (index !== list.length - 1) {
					secondSelected =
						list[index + 1].formats.length === 0
							? isSelected(list[index + 1].id)
							: list[index + 1].formats.some((item) => isSelected(item.value))
					secondId =
						list[index + 1].formats.length === 0
							? list[index + 1].id
							: list[index + 1].formats[secondSelected ? format : 0]?.value
					secondThumbnail =
						list[index + 1]?.formats.length === 0
							? `url(${list[index + 1]?.thumbnail ? `https://www.roomvo.com${list[index + 1]?.thumbnail}` : ''})`
							: `url(${
									list[index + 1]?.formats[secondSelected ? format : 0]?.thumbnailUrl
										? `https://www.roomvo.com${list[index + 1]?.formats[secondSelected ? format : 0]?.thumbnailUrl}`
										: ''
							  })`
				}

				if (index < list.length - 2) {
					thirdSelected =
						list[index + 2].formats.length === 0
							? isSelected(list[index + 2].id)
							: list[index + 2].formats.some((item) => isSelected(item.value))
					thirdId =
						list[index + 2].formats.length === 0
							? list[index + 2].id
							: list[index + 2].formats[thirdSelected ? format : 0]?.value
					thirdThumbnail =
						list[index + 2].formats.length === 0
							? `url(${list[index + 2]?.thumbnail ? `https://www.roomvo.com${list[index + 2]?.thumbnail}` : ''})`
							: `url(${
									list[index + 2].formats[thirdSelected ? format : 0]?.thumbnailUrl
										? `https://www.roomvo.com${list[index + 2].formats[thirdSelected ? format : 0]?.thumbnailUrl}`
										: ''
							  })`
				}

				cards.push(
					<Row justify='space-around' key={list[index].id}>
						<Col>
							<div
								className={`totem-carousel-card${firstSelected ? '-selected' : ''}`}
								onClick={() => (!firstSelected ? productSelection(firstId) : {})}
								onKeyDown={() => {}}
								role='button'
								tabIndex={-1}
							>
								<div className='totem-carousel-card-img' style={{ backgroundImage: firstThumbnail }}>
									{firstSelected && (
										<div
											className='totem-carousel-zoom'
											onClick={() =>
												zoomProduct(productsSelected[secondEnv ? 'secondSlot' : 'firstSlot'][floor ? 'floor' : 'wall'])
											}
											onKeyDown={() => {}}
											role='button'
											tabIndex={-1}
										>
											<ZoomInOutlined />
										</div>
									)}
								</div>
								<div className='totem-carousel-text'>{list[index].name}</div>
								{firstSelected && (
									<div
										className={`totem-carousel-measures ${
											list[index].formats.length <= 1 ? '' : 'totem-carousel-measures-bordered'
										}`}
									>
										{list[index].formats.length <= 1 ? (
											<span className='totem-carousel-single-measure'>
												{list[index].formats.length === 0 ? (
													<Intl id='visualization.notInformed' />
												) : (
													list[index].formats[0].name
												)}
											</span>
										) : (
											<Row justify='space-between'>
												<Col>
													<LeftArrow onClick={() => formatSelection('left', list[index].formats.length - 1)} />
												</Col>
												<Col>
													{
														list[index].formats[
															productsSelected[!firstSlot ? 'secondSlot' : 'firstSlot'][floor ? 'floor' : 'wall'].format
														]?.name
													}
												</Col>
												<Col>
													<RightArrow onClick={() => formatSelection('right', list[index].formats.length - 1)} />
												</Col>
											</Row>
										)}
									</div>
								)}
							</div>
						</Col>
						{index !== list.length - 1 && (
							<Col>
								<div
									key={list[index].id}
									className={`totem-carousel-card${secondSelected ? '-selected' : ''}`}
									onClick={() => (!secondSelected ? productSelection(secondId) : {})}
									onKeyDown={() => {}}
									role='button'
									tabIndex={-1}
								>
									<div className='totem-carousel-card-img' style={{ backgroundImage: secondThumbnail }}>
										{secondSelected && (
											<div
												className='totem-carousel-zoom'
												onClick={() =>
													zoomProduct(
														productsSelected[secondEnv ? 'secondSlot' : 'firstSlot'][floor ? 'floor' : 'wall']
													)
												}
												onKeyDown={() => {}}
												role='button'
												tabIndex={-1}
											>
												<ZoomInOutlined />
											</div>
										)}
									</div>
									<div className='totem-carousel-text'>{list[index + 1].name}</div>
									{secondSelected && (
										<div
											className={`totem-carousel-measures ${
												list[index + 1].formats.length <= 1 ? '' : 'totem-carousel-measures-bordered'
											}`}
										>
											{list[index + 1].formats.length <= 1 ? (
												<span className='totem-carousel-single-measure'>
													{list[index + 1].formats.length === 0 ? (
														<Intl id='visualization.notInformed' />
													) : (
														list[index + 1].formats[0].name
													)}
												</span>
											) : (
												<Row justify='space-between'>
													<Col>
														<LeftArrow onClick={() => formatSelection('left', list[index + 1].formats.length - 1)} />
													</Col>
													<Col>
														{
															list[index + 1].formats[
																productsSelected[!firstSlot ? 'secondSlot' : 'firstSlot'][floor ? 'floor' : 'wall']
																	.format
															]?.name
														}
													</Col>
													<Col>
														<RightArrow onClick={() => formatSelection('right', list[index + 1].formats.length - 1)} />
													</Col>
												</Row>
											)}
										</div>
									)}
								</div>
							</Col>
						)}
						{index < list.length - 2 && (
							<Col>
								<div
									key={list[index].id}
									className={`totem-carousel-card${thirdSelected ? '-selected' : ''}`}
									onClick={() => (!thirdSelected ? productSelection(thirdId) : {})}
									onKeyDown={() => {}}
									role='button'
									tabIndex={-1}
								>
									<div className='totem-carousel-card-img' style={{ backgroundImage: thirdThumbnail }}>
										{thirdSelected && (
											<div
												className='totem-carousel-zoom'
												onClick={() =>
													zoomProduct(
														productsSelected[secondEnv ? 'secondSlot' : 'firstSlot'][floor ? 'floor' : 'wall']
													)
												}
												onKeyDown={() => {}}
												role='button'
												tabIndex={-1}
											>
												<ZoomInOutlined />
											</div>
										)}
									</div>
									<div className='totem-carousel-text'>{list[index + 2].name}</div>
									{thirdSelected && (
										<div
											className={`totem-carousel-measures ${
												list[index + 2].formats.length <= 1 ? '' : 'totem-carousel-measures-bordered'
											}`}
										>
											{list[index + 2].formats.length <= 1 ? (
												<span className='totem-carousel-single-measure'>
													{list[index + 2].formats.length === 0 ? (
														<Intl id='visualization.notInformed' />
													) : (
														list[index + 2].formats[0].name
													)}
												</span>
											) : (
												<Row justify='space-between'>
													<Col>
														<LeftArrow onClick={() => formatSelection('left', list[index + 2].formats.length - 1)} />
													</Col>
													<Col>
														{
															list[index + 2].formats[
																productsSelected[!firstSlot ? 'secondSlot' : 'firstSlot'][floor ? 'floor' : 'wall']
																	.format
															]?.name
														}
													</Col>
													<Col>
														<RightArrow onClick={() => formatSelection('right', list[index + 2].formats.length - 1)} />
													</Col>
												</Row>
											)}
										</div>
									)}
								</div>
							</Col>
						)}
					</Row>
				)
			}
		}

		if (cards.length) return cards

		return (
			<EmptyMessageComponent>
				<Row justify='center'>
					<Col>
						<div className='title'>
							<strong>Oops</strong> <Intl id='visualization.productNotFound' />
						</div>
						<Button className={company} onClick={() => clearFilter(true)}>
							<Intl id='visualization.otherProducts' />
						</Button>
					</Col>
				</Row>
			</EmptyMessageComponent>
		)
	}

	const lookProduct = (product) => {
		const dataLayer = {
			event: 'view_product',
			eventModel: {
				modal: 'products',
				modal_position: secondEnv ? 'right' : 'left',
				brand: company,
				device: totem === '1',
				pdv,
				language: lang,
				room_name: roomRedux?.name,
				room_code: roomRedux?.id ? roomRedux?.id : false,
				link: product.link,
				source_product_site: sku !== null,
			},
		}
		TagManager.dataLayer({
			dataLayer,
		})
		return window.open(product.link)
	}

	const productModalContent = () => {
		const { id, format } = productModalSelection
		if (id) {
			const product = productList.find((item) => item.id === id)
			const thumbnail =
				product.formats.length === 0
					? `url(${product.thumbnail ? `https://www.roomvo.com${product.thumbnail}` : ''})`
					: `url(${
							product.formats[format].thumbnailUrl
								? `https://www.roomvo.com${product.formats[format].thumbnailUrl}`
								: ''
					  })`
			return (
				<Row className='visualization-modal-row-content'>
					<Col>
						<div className='visualization-product-modal-img' style={{ backgroundImage: thumbnail }} />
						<span className='visualization-product-modal-name'>{product.name}</span>
						<span className='visualization-product-modal-measures'>
							{product.formats.length === 0 ? <Intl id='visualization.notInformed' /> : product.formats[format].name}
						</span>
					</Col>
					<Button
						className='visualization-product-modal-btn'
						onClick={() => {
							window.open(product.link, '_blank')
							lookProduct(product)
						}}
					>
						<Intl id='visualization.lookProduct' />
					</Button>
				</Row>
			)
		}
		return ''
	}

	const onFilterChange = (value, context) => {
		const filterObj = filterSelected[floor ? 'floor' : 'wall']
		filterObj[context] = value
		setFilterSelected({
			...filterSelected,
			[floor ? 'floor' : 'wall']: {
				...filterObj,
			},
		})
		const categoriesFilter = filterObj.categoria && filterObj.categoria.length > 0 && filterObj.categoria.join('|')
		const collectionsFilter = filterObj.colecao && filterObj.colecao.length > 0 && filterObj.colecao.join('|')
		const colorsFilter = filterObj.cor && filterObj.cor.length > 0 && filterObj.cor.join('|')
		const widthFilter = filterObj.formato && filterObj.formato.length > 0 && filterObj.formato.join('|')
		const speciesFilter = filterObj.visual && filterObj.visual.length > 0 && filterObj.visual.join('|')
		const shapesFilter = filterObj.forma && filterObj.forma.length > 0 && filterObj.forma.join('|')

		const dataLayer = {
			event: 'select_filters',
			eventModel: {
				modal: 'products',
				modal_position: secondEnv ? 'right' : 'left',
				type: floor ? 'piso' : 'parede',
				brand: company,
				device: totem === '1',
				pdv,
				language: lang,
				room_name: roomRedux?.name ? roomRedux?.name : false,
				room_code: roomRedux?.id ? roomRedux?.id : false,
				categories: categoriesFilter,
				collections: collectionsFilter,
				colors: colorsFilter,
				width: widthFilter,
				species: speciesFilter,
				shapes: shapesFilter,
				source_product_site: sku !== null,
			},
		}

		TagManager.dataLayer({
			dataLayer,
		})
	}

	const drawerContent = (firstSlot) => (
		<>
			<Row className='drawer-header' justify='space-between'>
				<Col className='drawer-filter-icon'>
					<FilterIcon />
				</Col>
				<Col className='drawer-title'>
					<Intl id='visualization.categories' />{' '}
				</Col>
				<Col className='drawer-close-icon'>
					<CloseIcon
						onClick={() => {
							setMenuOpen(
								firstSlot ? { ...menuOpen, firstSlot: false } : { ...menuOpen, secondSlot: false }
							) /* eventCloseModal(); */
						}}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<Search
						className='visualization-totem-search'
						allowClear
						value={searchValues[firstSlot ? 'firstSlot' : 'secondSlot'][floor ? 'floor' : 'wall'].value}
						onChange={(e) => onSearchChange(e.target.value)}
						onSearch={(e) => searchAction(e)}
						enterButton
					/>
				</Col>
			</Row>
			{company !== 'durafloor' && (
				<Row justify='space-between'>
					<Col>
						<Button disabled={floor} className='visualization-totem-surface-btn' onClick={() => setFloor(true)}>
							<Intl id='visualization.floor' />
						</Button>
					</Col>
					<Col>
						<Button disabled={!floor} className='visualization-totem-surface-btn' onClick={() => setFloor(false)}>
							<Intl id='visualization.wall' />
						</Button>
					</Col>
				</Row>
			)}
			<Row>
				<Col span={24}>
					<FilterComponent
						filterOptions={filterOptions[floor ? 'floor' : 'wall']}
						filterSelected={filterSelected[floor ? 'floor' : 'wall']}
						onFilterChange={onFilterChange}
						clearFilter={clearFilter}
						floor={floor}
						company={company}
					/>
				</Col>
			</Row>
			<Row className='visualization-totem-card-content'>
				<Col>{totemCards(firstSlot)}</Col>
			</Row>
		</>
	)

	const selectionCard = (firstSlot, floorSurface) => {
		const { id, format } = productsSelected[firstSlot ? 'firstSlot' : 'secondSlot'][floorSurface ? 'floor' : 'wall']
		const principalProductFinded = productList.find((item) => item.id === id)
		let secundaryProductFinded
		let thumbnail = ''
		if (!principalProductFinded) {
			secundaryProductFinded = productList
				.filter((item) => item.formats.length > 1)
				.find((item) => item.formats[format].value === id)
		}
		const product = principalProductFinded || secundaryProductFinded
		if (product) {
			thumbnail =
				product.formats.length === 0
					? `url(${product.thumbnail ? `https://www.roomvo.com${product.thumbnail}` : ''})`
					: `url(${
							product.formats[format].thumbnailUrl
								? `https://www.roomvo.com${product.formats[format].thumbnailUrl}`
								: ''
					  })`
		}
		return (
			<Col className='selection-card'>
				<div className='selection-card-title'>
					{floorSurface ? <Intl id='visualization.floor' /> : <Intl id='visualization.wall' />}
				</div>
				<div className='selection-card-body'>
					<div className='selection-card-img' style={{ backgroundImage: thumbnail }}>
						{product && (
							<div
								className='horizontal-carousel-zoom'
								onClick={() =>
									zoomProduct(productsSelected[firstSlot ? 'firstSlot' : 'secondSlot'][floorSurface ? 'floor' : 'wall'])
								}
								onKeyDown={() => {}}
								role='button'
								tabIndex={-1}
							>
								<ZoomInOutlined />
							</div>
						)}
					</div>
					<div className={`selection-card-name${product ? '' : '-empty'}`}>{product?.name}</div>
					{product && (
						<div className='horizontal-carousel-measures'>
							{product.formats.length <= 1 ? (
								<span className='horizontal-carousel-single-measure'>
									{product.formats.length === 0 ? <Intl id='visualization.notInformed' /> : product.formats[0].name}
								</span>
							) : (
								<Row justify='center'>
									<Col>{product.formats[format].name}</Col>
								</Row>
							)}
						</div>
					)}
				</div>
			</Col>
		)
	}

	const selectionContent = () => (
		<Row className='selection-content-container'>
			<Col>
				{onCompare && (
					<Row className={`selection-env-title ${onCompare && !secondEnv && 'select-env'}`}>
						<Col>
							<Intl id='visualization.env1' />
						</Col>
					</Row>
				)}
				<Row className={onCompare && !secondEnv && 'select-env'} justify='center'>
					{selectionCard(true, true)}
					{company !== 'durafloor' && selectionCard(true, false)}
				</Row>
				{onCompare && (
					<Row
						className={`selection-env-title ${onCompare && secondEnv && 'select-env'}`}
						style={{ marginTop: '22px' }}
					>
						<Col>
							<Intl id='visualization.env2' />
						</Col>
					</Row>
				)}
				{onCompare && (
					<Row className={onCompare && secondEnv && 'select-env'} justify='center'>
						{selectionCard(false, true)}
						{company !== 'durafloor' && selectionCard(false, false)}
					</Row>
				)}
			</Col>
		</Row>
	)

	const handleOpenPrivacyPolicy = () => {
		setPrivacyPolicyModalVisible(true)
		if (!openedPrivacyPolicy) {
			const dataLayer = {
				event: 'lgpd_privacy_policy',
				eventModel: {
					source_product_site: sku !== null,
					device: totem === '1',
					pdv,
					language: lang,
					brand: company,
					modal_position: 'share',
				},
			}
			TagManager.dataLayer({
				dataLayer,
			})
			setOpenedPrivacyPolicy(true)
		}
	}

	const eventLinkDSR = () => {
		const dataLayer = {
			event: 'lgpd_dsr',
			eventModel: {
				source_product_site: sku !== null,
				device: totem === '1',
				pdv,
				language: lang,
				brand: company,
				modal_position: 'share',
			},
		}
		TagManager.dataLayer({
			dataLayer,
		})
	}

	const consentPrivacyPolicy = () => (
		<Row className='consent-wrap'>
			<Checkbox
				className='consent-checkbox'
				disabled={totem === '0' && width > 1000 ? !toggleEmail : false}
				checked={acceptedConsentEmail}
				onChange={() => setAcceptedConsentEmail(!acceptedConsentEmail)}
			>
				{' '}
				Estou ciente que informei meu email para receber a simulação apresentada.
			</Checkbox>
			<span className='cta-privacy-policy'>
				<Button onClick={handleOpenPrivacyPolicy}>Clique</Button>{' '}
				<Button onClick={handleOpenPrivacyPolicy}>aqui</Button> para acessar a{' '}
				<Button onClick={handleOpenPrivacyPolicy}>Política</Button>{' '}
				<Button onClick={handleOpenPrivacyPolicy}>de</Button>{' '}
				<Button onClick={handleOpenPrivacyPolicy}>Privacidade</Button>{' '}
				<Button onClick={handleOpenPrivacyPolicy}>Dexco</Button>.
			</span>
		</Row>
	)

	return productList && firstSlotImg && productsLists ? (
		<VisualizationContainer compare={onCompare} scale={scale}>
			{loading && <LoadingComponent theme='dark' />}
			{totem === '0' && (
				<Row justify='start' className={`${company}-header header`}>
					<Col className='back-to-rooms' onClick={eventChangeRoom}>
						<Link to='/rooms'>
							{width > 999 ? <LeftOutlined /> : <PhotoLibrary />}
							<span>
								<Intl id='visualization.changeEnv' />
							</span>
						</Link>
					</Col>
				</Row>
			)}
			{totem === '1' && (
				<Row className={`${company}-header${totem === '1' ? '-totem' : ''} header${totem === '1' ? '-totem' : ''}`}>
					<Col className='back-to-rooms-totem' onClick={eventChangeRoom}>
						<Link to='/rooms'>
							<ArrowLeftOutlined />
						</Link>
					</Col>
					<Col className='header-totem-logo'>
						<LogoBrand brand={company} />
					</Col>
				</Row>
			)}
			<Row justify='space-between'>
				{width > 999 && totem === '0' && (
					<Col className='visualization-vertical-carousel-container'>
						{onCompare && (
							<Row className='visualization-cta-select-items'>
								<Col>
									{secondEnv ? <Intl id='visualization.selectItems2' /> : <Intl id='visualization.selectItems1' />}
								</Col>
							</Row>
						)}
						<Row justify='center'>
							<Col>
								<Search
									className='visualization-desktop-search'
									allowClear
									value={searchValues[secondEnv ? 'secondSlot' : 'firstSlot'][floor ? 'floor' : 'wall'].value}
									onChange={(e) => onSearchChange(e.target.value)}
									onSearch={(e) => searchAction(e)}
									enterButton
								/>
							</Col>
						</Row>
						<Row justify='center'>
							<Col span={21}>
								<FilterComponent
									filterOptions={filterOptions[floor ? 'floor' : 'wall']}
									filterSelected={filterSelected[floor ? 'floor' : 'wall']}
									onFilterChange={onFilterChange}
									clearFilter={clearFilter}
									floor={floor}
									company={company}
								/>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<Row>
									<Col>
										<Button
											disabled={floor}
											className='visualization-desktop-surface-btn'
											onClick={() => setFloor(true)}
										>
											{' '}
											<Intl id='visualization.floor' />
										</Button>
										{company !== 'durafloor' && (
											<Button
												disabled={!floor}
												className='visualization-desktop-surface-btn'
												onClick={() => setFloor(false)}
											>
												<Intl id='visualization.wall' />
											</Button>
										)}
									</Col>
								</Row>
								<Row>
									<Col className='vertical-cards-container' span={24}>
										{verticalCards()}
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				)}
				<Col style={{ maxHeight: '100vh' }}>
					<Button
						className={`visualization-rotate-btn${totem === '1' ? '-totem' : ''}`}
						onClick={() => orientationSelection()}
					>
						<Redo />
					</Button>
					<Button
						className={`visualization-clear-btn${totem === '1' ? '-totem' : ''}`}
						onClick={() => clearVisualization()}
					>
						<TrashIcon />
					</Button>

					{totem === '1' && (
						<Button
							className='visualization-menu-left-btn'
							onClick={() => {
								setMenuOpen({ ...menuOpen, firstSlot: true })
								setSecondEnv(false)
								eventOpenModal()
							}}
						>
							<MenuOutlined />
						</Button>
					)}
					{totem === '1' && onCompare && (
						<Button
							className='visualization-menu-right-btn'
							onClick={() => {
								setMenuOpen({ ...menuOpen, secondSlot: true })
								setSecondEnv(true)
								eventOpenModal()
							}}
						>
							<MenuOutlined />
						</Button>
					)}
					{(width < 1000 || totem === '1') && (
						<Button
							className={`visualization-share-btn${totem === '1' ? '-totem' : ''}`}
							onClick={() => eventOpenModalShare()}
						>
							<ShareIcon />
						</Button>
					)}
					{width < 1000 && totem === '0' && (
						<Button
							className={`visualization-search-btn${totem === '1' ? '-totem' : ''}${searchOn ? '-hidden' : ''}`}
							onClick={() => {
								setSearchOn(true)
								setFilterOn(false)
							}}
						>
							<SearchIcon />
						</Button>
					)}
					{width < 1000 && totem === '0' && (
						<>
							<div
								onKeyDown={() => {}}
								aria-label='Fechar busca'
								role='button'
								tabIndex={-1}
								onClick={() => {
									setSearchOn(false)
								}}
								className={`visualization-overlay ${!searchOn ? 'hidden' : ''}`}
							/>
							<div className={`visualization-search-box${totem === '1' ? '-totem' : ''}${!searchOn ? '-hidden' : ''}`}>
								<CloseOutlined className='search-close-icon' onClick={() => setSearchOn(false)} />
								<Search
									className='search-input'
									value={searchValues[secondEnv ? 'secondSlot' : 'firstSlot'][floor ? 'floor' : 'wall'].value}
									onChange={(e) => onSearchChange(e.target.value)}
									onSearch={(e) => searchAction(e)}
									enterButton={<ArrowCircleRight />}
									allowClear
								/>
							</div>
						</>
					)}
					{width < 1000 && totem === '0' && (
						<Button
							className={`visualization-filter-btn${totem === '1' ? '-totem' : ''}${filterOn ? '-hidden' : ''}`}
							onClick={() => {
								setFilterOn(true)
								setSearchOn(false)
								eventToogleProductModal(true)
							}}
						>
							<FilterIcon />
						</Button>
					)}
					{width < 1000 && (
						<>
							<div
								onKeyDown={() => {}}
								aria-label='Fechar filtro'
								role='button'
								tabIndex={-1}
								onClick={() => {
									setFilterOn(false)
									eventToogleProductModal(false)
								}}
								className={`visualization-overlay ${!filterOn ? 'hidden' : ''}`}
							/>
							<div className={`visualization-filter-box${totem === '1' ? '-totem' : ''}${!filterOn ? '-hidden' : ''}`}>
								<div>
									<FilterIcon className='visualization-filter-icon' />
									<span className='visualization-filter-box-title'>
										<Intl id='visualization.filter' />
									</span>
									<CloseOutlined
										className='filter-close-icon'
										onClick={() => {
											setFilterOn(false)
											eventToogleProductModal(false)
										}}
									/>
								</div>
								<FilterComponent
									filterOptions={filterOptions[floor ? 'floor' : 'wall']}
									filterSelected={filterSelected[floor ? 'floor' : 'wall']}
									onFilterChange={onFilterChange}
									clearFilter={clearFilter}
									floor={floor}
									company={company}
								/>
							</div>
						</>
					)}
					{!onCompare && (
						<Button
							id='compare-btn'
							className={`visualization-compare-btn${totem === '1' ? '-totem' : ''}`}
							onClick={() => {
								setOnCompare(true)
								eventCompareOpen()
							}}
						>
							<Intl id='visualization.compare' />
						</Button>
					)}
					{onCompare && totem === '1' && (
						<Button
							id='compare-btn'
							className={`visualization-compare-btn${totem === '1' ? '-totem' : ''}`}
							onClick={() => {
								setOnCompare(false)
								eventCompareClose()
							}}
						>
							<Intl id='visualization.close' />
						</Button>
					)}
					{onCompare && totem === '0' && (
						<>
							<Button
								disabled={!secondEnv}
								style={{ backgroundColor: !secondEnv ? 'rgba(0, 0, 0, 0.6)' : '#0000001a' }}
								className={`visualization-compare-btn-left${totem === '1' ? '-totem' : ''}`}
								onClick={() => setSecondEnv(false)}
							>
								<Intl id='visualization.env1' />
							</Button>
							<Button
								disabled={secondEnv}
								style={{ backgroundColor: secondEnv ? 'rgba(0, 0, 0, 0.6)' : '#0000001a' }}
								className={`visualization-compare-btn-right${totem === '1' ? '-totem' : ''}`}
								onClick={() => setSecondEnv(true)}
							>
								<Intl id='visualization.env2' />
							</Button>
							<Button
								className={`visualization-compare-btn-exit${totem === '1' ? '-totem' : ''}`}
								onClick={() => {
									setOnCompare(false)
									setSecondEnv(false)
									eventCompareClose()
								}}
							>
								<CloseOutlined />
							</Button>
						</>
					)}
					{onCompare ? (
						<ImgComparisonSlider>
							<Image
								slot='first'
								src={firstSlotImg}
								preview={false}
								placeholder
								className={`visualization-img${totem === '1' ? '-totem' : ''}`}
							/>
							<Image
								slot='second'
								src={secondSlotImg}
								preview={false}
								placeholder
								className={`visualization-img${totem === '1' ? '-totem' : ''}`}
							/>
						</ImgComparisonSlider>
					) : (
						<Image
							src={firstSlotImg}
							preview={false}
							placeholder
							className={`visualization-img${totem === '1' ? '-totem' : ''}`}
						/>
					)}
				</Col>
				{width < 1000 && (
					<Col style={{ position: 'absolute', bottom: '20px' }}>
						<div className='logo-powered'>
							<a href={roomvoPoweredLinks[company]} target='_blank' rel='noreferrer'>
								<img src='/images/powered-logo.png' alt='Powered Roomvo Logo' />
							</a>
						</div>
					</Col>
				)}
				{width > 999 && totem === '0' && (
					<Col className='visualization-selection-display-container'>
						<Row className='visualization-selection-container'>
							<Col>
								<Row>
									<Col>
										<span className='visualization-selection-span'>
											<Intl className='test' id='visualization.selectedItems' />
										</span>
									</Col>
								</Row>
								<Col>{selectionContent()}</Col>
							</Col>
						</Row>
						<Row className='visualization-share-container'>
							<Col>
								<Row>
									<Col>
										<span className='visualization-share-span'>
											<Intl id='visualization.share' />
										</span>
									</Col>
								</Row>
								{/* <Row>
							<Col>
								<Button onClick={() => eventDownload()} className='visualization-share-btn' icon={<DownloadOutlined style={{marginRight: 20}}/>}><Intl id='visualization.download' /></Button>
							</Col>
						</Row> */}
								<Row>
									<Col>
										{!toggleEmail && (
											<Button
												onClick={() => setToggleEmail(true)}
												className='visualization-mail-btn'
												icon={<MailOutlined style={{ marginRight: 20 }} />}
											>
												<Intl id='visualization.email' />
											</Button>
										)}
										{toggleEmail && (
											<Input.Group compact>
												<Input
													prefix={<MailOutlined />}
													value={email}
													onChange={(e) => setEmail(e.target.value)}
													type='e-mail'
													className='visualization-share-send-input'
												/>
												<Button
													disabled={!email}
													loading={emailLoading}
													onClick={() => sendEmailJaiminho()}
													className='visualization-share-send-btn'
												>
													{emailLoading ? '' : <Intl id='visualization.send' />}
												</Button>
											</Input.Group>
										)}
										<Row style={{ marginLeft: '18px' }}>{consentPrivacyPolicy()}</Row>
										{width > 1000 && (
											<Col style={{ position: 'relative', bottom: '0px', marginBottom: '18px' }}>
												<div className='logo-powered'>
													<a href={roomvoPoweredLinks[company]} target='_blank' rel='noreferrer'>
														<img src='/images/powered-logo.png' alt='Powered Roomvo Logo' />
													</a>
												</div>
											</Col>
										)}
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				)}
			</Row>
			{width < 1000 && company !== 'durafloor' && (
				<Row justify='center'>
					<Col className='visualization-horizontal-carousel-btns'>
						<Button
							disabled={floor}
							className='visualization-horizontal-carousel-left-btn'
							onClick={() => setFloor(true)}
						>
							{' '}
							<Intl id='visualization.floor' />
						</Button>
						<Button
							disabled={!floor}
							className='visualization-horizontal-carousel-right-btn'
							onClick={() => setFloor(false)}
						>
							<Intl id='visualization.wall' />
						</Button>
					</Col>
				</Row>
			)}
			{width < 1000 && (
				<Row>
					<Col className='visualization-carousel' span={24} style={{ marginBottom: '60px' }}>
						{horizontalCarouselCards()}
					</Col>
				</Row>
			)}
			{totem === '0' && (
				<ProductsModal
					className='visualization-product-modal'
					visible={productsModalVisible}
					onCancel={() => setProductsModalVisible(false)}
					footer={null}
					width='350px'
					closeIcon={<CloseIcon />}
				>
					{productModalContent()}
				</ProductsModal>
			)}
			{totem === '1' && (
				<ProductsTotemModal
					className='visualization-product-modal'
					visible={productsModalVisible}
					onCancel={() => setProductsModalVisible(false)}
					footer={null}
					width='650px'
					closeIcon={<CloseIcon />}
				>
					{productModalContent()}
				</ProductsTotemModal>
			)}
			<ShareModal
				className='visualization-product-modal'
				visible={shareModalVisible}
				onCancel={() => setShareModalVisible(false)}
				// width='350px'
				footer={null}
				closeIcon={<CloseIcon />}
			>
				<Row>
					<Col>
						{/* <span className='visualization-share-modal-title'><Intl id='visualization.shareModalTitle' /></span> */}
						{/* <Button onClick={() => {eventDownload()}} className='visualization-share-modal-btn'><Intl id='visualization.downloadImg' /></Button> */}
						<span className='visualization-share-modal-title'>
							<Intl id='visualization.shareModalSubtitle' />
						</span>
						<Input.Group compact>
							<Input
								prefix={<MailOutlined />}
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								type='e-mail'
								className='visualization-share-send-input'
							/>
							<Button
								disabled={!email}
								loading={emailLoading}
								onClick={() => sendEmailJaiminho()}
								className='visualization-share-send-btn'
							>
								{emailLoading ? '' : <Intl id='visualization.send' />}
							</Button>
						</Input.Group>
						{consentPrivacyPolicy()}
					</Col>
				</Row>
			</ShareModal>
			<TotemShareModal
				className='visualization-product-modal'
				visible={totemShareModalVisible}
				onCancel={() => {
					setTotemShareModalVisible(false)
					eventQRCode()
				}}
				width='650px'
				footer={null}
				closeIcon={<CloseIcon />}
			>
				<Row justify='center'>
					<Col>
						{/* <span className='visualization-share-modal-title'>
							<Intl id='visualization.shareModalQr' />
						</span>
						<QRCode style={{ marginLeft: '125px' }} value={getQrCode()} size={300} />
						<span className='visualization-share-modal-totem-obs'>
							<ObsDownload />
							<Intl id='visualization.shareModalQrObs' />
						</span> */}
						<span className='visualization-share-modal-title-email'>
							<Intl id='visualization.shareModalSubtitleTotem' />
						</span>
						<Input.Group compact>
							<Input
								prefix={<MailOutlined />}
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								type='e-mail'
								className='visualization-share-send-input'
							/>
							<Button
								loading={emailLoading}
								disabled={!email}
								onClick={() => sendEmailJaiminho()}
								className='visualization-share-send-btn'
							>
								{emailLoading ? '' : <Intl id='visualization.send' />}
							</Button>
						</Input.Group>
						{consentPrivacyPolicy()}
					</Col>
				</Row>
			</TotemShareModal>
			<PrivacyPolicyModal
				visible={privacyPolicyModalVisible}
				onCancel={() => setPrivacyPolicyModalVisible(false)}
				linkDSR={linksDSR(company)}
				totem={totem}
				eventLinkDSR={eventLinkDSR}
			/>
			<TotemDrawer
				closable={false}
				maskStyle={{ background: 'transparent' }}
				width='50%'
				placement='left'
				visible={menuOpen.firstSlot}
				onClose={() => {
					eventCloseModal()
					setMenuOpen({ ...menuOpen, firstSlot: false })
				}}
			>
				{drawerContent(true)}
			</TotemDrawer>
			<TotemDrawer
				closable={false}
				maskStyle={{ background: 'transparent' }}
				width='50%'
				placement='right'
				visible={menuOpen.secondSlot}
				onClose={() => {
					eventCloseModal()
					setMenuOpen({ ...menuOpen, secondSlot: false })
				}}
			>
				{drawerContent(false)}
			</TotemDrawer>
			<TourWrap setOnCompare={setOnCompare} sku={sku} company={company} />
		</VisualizationContainer>
	) : (
		<LoadingComponent />
	)
}

export default Visualization
