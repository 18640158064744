import React from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import Container from './style';

function LoadingComponent({ theme = false }) {
	return (
		<Container theme={theme}>
			{theme === 'dark' ? (
				<div className="dot-pulse" />
			) : (
				<LoadingOutlined style={{ fontSize: 80 }} spin />
			)}
		</Container>
	);
}

export default LoadingComponent;
