import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	z-index: 99999;
	background-color: ${(props) =>
		props.theme === 'dark'
			? 'rgba(0, 0, 0, 0.7)'
			: 'rgba(255, 255, 255, 0.96)'};
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	/* transform: translate(-50%, -50%) */

	.dot-pulse {
		position: relative;
		left: -9999px;
		width: 10px;
		height: 10px;
		border-radius: 5px;
		background-color: #dbdbdb;
		color: #dbdbdb;
		box-shadow: 9999px 0 0 -5px #dbdbdb;
		animation: dotPulse 1.5s infinite linear;
		animation-delay: 0.25s;
	}

	.dot-pulse::before,
	.dot-pulse::after {
		content: '';
		display: inline-block;
		position: absolute;
		top: 0;
		width: 10px;
		height: 10px;
		border-radius: 5px;
		background-color: #dbdbdb;
		color: #dbdbdb;
	}

	.dot-pulse::before {
		box-shadow: 9984px 0 0 -5px #dbdbdb;
		animation: dotPulseBefore 1.5s infinite linear;
		animation-delay: 0s;
	}

	.dot-pulse::after {
		box-shadow: 10014px 0 0 -5px #dbdbdb;
		animation: dotPulseAfter 1.5s infinite linear;
		animation-delay: 0.5s;
	}

	@keyframes dotPulseBefore {
		0% {
			box-shadow: 9984px 0 0 -5px #dbdbdb;
		}
		30% {
			box-shadow: 9984px 0 0 2px #dbdbdb;
		}
		60%,
		100% {
			box-shadow: 9984px 0 0 -5px #dbdbdb;
		}
	}

	@keyframes dotPulse {
		0% {
			box-shadow: 9999px 0 0 -5px #dbdbdb;
		}
		30% {
			box-shadow: 9999px 0 0 2px #dbdbdb;
		}
		60%,
		100% {
			box-shadow: 9999px 0 0 -5px #dbdbdb;
		}
	}

	@keyframes dotPulseAfter {
		0% {
			box-shadow: 10014px 0 0 -5px #dbdbdb;
		}
		30% {
			box-shadow: 10014px 0 0 2px #dbdbdb;
		}
		60%,
		100% {
			box-shadow: 10014px 0 0 -5px #dbdbdb;
		}
	}
`;

export default Container;
