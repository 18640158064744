import styled from 'styled-components'

export const Container = styled.div`
	position: relative;

	@media (min-width: 1920px) {
		width: 50%;
		display: flex;
		flex-wrap: wrap;
	}

	@media only screen and (max-width: 1919px) and (min-width: 1400px) {
		width: 50%;
		display: flex;
		flex-wrap: wrap;
	}

	.span-filter {
		left: 20px;
		top: 7px;
		@media only screen and (max-width: 1399px) {
			left: 10% !important;
		}
		/* font-size: 26px !important; */
		/* @media (min-width: 1000px) { height: 18px; }
			@media (width: 1920px) and (height: 1080) { height: 26px; } */
		/* width: 185px !important; */
	}
`

export const LabelAndCounter = styled.div`
	@media only screen and (max-width: 1920px) and (min-width: 1080px) {
		width: 65%;
	}

	@media only screen and (max-width: 1919px) and (min-width: 1400px) {
		width: 65%;
	}
	@media only screen and (max-width: 1399px) and (min-width: 1000px) {
		width: 50%;
	}
	position: absolute;
	z-index: 9;
	font-size: 14px;
	@media (min-width: 1000px) {
		height: 18px;
	}
	@media (width: 1920px) and (height: 1080) {
		height: 26px;
	}
	color: black;
	font-weight: 500;
	display: flex;
	justify-content: space-between;
	width: 145px;
	align-items: center;

	&::after {
		display: ${(props) => (props.counter > 0 ? 'flex' : 'none')};
		content: '${(props) => props.counter}';
		height: 20px;
		width: 20px;
		background: gray;
		border-radius: 100%;
		justify-content: center;
		align-items: center;
		color: #f7f6f6;
		font-size: 10px;
	}
`
