import styled from 'styled-components';

const handleColorType = (bgTheme) => {
	switch (bgTheme) {
		case 'portinari':
			return '#DDD8D2; background: #DDD8D2; border-radius: 100px 0px 0px 100px; color: black';
		case 'ceusa':
			return '#FF2B00; background: #FF2B00; border-radius: 100px 0px 0px 100px; color: white';
		case 'durafloor':
			return '#E3FF54; background: #E3FF54; border-radius: 100px 0px 0px 100px; color: black';
		default:
			return 'blue; background:blue; border-radius: 0px 100px 100px 0px; color: white';
	}
};

const ButtonContainer = styled.div`
    border:none;
    padding: 0;
    background: transparent;
    display: flex;
    justify-content: ${(props) => (props.direction ? 'left' : 'right')};
    z-index: 10;
    position: relative;
    margin-top: 55px;
    border-style: none;
    width: 100%;

    [ant-click-animating-without-extra-node]:after {
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
    }

    icons {
      margin: auto 0;
      padding-left: 10px;
      position: relative;
      top: 5px;
      color: ${(props) => (props.iconColor ? props.iconColor : 'black')};
    }
    
    
    .btn-default {
      background-color: ${({ bgTheme }) => handleColorType(bgTheme)};
      border: 3px solid ${(props) =>
				props.borderColor
					? props.borderColor
					: ({ bgTheme }) => handleColorType(bgTheme)};
      &:focus {
        background-color: ${(props) =>
					props.effectsColor ? ({ bgTheme }) => handleColorType(bgTheme) : ''};
      }

      @media (min-width: 524px){
        .btn-default {
          transform: scale(0.46041666666666664);
        }
    }
      width: 480px;
      height: 200px;
      width="100%" height="100%" 
      font-size: 25px;
      margin-bottom : 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 48px;
      transform: scale(1);
    }       
`;

export default ButtonContainer;
