import styled from 'styled-components'
import { Modal, Drawer } from 'antd'

export const VisualizationContainer = styled.div`
	font: Roboto;
	overflow-y: overlay;
	height: 100%;

	.header {
		height: 42px;
		width: 100%;
		font-size: 14px;
		font-weight: bold;
		position: fixed;
		z-index: 999;

		a {
			color: #29251f;
			display: flex;
		}
	}

	.header-totem {
		height: 152px;
		width: 100%;
		position: fixed;
		z-index: 999;
		background-color: white;

		.anticon-arrow-left {
			line-height: 152px;
			color: #161e21;

			svg {
				width: 35px;
				height: 40px;
				margin-top: 60px;
				margin-left: 40px;
			}
		}
	}

	.portinari-header,
	.portinari-rooms-brand-bullet {
		background-color: #ddd8d2;
	}

	.visualization-overlay {
		position: absolute;
		height: 100vh;
		width: 100vw;
		z-index: 10;
		background-color: rgba(0, 0, 0, 0.4);
		visibility: visible;
		opacity: 1;
		-webkit-transition: opacity 400ms, visibility 400ms;
		-ms-transition: opacity 400ms, visibility 400ms;
		transition: opacity 400ms, visibility 400ms;
	}

	.visualization-overlay.hidden {
		/* display: none; */
		opacity: 0;
		visibility: hidden;
		/* background-color: rgba(0,0,0,.0); */
	}

	.ceusa-header,
	.ceusa-rooms-brand-bullet {
		background-color: #fe5000;

		a {
			color: white;
		}
		svg path {
			fill: white;
		}
	}
	.durafloor-header {
		background-color: #f2f2f2;
	}

	.durafloor-rooms-brand-bullet {
		background-color: #edef8e;
	}

	.back-to-site {
		margin-right: 30px;

		span {
			margin-right: 10px;
			line-height: 42px;
		}

		svg {
			height: 17px;
			margin-top: 11px;
		}
	}

	.back-to-rooms {
		span:not(.anticon-left) {
			line-height: 42px;
			margin-left: 13px;
		}

		svg {
			height: 17px;
			margin-top: 11px;
			margin-left: 29px;
		}
	}

	.visualization-img {
		max-width: 55vw;
		margin-top: 42px;
		max-height: calc(100vh - 42px);
	}

	.visualization-img-totem {
		width: 100vw;
		margin-top: 152px;
	}

	.visualization-compare-btn {
		position: absolute;
		font-size: 18px;
		top: 42px;
		height: 52px;
		width: 298px;
		z-index: 9;
		color: white;
		background: rgba(0, 0, 0, 0.6);
		backdrop-filter: blur(10px);
		border-radius: 0px 0px 10px 10px;
		border: unset;
		left: 50%;
		transform: translate(-50%, 0%);
	}

	.visualization-compare-btn-left {
		position: absolute;
		font-size: 18px;
		top: 42px;
		height: 52px;
		width: 157.5px;
		z-index: 9;
		color: white;
		background: rgba(0, 0, 0, 0.6);
		backdrop-filter: blur(10px);
		border-radius: 0px 0px 0px 10px;
		border: unset;
		right: 50%;
	}

	.visualization-compare-btn-exit {
		position: absolute;
		top: 51px;
		right: 13px;
		z-index: 9;
		color: white;
		background: rgba(0, 0, 0, 0.6);
		backdrop-filter: blur(10px);
		height: 36px;
		width: 36px;
		border-radius: 36px;
		border: unset;

		svg {
			height: 10px;
			width: 10px;
			margin-left: -1.2px;
			margin-bottom: 1px;
		}
	}

	.visualization-compare-btn-left[disabled],
	.visualization-compare-btn-right[disabled],
	.visualization-compare-btn-right-totem[disabled],
	.visualization-compare-btn-left-totem[disabled] {
		background: rgba(0, 0, 0, 0.1);
		color: white;
	}

	.visualization-compare-btn-right {
		position: absolute;
		font-size: 18px;
		top: 42px;
		height: 52px;
		width: 157.5px;
		z-index: 9;
		color: white;
		background: rgba(0, 0, 0, 0.6);
		backdrop-filter: blur(10px);
		border-radius: 0px 0px 10px 0px;
		border: unset;
		left: 50%;
	}

	.visualization-compare-btn-totem {
		position: absolute;
		font-size: 18px;
		top: 152px;
		height: 52px;
		width: 298px;
		z-index: 9;
		color: white;
		background: rgba(0, 0, 0, 0.6);
		backdrop-filter: blur(10px);
		border-radius: 0px 0px 10px 10px;
		border: unset;
		left: 50%;
		transform: translate(-50%, 0%);
	}

	.visualization-compare-btn-left-totem {
		position: absolute;
		font-size: 18px;
		top: 152px;
		height: 52px;
		width: 157.5px;
		z-index: 9;
		color: white;
		background: rgba(0, 0, 0, 0.6);
		backdrop-filter: blur(10px);
		border-radius: 0px 0px 0px 10px;
		border: unset;
		right: 50%;
	}

	.visualization-compare-btn-exit-totem {
		position: absolute;
		top: 160px;
		right: 13px;
		z-index: 9;
		color: white;
		background: rgba(0, 0, 0, 0.6);
		backdrop-filter: blur(10px);
		height: 36px;
		width: 36px;
		border-radius: 36px;
		border: unset;

		svg {
			height: 10px;
			width: 10px;
			margin-left: -1.2px;
			margin-bottom: 1px;
		}
	}

	.visualization-compare-btn-right-totem {
		position: absolute;
		font-size: 18px;
		top: 152px;
		height: 52px;
		width: 157.5px;
		z-index: 9;
		color: white;
		background: rgba(0, 0, 0, 0.6);
		backdrop-filter: blur(10px);
		border-radius: 0px 0px 10px 0px;
		border: unset;
		left: 50%;
	}

	.ceusa-header-totem {
		.header-totem-logo svg g path.a {
			fill: #fe5000;
		}
	}

	.header-totem-logo svg {
		left: calc(50vw - 75px);
		top: calc(50% - 10px);
		position: relative;
		transform: translate(-50%, -50%);
	}

	[ant-click-animating-without-extra-node='true']::after {
		display: none;
	}

	.visualization-horizontal-carousel-btns {
		margin-top: ${(props) => (props.compare ? '25px' : '32px')};
	}

	.visualization-carousel {
		margin-top: ${(props) => (props.compare && props.company === 'durafloor' ? '25px' : '32px')};
	}

	.visualization-horizontal-carousel-left-btn {
		background: rgba(196, 196, 196, 0.3);
		box-shadow: 0px 4px 15px rgba(2, 2, 2, 0.15);
		border-radius: 5px 0px 0px 5px;
		border: unset;
		font-weight: 500;
		font-size: 14px;
		color: #666666;
	}

	.visualization-horizontal-carousel-right-btn {
		background: rgba(196, 196, 196, 0.3);
		box-shadow: 0px 4px 15px rgba(2, 2, 2, 0.15);
		border-radius: 0px 5px 5px 0px;
		border: unset;
		font-weight: 500;
		font-size: 14px;
		color: #666666;
	}

	.visualization-horizontal-carousel-left-btn[disabled],
	.visualization-horizontal-carousel-right-btn[disabled] {
		background: #666666;
		color: white;
	}

	.horizontal-carousel-card {
		height: 174px;
		width: 114px;
		border-radius: 5px;
		padding-top: 7px;
		font-size: 12px;
	}

	.horizontal-carousel-card-selected {
		height: 174px;
		width: 114px;
		border-radius: 5px;
		padding-top: 7px;
		font-size: 12px;
		background-color: rgba(203, 203, 203, 0.2);
	}

	.horizontal-carousel-card-img {
		width: 90px;
		height: 90px;
		margin-top: 7px;
		margin-left: 12px;
		margin-bottom: 6px;
		background-repeat: no-repeat;
		background-size: cover;
		display: flex;
		justify-content: end;
		align-items: end;
	}

	.horizontal-carousel-text {
		margin-left: 12px;
		margin-right: 12px;
		color: #161e21;
		font-weight: 500;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.horizontal-carousel-measures {
		margin-left: 7px;
		margin-right: 7px;
		height: 32px;
		line-height: 32px;
		font-weight: 700;
		font-size: 10px;
		color: #555555;
		text-align: center;

		.ant-row {
			margin-left: 5px;
			margin-right: 5px;
		}
	}

	.horizontal-carousel-measures-bordered {
		border: 1px solid #c4c4c4;
		border-radius: 5px;
	}

	.horizontal-carousel-zoom {
		margin: 0 5px 5px 0;
		height: 32px;
		width: 32px;
		border-radius: 32px;
		background: rgba(0, 0, 0, 0.6);
		backdrop-filter: blur(10px);
		position: relative;

		span {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: white;
		}
	}

	.visualization-rotate-btn {
		position: absolute;
		background: rgba(0, 0, 0, 0.6);
		border-radius: 0px 10px 0px 0px;
		z-index: 9;
		border: unset;
		color: white;
		bottom: 0px;
		left: 50%;

		svg {
			margin-top: 3px;
		}
	}

	.visualization-clear-btn {
		position: absolute;
		background: rgba(0, 0, 0, 0.6);
		border-radius: 10px 0px 0px 0px;
		z-index: 9;
		border: unset;
		color: white;
		bottom: 0px;
		right: 50%;

		svg {
			margin-top: 2px;
		}
	}

	.visualization-rotate-btn-totem {
		position: absolute;
		background: rgba(0, 0, 0, 0.6);
		border-radius: 0px 10px 0px 0px;
		z-index: 9;
		border: unset;
		color: white;
		bottom: ${(props) => (props.compare ? '7px' : '0px')};
		left: 50%;
		height: 64px;
		width: 64px;
		transition: unset;

		svg {
			height: 32px;
			width: 32px;
			margin-top: 5px;
		}
	}

	.visualization-clear-btn-totem {
		position: absolute;
		background: rgba(0, 0, 0, 0.6);
		border-radius: 10px 0px 0px 0px;
		z-index: 9;
		border: unset;
		color: white;
		bottom: ${(props) => (props.compare ? '7px' : '0px')};
		right: 50%;
		height: 64px;
		width: 64px;
		transition: unset;

		svg {
			height: 32px;
			width: 32px;
			margin-top: 5px;
		}
	}

	.visualization-share-btn-totem {
		position: absolute;
		background: rgba(0, 0, 0, 0.6);
		border-radius: 10px 0px 0px 10px;
		width: 80px;
		height: 80px;
		z-index: 9;
		border: unset;
		color: white;
		top: 331px;
		right: 0;

		svg {
			margin-top: 8px;
			height: 24px;
			width: 24px;
		}
	}

	.visualization-search-btn-totem {
		position: absolute;
		background: rgba(0, 0, 0, 0.6);
		border-radius: 0px 10px 10px 0px;
		width: 80px;
		height: 80px;
		z-index: 9;
		border: unset;
		color: white;
		top: 331px;
		left: 0;
		opacity: 1;
		transition-timing-function: linear;
		transition-duration: 2s, 0.1;
		transition-property: opacity, visibility;
		transition-delay: 0.4s, 0.4s;

		svg {
			margin-top: 8px;
			height: 24px;
			width: 24px;

			path {
				stroke: white;
			}
		}
	}
	.visualization-search-btn-totem-hidden {
		position: absolute;
		background: rgba(0, 0, 0, 0.6);
		border-radius: 0px 10px 10px 0px;
		width: 80px;
		height: 80px;
		z-index: 9;
		border: unset;
		color: white;
		top: 331px;
		left: 0;
		opacity: 0;
		visibility: hidden;
		transition-timing-function: linear;
		transition-duration: 0.5s, 0.1;
		transition-property: opacity, visibility;
		transition-delay: 0s, 0.2s;

		svg {
			margin-top: 8px;
			height: 24px;
			width: 24px;

			path {
				stroke: white;
			}
		}
	}

	.visualization-menu-left-btn {
		position: absolute;
		background: rgba(0, 0, 0, 0.6);
		border-radius: 0px 0px 10px 10px;
		width: 80px;
		height: 80px;
		z-index: 9;
		border: unset;
		color: white;
		top: 152px;
		left: 120px;
		opacity: 1;
		transition-timing-function: linear;
		transition-duration: 2s, 0.1;
		transition-property: opacity, visibility;
		transition-delay: 0.4s, 0.4s;

		svg {
			margin-top: 8px;
			height: 24px;
			width: 24px;

			path {
				stroke: white;
			}
		}
	}
	.visualization-menu-left-btn-hidden {
		position: absolute;
		background: rgba(0, 0, 0, 0.6);
		border-radius: 0px 10px 10px 0px;
		width: 80px;
		height: 80px;
		z-index: 9;
		border: unset;
		color: white;
		top: 152px;
		left: 120px;
		opacity: 0;
		visibility: hidden;
		transition-timing-function: linear;
		transition-duration: 0.5s, 0.1;
		transition-property: opacity, visibility;
		transition-delay: 0s, 0.2s;

		svg {
			margin-top: 8px;
			height: 24px;
			width: 24px;

			path {
				stroke: white;
			}
		}
	}

	.visualization-menu-right-btn {
		position: absolute;
		background: rgba(0, 0, 0, 0.6);
		border-radius: 0px 0px 10px 10px;
		width: 80px;
		height: 80px;
		z-index: 9;
		border: unset;
		color: white;
		top: 152px;
		right: 120px;
		opacity: 1;
		transition-timing-function: linear;
		transition-duration: 2s, 0.1;
		transition-property: opacity, visibility;
		transition-delay: 0.4s, 0.4s;

		svg {
			margin-top: 8px;
			height: 24px;
			width: 24px;

			path {
				stroke: white;
			}
		}
	}
	.visualization-menu-right-btn-hidden {
		position: absolute;
		background: rgba(0, 0, 0, 0.6);
		border-radius: 0px 10px 10px 0px;
		width: 80px;
		height: 80px;
		z-index: 9;
		border: unset;
		color: white;
		top: 152px;
		right: 120px;
		opacity: 0;
		visibility: hidden;
		transition-timing-function: linear;
		transition-duration: 0.5s, 0.1;
		transition-property: opacity, visibility;
		transition-delay: 0s, 0.2s;

		svg {
			margin-top: 8px;
			height: 24px;
			width: 24px;

			path {
				stroke: white;
			}
		}
	}

	.visualization-vertical-carousel-container {
		width: 22.5vw;
		margin-top: 42px;
		height: calc(100vh - 44px);
		overflow: hidden;

		.vertical-cards-container {
			height: calc(100vh - 44px - 380px);
			overflow: hidden;
			overflow-y: overlay;
		}

		.visualization-filter-select {
			width: 196px;
			height: 32px;
			margin-left: 35px;
			margin-bottom: 15px;
			background: #f7f6f6;
			border: 1px solid #ddd8d2;
			border-radius: 10px;
			box-shadow: unset;
			font-size: 12px;

			.ant-select-selection-overflow {
				display: none;
			}

			.ant-select-selector {
				background: transparent;
				border: unset;
			}

			.ant-select-focused {
				box-shadow: unset;
			}

			::selection {
				background: transparent;
			}
		}
		@media only screen and (min-width: 1920px) and (min-width: 1080px) {
			.visualization-filter-select {
				width: 100% !important;
				margin-left: 10px !important;
			}
		}

		@media only screen and (max-width: 1919px) and (min-width: 1400px) {
			.visualization-filter-select {
				margin-left: 10px !important;
			}
		}
		@media only screen and (max-width: 1399px) and (min-width: 1000px) {
			.visualization-filter-select {
				width: 100% !important;
				margin-left: 0px !important;
			}
		}

		.visualization-desktop-surface-btn {
			color: #585c5c;
			border: unset;
			font-weight: 600;
			font-size: 18px;
			background: transparent;
			width: calc(11.25vw - 50px);
			padding-left: 5px;
			margin-bottom: 20px;
			border-radius: 0;

			:first-child {
				margin-right: 20px;
				margin-left: 30px;
			}

			span {
				display: flex;
			}
		}

		.visualization-desktop-surface-btn[disabled] {
			color: black;
			border: unset;
			border-bottom: solid 2px black;
		}

		.span-categorias {
			left: 50px;
			top: 7.5px;
		}

		.span-cores {
			left: 50px;
			top: 53.5px;
		}

		.span-visuais {
			left: 50px;
			top: 100.5px;
		}

		.span-formatos {
			left: 50px;
			top: 148.5px;
		}

		.span-colecoes {
			left: 50px;
			top: 195.5px;
		}

		.span-formas {
			left: 50px;
			top: 242.5px;
		}

		.visualization-filter-footer {
			width: 135px;
			height: 19px;
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 700;
			font-size: 16px;
			line-height: 19px;
			border-bottom: 1px solid #a2a2a2;
			color: #a2a2a2;
			float: right;
			margin: 0px 15px 15px 0;
			cursor: pointer;

			svg {
				margin-right: 5px;
			}
		}

		.visualization-cta-select-items {
			/* padding: 17px 0 0 18px; */
			padding: 0px 0 0 18px;
			color: #000;
			font-size: 22px;
			font-weight: 700;
			line-height: 39px;
			letter-spacing: 0.05em;
		}

		.visualization-desktop-search {
			width: calc(22.5vw - 50px);
			margin-bottom: 28px;
			/* margin-top: 18px; */
			background-color: transparent;
			border: unset;

			span {
				margin: 0;
			}

			.ant-input-search .ant-input:hover,
			.ant-input-search .ant-input:focus {
				border: unset;
			}

			.ant-input-group-addon {
				background: transparent;
				border: unset;
			}
			.ant-btn-primary {
				background: transparent;
				border: unset;
				margin-left: 8px;
				margin-top: -2px;
			}

			.ant-input-affix-wrapper,
			.ant-input-affix-wrapper:hover,
			.ant-input-affix-wrapper:focus {
				height: 32px;
				background-color: transparent;
				border: unset;
				border-bottom: 1px solid #161e21;
				padding-left: 5px;
				margin-left: 13.5px;
				box-shadow: unset;
			}

			.ant-input {
				background-color: transparent;
				color: #161e21;
			}

			button svg {
				margin-top: 11px;
				height: 21px;
				width: 21px;
				color: #161e21;
			}
		}
	}

	.logo-powered img {
		position: relative;
		width: 50%;
		margin: 0 auto;
		display: flex;
	}

	.visualization-selection-display-container {
		width: 22.5vw;
		margin-top: 42px;
		overflow-y: overlay;
		height: calc(100vh - 43px);

		.selection-content-container {
			/* margin-left: calc(0.1*22.5vw); */
			/* margin-left: 18px;
			margin-top: 14px; */
			margin: 15px;

			.ant-col {
				width: 100%;
			}

			.selection-card-body {
				background: #faf9f8;
				border-radius: 0px 0px 5px 5px;
				padding-top: 15px;
			}

			.selection-card-img {
				height: calc(0.303 * 22.5vw);
				width: calc(0.303 * 22.5vw);
				margin: auto;
				margin-bottom: 7px;
				background-repeat: no-repeat;
				background-size: cover;
				display: flex;
				display: -webkit-box;
				-webkit-box-pack: end;
				-ms-flex-pack: end;
				justify-content: end;
				-webkit-box-align: end;
				-ms-flex-align: end;
				align-items: end;
				cursor: pointer;
			}

			.selection-card-name {
				font-weight: 500;
				font-size: 14px;
				color: #161e21;
				width: calc(0.303 * 22.5vw);
				margin: auto;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}

			.selection-card-name-empty {
				width: calc(0.303 * 22.5vw);
				height: 54px;
			}

			.selection-card {
				width: calc(0.37 * 22.5vw);

				:first-child {
					margin-right: calc(0.046 * 22.5vw);
				}
			}

			.selection-env-title {
				font-weight: 700;
				font-size: 20px;
				color: #000000;
				padding-left: 14px;
			}

			.select-env {
				background-color: #ddd8d233;
			}

			.selection-card-title {
				font-weight: 600;
				font-size: 18px;
				color: #000000;
				border-bottom: 2px solid #000000;
			}
		}

		.ant-input-group.ant-input-group-compact {
			margin-top: 30px;
			margin-left: calc(0.11 * 22.5vw);
		}

		.ant-input-group.ant-input-group-compact > *:last-child.visualization-share-send-btn {
			width: 67px;
			height: calc(0.125 * 22.5vw);
			min-height: 36px;
			max-height: 54px;
			background: #666666;
			border-radius: 0px 10px 10px 0px;
			border: unset;
			color: white;
			padding: 0;
		}

		.ant-input-group.ant-input-group-compact > *:first-child.visualization-share-send-input:active,
		.ant-input-group.ant-input-group-compact > *:first-child.visualization-share-send-input:hover,
		.ant-input-group.ant-input-group-compact > *:first-child.visualization-share-send-input:focus {
			box-shadow: unset;
		}

		.ant-input-group.ant-input-group-compact > *:first-child.visualization-share-send-input {
			width: calc(0.78 * 22.5vw - 67px);
			max-width: calc(300px - 67px);
			height: calc(0.125 * 22.5vw);
			min-height: 36px;
			max-height: 54px;
			border: 1px solid #666666;
			border-radius: 10px 0px 0px 10px;
			margin-bottom: 18px;

			svg {
				color: #cdcdcd;
			}

			::selection {
				background: none;
			}
		}

		.consent-wrap {
			.consent-checkbox {
				margin: 0 0 9px;
			}

			.cta-privacy-policy {
				padding: 0 8px 0;
				margin-bottom: 18px;
				text-align: center;

				.ant-btn-default {
					color: #1890ff;
					margin: 0 !important;
					padding: 0 !important;
					border: none !important;
					height: auto;
					line-height: 1.5;
				}
			}
		}

		.visualization-share-span {
			font-weight: 600;
			font-size: 22px;
			display: flex;
			color: #000000;
			margin-left: 18px;
			/* padding-right: calc(0.78 * 22.5vw - 150px);  */
			border-bottom: 1px solid #ddd8d2;
		}

		.visualization-selection-span {
			font-weight: 600;
			font-size: 22px;
			text-align: center;
			color: #000000;
			margin-left: 18px;
			/* padding-right: calc(0.78 * 22.5vw - 150px ); */
			border-bottom: 1px solid #ddd8d2;
			/* padding-right: 0px; */
		}
		@media (max-width: 1230px) {
			.visualization-selection-span {
				font-size: 20px !important;
			}
		}
		@media (max-width: 1100px) {
			.visualization-selection-span {
				font-size: 18px !important;
			}
		}

		.visualization-share-btn,
		.visualization-mail-btn {
			width: calc(0.78 * 22.5vw);
			margin-left: calc(0.11 * 22.5vw);
			height: calc(0.125 * 22.5vw);
			max-width: 300px;
			min-height: 36px;
			max-height: 54px;
			background: #666666;
			border-radius: 10px;
			color: white;
			font-weight: 600;
			font-size: calc(14px + 0.008 * 22.5vw);
			border: unset;
			margin-top: 30px;
		}

		.visualization-mail-btn {
			margin-bottom: 18px;
		}

		.visualization-share-btn:hover,
		.visualization-mail-btn:hover,
		.visualization-share-btn:focus,
		.visualization-mail-btn:focus {
			border: unset;
		}

		.visualization-share-container {
			height: calc(0.25 * 22.5vw + 126px);
		}

		.visualization-selection-container {
			height: calc(100vh - 0.25 * 22.5vw - 126px - 50px);
			overflow: overlay;
			display: block;
		}
	}

	@media (max-width: 999px) {
		.header {
			height: 36px;
			font-size: 10px;
		}

		.back-to-site {
			margin-right: 16px;

			span {
				margin-right: 5px;
				line-height: 36px;
			}

			svg {
				height: 13.5px;
				margin-top: 10.5px;
			}
		}

		.back-to-rooms {
			span:not(.anticon-left) {
				line-height: 36px;
				margin-left: 4px;
			}

			svg {
				height: 17px;
				margin-top: 8px;
				margin-left: 14px;
			}
		}

		.visualization-compare-btn {
			top: 36px;
			font-size: 10px;
			width: 183px;
			height: 36px;
		}

		.visualization-compare-btn-right {
			top: 36px;
			font-size: 10px;
			width: 92px;
			height: 36px;
		}

		.visualization-compare-btn-left {
			top: 36px;
			font-size: 10px;
			width: 92px;
			height: 36px;
		}

		.visualization-compare-btn-exit {
			top: 40px;
			right: 10px;
			height: 32px;
			width: 32px;
			border-radius: 32px;

			svg {
				height: 8px;
				width: 8px;
				margin-left: -2.2px;
				margin-bottom: 2px;
			}
		}

		.visualization-img {
			max-width: 100vw;
			width: 100vw;
			margin-top: 36px;
		}

		.visualization-rotate-btn {
			position: absolute;
			background: rgba(0, 0, 0, 0.6);
			border-radius: 0px 10px 0px 0px;
			bottom: ${(props) => (props.compare ? '7px' : '0px')};
			left: 50%;
			z-index: 9;
			border: unset;
			color: white;
			transition: unset;

			svg {
				height: 16px;
				width: 16px;
				margin-top: 4px;
			}
		}

		.visualization-clear-btn {
			position: absolute;
			background: rgba(0, 0, 0, 0.6);
			border-radius: 10px 0px 0px 0px;
			z-index: 9;
			border: unset;
			color: white;
			bottom: ${(props) => (props.compare ? '7px' : '0px')};
			right: 50%;
			transition: unset;

			svg {
				margin-top: 3px;
			}
		}

		.visualization-share-btn {
			position: absolute;
			background: rgba(0, 0, 0, 0.6);
			border-radius: 10px 0px 0px 10px;
			width: 45px;
			height: 42px;
			z-index: 9;
			border: unset;
			color: white;
			bottom: ${(props) => (props.compare ? '50px' : '43px')};
			right: 0;
			transition: unset;

			svg {
				margin-top: 8px;
				height: 14px;
				width: 16px;
			}
		}

		.visualization-search-btn {
			position: absolute;
			background: rgba(0, 0, 0, 0.6);
			border-radius: 0px 10px 10px 0px;
			width: 45px;
			height: 42px;
			z-index: 9;
			border: unset;
			color: white;
			top: 125px;
			left: 0;
			opacity: 1;
			transition-timing-function: linear;
			transition-duration: 2s, 0.1;
			transition-property: opacity, visibility;
			transition-delay: 0.4s, 0.4s;

			svg {
				margin-top: 8px;
				height: 14px;
				width: 16px;

				path {
					stroke: white;
				}
			}
		}
		.visualization-search-btn-hidden {
			position: absolute;
			background: rgba(0, 0, 0, 0.6);
			border-radius: 0px 10px 10px 0px;
			width: 45px;
			height: 42px;
			z-index: 9;
			border: unset;
			color: white;
			top: 125px;
			left: 0;
			opacity: 0;
			visibility: hidden;
			transition-timing-function: linear;
			transition-duration: 0.5s, 0.1;
			transition-property: opacity, visibility;
			transition-delay: 0s, 0.2s;

			svg {
				margin-top: 8px;
				height: 14px;
				width: 16px;

				path {
					stroke: white;
				}
			}
		}

		.visualization-search-box {
			position: absolute;
			left: 0;
			top: 125px;
			width: 262px;
			height: 42px;
			background: rgba(244, 244, 244, 0.6);
			backdrop-filter: blur(10px);
			border-radius: 0px 10px 10px 0px;
			z-index: 11;
			transition: left 0.5s linear;

			.search-close-icon svg {
				height: 9px;
				width: 9px;
				margin-top: 16.5px;
				margin-left: 12.5px;
			}

			.search-input {
				width: 240px;
				margin-bottom: 9px;
				margin-top: 11px;
				background-color: transparent;
				border: unset;

				.ant-input-search .ant-input:hover,
				.ant-input-search .ant-input:focus {
					border: unset;
				}

				.ant-input-group-addon {
					background: transparent;
					border: unset;
				}
				.ant-btn-primary {
					background: transparent;
					border: unset;
					margin-left: 8px;
					margin-top: -2px;
				}

				.ant-input-affix-wrapper,
				.ant-input-affix-wrapper:hover,
				.ant-input-affix-wrapper:focus {
					height: 22px;
					background-color: transparent;
					border: unset;
					border-bottom: 1px solid #666666;
					padding-left: 5px;
					margin-left: 13.5px;
					box-shadow: unset;
				}

				.ant-input {
					background-color: transparent;
					color: #8b8b8b;
				}

				button svg {
					height: 21px;
					width: 21px;
				}
			}
		}

		.visualization-search-box-hidden {
			position: absolute;
			left: -262px;
			top: 125px;
			width: 262px;
			height: 42px;
			background: rgba(244, 244, 244, 0.6);
			backdrop-filter: blur(10px);
			border-radius: 0px 10px 10px 0px;
			z-index: 11;
			transition: left 0.5s linear;

			.search-close-icon svg {
				height: 9px;
				width: 9px;
				margin-top: 16.5px;
				margin-left: 12.5px;
			}

			.search-input {
				width: 240px;
				margin-bottom: 9px;
				margin-top: 11px;
				background-color: transparent;
				border: unset;

				.ant-input-search .ant-input:hover,
				.ant-input-search .ant-input:focus {
					border: unset;
				}

				.ant-input-group-addon {
					background: transparent;
					border: unset;
				}
				.ant-btn-primary {
					background: transparent;
					border: unset;
					margin-left: 8px;
					margin-top: -2px;
				}

				.ant-input-affix-wrapper,
				.ant-input-affix-wrapper:hover,
				.ant-input-affix-wrapper:focus {
					height: 22px;
					background-color: transparent;
					border: unset;
					border-bottom: 1px solid #666666;
					padding-left: 5px;
					margin-left: 13.5px;
					box-shadow: unset;
				}

				.ant-input {
					background-color: transparent;
					color: #8b8b8b;
				}

				button svg {
					height: 21px;
					width: 21px;
				}
			}
		}

		.visualization-filter-btn {
			position: absolute;
			background: rgba(0, 0, 0, 0.6);
			border-radius: 0px 10px 10px 0px;
			width: 45px;
			height: 42px;
			z-index: 9;
			border: unset;
			color: white;
			top: 75px;
			left: 0;
			opacity: 1;
			transition-timing-function: linear;
			transition-duration: 2s, 0.1;
			transition-property: opacity, visibility;
			transition-delay: 0.4s, 0.4s;

			svg {
				margin-top: 8px;
				height: 14px;
				width: 16px;

				path {
					stroke: white;
				}
			}
		}
		.visualization-filter-btn-hidden {
			position: absolute;
			background: rgba(0, 0, 0, 0.6);
			border-radius: 0px 10px 10px 0px;
			width: 45px;
			height: 42px;
			z-index: 9;
			border: unset;
			color: white;
			top: 75px;
			left: 0;
			opacity: 0;
			visibility: hidden;
			transition-timing-function: linear;
			transition-duration: 0.5s, 0.1;
			transition-property: opacity, visibility;
			transition-delay: 0s, 0.2s;

			svg {
				margin-top: 8px;
				height: 14px;
				width: 16px;

				path {
					stroke: white;
				}
			}
		}

		.visualization-filter-box {
			position: absolute;
			left: 0;
			top: 75px;
			width: 227px;
			height: 330px;
			background: rgba(244, 244, 244, 0.6);
			backdrop-filter: blur(10px);
			border-radius: 0px 10px 10px 0px;
			z-index: 11;
			transition: left 0.5s linear;

			.filter-close-icon svg {
				height: 9px;
				width: 9px;
				margin-top: 16.5px;
				margin-left: 12.5px;
			}
		}

		.visualization-filter-box-hidden {
			position: absolute;
			left: -227px;
			top: 75px;
			width: 227px;
			height: 330px;
			background: rgba(244, 244, 244, 0.6);
			backdrop-filter: blur(10px);
			border-radius: 0px 10px 10px 0px;
			z-index: 11;
			transition: left 0.5s linear;

			.filter-close-icon svg {
				height: 9px;
				width: 9px;
				margin-top: 16.5px;
				margin-left: 12.5px;
			}
		}

		.visualization-filter-select {
			width: 196px;
			height: 32px;
			margin-left: 15px;
			margin-bottom: 15px;
			background: #f7f6f6;
			border: 1px solid #ddd8d2;
			border-radius: 10px;
			box-shadow: unset;
			font-size: 12px;

			.ant-select-selection-overflow {
				display: none;
			}

			.ant-select-selector {
				background: transparent;
				border: unset;
			}

			.ant-select-focused {
				box-shadow: unset;
			}

			::selection {
				background: transparent;
			}
		}

		.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
			border-color: transparent;
		}

		.visualization-filter-icon {
			margin-left: 15px;
			margin-top: 16px;
			margin-bottom: 15px;
			margin-right: 10px;
			stroke: #454444;
		}

		.visualization-filter-box-title {
			font-weight: 600;
			font-size: 16px;
			color: #454444;
			bottom: 17px;
			position: relative;
		}

		.filter-close-icon {
			top: -20px;
			right: -105px;
			position: relative;
		}

		.visualization-filter-footer {
			width: 135px;
			height: 19px;
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 700;
			font-size: 16px;
			line-height: 19px;
			border-bottom: 1px solid #454444;
			color: #454444;
			margin-left: 15px;
			cursor: pointer;

			svg {
				margin-right: 5px;
			}
		}

		.span-categorias {
			left: 30px;
			top: 59.5px;
		}

		.span-cores {
			left: 30px;
			top: 105.5px;
		}

		.span-visuais {
			left: 30px;
			top: 153.5px;
		}

		.span-formatos {
			left: 30px;
			top: 201.5px;
		}

		.span-colecoes {
			left: 30px;
			top: 248.5px;
		}

		.span-formas {
			left: 30px;
			top: 296.5px;
		}
	}
`

export const ProductsModal = styled(Modal)`
	width: 350px;
	height: 493px;

	.ant-modal-content {
		height: 100%;
	}

	.ant-modal-body {
		border-radius: 5px;
		padding: 50px 50px 30px 50px;
		display: flex;
		justify-content: center;
	}

	.visualization-product-modal-img {
		width: 250px;
		height: 250px;
		margin: 0;
		border-radius: 5px;
		margin-bottom: 20px;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.visualization-modal-row-content {
		justify-content: center;
	}

	.visualization-product-modal-name {
		font-weight: 500;
		font-size: 22px;
		color: #161e21;
		display: flex;
		line-height: 24px;
	}

	.visualization-product-modal-measures {
		font-weight: 400;
		font-size: 16px;
		color: #161e21;
		display: flex;
	}

	.visualization-product-modal-btn {
		color: white;
		width: 170px;
		height: 42px;
		font-weight: 600;
		background: #666666;
		border-radius: 10px;
		position: absolute;
		border: unset;
		bottom: 39px;
	}

	[ant-click-animating-without-extra-node='true']::after {
		display: none;
	}

	@media (min-width: 1000px) {
		width: 606px !important;
		height: 803px;

		.ant-modal-body {
			padding-top: 75px;
		}

		.visualization-product-modal-img {
			width: 440px;
			height: 440px;
		}
	}
`

export const ShareModal = styled(Modal)`
	display: flex;
	justify-content: center;
	align-items: center;

	.ant-modal-content {
		/* width: 350px; */
		width: 84vw;
		max-width: 350px;
	}

	.ant-modal-body {
		/* width: 350px;
		height: 493px; */
		border-radius: 5px;
		/* padding: 50px 50px 30px 50px; */
		padding: 81px 32px 67px;
	}

	.visualization-share-modal-title {
		font-weight: 500;
		font-size: 16px;
		text-align: center;
		display: flex;
		color: #29251f;
		margin-bottom: 24px;
	}

	.visualization-share-modal-btn {
		color: white;
		width: 170px;
		height: 42px;
		font-weight: 600;
		background: #666666;
		border-radius: 10px;
		margin-bottom: 47px;
		margin-left: 40px;
		border: unset;
	}

	.ant-input-group.ant-input-group-compact > *:last-child.visualization-share-send-btn {
		width: 67px;
		height: 42px;
		background: #666666;
		border-radius: 0px 10px 10px 0px;
		border: unset;
		color: white;
		padding: 0;
	}

	.ant-input-group.ant-input-group-compact > *:first-child.visualization-share-send-input:active,
	.ant-input-group.ant-input-group-compact > *:first-child.visualization-share-send-input:hover,
	.ant-input-group.ant-input-group-compact > *:first-child.visualization-share-send-input:focus {
		box-shadow: unset;
	}

	.ant-input-group.ant-input-group-compact > *:first-child.visualization-share-send-input {
		width: 184px;
		height: 42px;
		border: 1px solid #666666;
		border-radius: 10px 0px 0px 10px;

		svg {
			color: #cdcdcd;
		}

		::selection {
			background: none;
		}
	}

	.consent-wrap {
		.consent-checkbox {
			margin: 18px 0 9px 0;
		}
		.cta-privacy-policy {
			padding: 0 8px 0 22px;

			.ant-btn-default {
				color: #1890ff;
				margin: 0 !important;
				padding: 0 !important;
				border: none !important;
				height: auto;
				line-height: 1.5;
			}
		}
	}

	[ant-click-animating-without-extra-node='true']::after {
		display: none;
	}
`

export const ProductsTotemModal = styled(Modal)`
	.ant-modal-content {
		width: 650px;
		max-width: 650px;
	}

	.ant-modal-body {
		width: 650px;
		height: 850px;
		border-radius: 5px;
		padding: 100px 100px 80px 100px;
	}

	.visualization-product-modal-img {
		height: 450px;
		width: 450px;
		margin: 0;
		border-radius: 5px;
		margin-bottom: 20px;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.visualization-product-modal-name {
		font-weight: 500;
		font-size: 46px;
		color: #161e21;
		display: flex;
		line-height: 46px;
	}

	.visualization-product-modal-measures {
		font-weight: 400;
		font-size: 36px;
		color: #161e21;
		display: flex;
	}

	.visualization-product-modal-btn {
		color: white;
		width: 370px;
		height: 72px;
		font-weight: 600;
		background: #666666;
		border-radius: 10px;
		left: 40px;
		top: 50px;
		border: unset;
		font-size: 38px;
	}

	.ant-modal-close-x {
		svg {
			margin-top: 15px;
			margin-right: 15px;
			height: 32px;
			width: 32px;
		}
	}

	[ant-click-animating-without-extra-node='true']::after {
		display: none;
	}
`

export const TotemShareModal = styled(Modal)`
	.ant-modal-content {
		width: 650px;
		max-width: 650px;
	}

	.ant-modal-body {
		width: 650px;
		height: max-content;
		border-radius: 5px;
		padding: 50px 50px 30px 50px;
	}

	.visualization-share-modal-title {
		font-weight: 500;
		font-size: 16px;
		text-align: center;
		display: flex;
		color: #29251f;
		margin-bottom: 24px;
		text-transform: uppercase;
	}

	.visualization-share-modal-btn {
		color: white;
		width: 170px;
		height: 42px;
		font-weight: 600;
		background: #666666;
		border-radius: 10px;
		margin-bottom: 47px;
		margin-left: 40px;
		border: unset;
	}

	.ant-input-group.ant-input-group-compact > *:last-child.visualization-share-send-btn {
		width: 120px;
		height: 52px;
		background: #666666;
		border-radius: 0px 10px 10px 0px;
		border: unset;
		color: white;
		padding: 0;
		font-size: 24px;
	}

	.ant-input-group.ant-input-group-compact > *:first-child.visualization-share-send-input:active,
	.ant-input-group.ant-input-group-compact > *:first-child.visualization-share-send-input:hover,
	.ant-input-group.ant-input-group-compact > *:first-child.visualization-share-send-input:focus {
		box-shadow: unset;
	}

	.ant-input-group.ant-input-group-compact > *:first-child.visualization-share-send-input {
		width: 350px;
		height: 52px;
		border: 1px solid #666666;
		border-radius: 10px 0px 0px 10px;
		font-size: 24px;
		margin-left: 40px;

		svg {
			color: #cdcdcd;
		}

		::selection {
			background: none;
		}
	}

	.consent-wrap {
		font-size: 28px;
		.consent-checkbox {
			margin: 18px 0 9px 0;
			font-size: 28px;
			.ant-checkbox-inner,
			.ant-checkbox-input {
				width: 22px;
				height: 22px;
				font-size: 22px;
				border-radius: 1px solid #666666;
				[ant-click-animating-without-extra-node='true']::after {
					display: none;
				}
			}
			.ant-checkbox-checked .ant-checkbox-inner::after {
				background: transparent;
				border-radius: 1px solid #666666;
			}
		}
		.cta-privacy-policy {
			padding: 0 8px 0 26px;

			.ant-btn-default {
				color: #1890ff;
				font-size: 28px;
				margin: 0 !important;
				padding: 0 !important;
				border: none !important;
				height: auto;
				line-height: 1.5;
			}
		}
	}

	[ant-click-animating-without-extra-node='true']::after {
		display: none;
	}

	.visualization-share-modal-title {
		font-weight: 500;
		font-size: 26px;
		text-align: center;
		display: flex;
		color: #29251f;
		margin-bottom: 32px;
	}

	.visualization-share-modal-totem-obs {
		font-weight: 500;
		font-size: 22px;
		display: flex;
		color: #29251f;
		margin-bottom: 32px;
		margin-top: 28px;
		color: rgba(41, 37, 31, 0.6);
		text-align: center;

		svg {
			height: 22px;
			width: 22px;
			margin-right: 5px;
			margin-top: 4px;
		}
	}

	.visualization-share-modal-title-email {
		font-weight: 500;
		font-size: 26px;
		display: flex;
		justify-content: center;
		color: #29251f;
		margin: 4px 0 24px;
	}

	.ant-input {
		font-size: 26px;
	}

	.ant-modal-close-x svg {
		height: 24px;
		width: 24px;
		margin-top: 15px;
		margin-right: 10px;
	}
`

export const TotemDrawer = styled(Drawer)`
	margin-top: 152px;
	height: calc(100vh - 152px);
	overflow: hidden;
	.visualization-desktop-search {
		margin-top: 30px;
		margin-bottom: 30px;
		width: calc(22.5vw - 50px);
		margin-bottom: 9px;
		margin-top: 11px;
		background-color: transparent;
		border: unset;

		.ant-input-search .ant-input:hover,
		.ant-input-search .ant-input:focus {
			border: unset;
		}

		.ant-input-group-addon {
			background: transparent;
			border: unset;
		}
		.ant-btn-primary {
			background: transparent;
			border: unset;
			margin-left: 8px;
			margin-top: -2px;
		}

		.ant-input-affix-wrapper,
		.ant-input-affix-wrapper:hover,
		.ant-input-affix-wrapper:focus {
			height: 32px;
			background-color: transparent;
			border: unset;
			border-bottom: 1px solid #161e21;
			padding-left: 5px;
			margin-left: 13.5px;
			box-shadow: unset;
		}

		.ant-input {
			background-color: transparent;
			color: #161e21;
		}

		button svg {
			margin-top: 11px;
			height: 21px;
			width: 21px;
			color: #161e21;
		}
	}

	.drawer-header {
		.drawer-filter-icon svg {
			height: 32px;
			width: 32px;
			margin-top: 9px;

			path {
				stroke: #161e21;
			}
		}

		.drawer-title {
			font-weight: 500;
			font-size: 32px;
			color: #161e21;
		}

		.drawer-close-icon svg {
			height: 32px;
			width: 32px;
			margin-top: 8px;
		}
	}

	.visualization-totem-search {
		height: 52px;
		font-size: 24px;
		width: 480px;
		background-color: transparent;
		border: unset;
		margin-top: 40px;

		.ant-input-wrapper {
			border-bottom: 2px solid #161e21;
		}

		.ant-input-search .ant-input:hover,
		.ant-input-search .ant-input:focus {
			border: unset;
		}

		.ant-input-group-addon {
			background: transparent;
			border: unset;
		}
		.ant-btn-primary {
			background: transparent;
			border: unset;
			margin-left: 8px;
			margin-top: -2px;
		}

		.ant-input-affix-wrapper,
		.ant-input-affix-wrapper:hover,
		.ant-input-affix-wrapper:focus {
			height: 32px;
			background-color: transparent;
			border: unset;
			padding-left: 5px;
			margin-left: 13.5px;
			box-shadow: unset;
		}

		.ant-input {
			background-color: transparent;
			color: #161e21;
			font-size: 24px;
		}

		button svg {
			height: 24px;
			width: 24px;
			color: #161e21;
		}
	}

	.visualization-totem-surface-btn {
		color: #585c5c;
		border: unset;
		font-weight: 600;
		font-size: 32px;
		background: transparent;
		width: 22vw;
		height: 52px;
		padding-left: 5px;
		margin-bottom: 20px;
		border-radius: 0;

		span {
			display: flex;
		}
	}

	.visualization-totem-surface-btn[disabled] {
		color: black;
		border: unset;
		border-bottom: solid 3px black;
		background: transparent;
	}

	.visualization-filter-footer {
		width: 196px;
		height: 19px;
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 19px;
		border-bottom: 1px solid #a2a2a2;
		color: #a2a2a2;
		float: right;
		margin: 0px 15px 15px 0;
		cursor: pointer;

		svg {
			height: 20px;
			width: 20px;
			margin-right: 5px;
			margin-bottom: 2px;
		}
	}

	.visualization-filter-select {
		width: 230px;
		height: 56px;
		margin-bottom: 15px;
		margin-left: 10px;
		background: #f7f6f6;
		border: 1px solid #ddd8d2;
		border-radius: 10px;
		box-shadow: unset;
		font-size: 26px;
		line-height: 26px;

		.ant-select-selection-overflow {
			display: none;
		}

		.ant-select-selector {
			background: transparent;
			border: unset;
			height: 56px;
		}

		.ant-select-focused {
			box-shadow: unset;
		}

		::selection {
			background: transparent;
		}
	}

	.span-filter {
		left: 25px !important;
		top: 18px !important;
		font-size: 26px !important;
		width: 65% !important;
	}

	.horizontal-carousel-card {
		height: 174px;
		width: 114px;
		border-radius: 5px;
		padding-top: 7px;
		font-size: 12px;
	}

	.horizontal-carousel-card-selected {
		height: 174px;
		width: 114px;
		border-radius: 5px;
		padding-top: 7px;
		font-size: 12px;
		background-color: rgba(203, 203, 203, 0.2);
	}

	.horizontal-carousel-card-img {
		width: 90px;
		height: 90px;
		margin-top: 7px;
		margin-left: 12px;
		margin-bottom: 6px;
		background-repeat: no-repeat;
		background-size: cover;
		display: flex;
		justify-content: end;
		align-items: end;
	}

	.horizontal-carousel-text {
		margin-left: 12px;
		margin-right: 12px;
		color: #161e21;
		font-weight: 500;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.horizontal-carousel-measures {
		margin-left: 7px;
		margin-right: 7px;
		height: 32px;
		line-height: 32px;
		font-weight: 700;
		font-size: 10px;
		color: #555555;
		text-align: center;

		.ant-row {
			margin-left: 5px;
			margin-right: 5px;
		}
	}

	.horizontal-carousel-measures-bordered {
		border: 1px solid #c4c4c4;
		border-radius: 5px;
	}

	.horizontal-carousel-zoom {
		margin: 0 5px 5px 0;
		height: 32px;
		width: 32px;
		border-radius: 32px;
		background: rgba(0, 0, 0, 0.6);
		backdrop-filter: blur(10px);

		span {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: white;
		}
	}

	.totem-carousel-card {
		height: 250px;
		width: 160px;
		border-radius: 5px;
		padding-top: 7px;
		font-size: 12px;
	}

	.totem-carousel-card-selected {
		height: 250px;
		width: 160px;
		border-radius: 5px;
		padding-top: 7px;
		font-size: 12px;
		background-color: rgba(203, 203, 203, 0.2);
	}

	.totem-carousel-card-img {
		width: 140px;
		height: 140px;
		margin-top: 7px;
		margin-left: 12px;
		margin-bottom: 6px;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.totem-carousel-text {
		margin-left: 12px;
		margin-right: 12px;
		color: #161e21;
		font-weight: 500;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.totem-carousel-measures {
		margin-left: 7px;
		margin-right: 7px;
		height: 32px;
		line-height: 32px;
		font-weight: 700;
		font-size: 10px;
		color: #555555;
		text-align: center;

		.ant-row {
			margin-left: 5px;
			margin-right: 5px;
		}
	}

	.totem-carousel-measures-bordered {
		border: 1px solid #c4c4c4;
		border-radius: 5px;
	}

	.totem-carousel-zoom {
		position: relative;
		left: 85px;
		top: 85px;
		height: 48px;
		width: 48px;
		border-radius: 32px;
		background: rgba(0, 0, 0, 0.6);
		backdrop-filter: blur(10px);

		span {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: white;
		}

		svg {
			height: 24px;
			width: 24px;
		}
	}

	.visualization-totem-card-content {
		height: calc(100vh - 606px);
		overflow: overlay;
	}
`

export const EmptyMessageComponent = styled.div`
	padding: 45px 20px;

	.title {
		font-size: 24px;
		font-weight: 400;
		text-align: center;
	}

	button.ant-btn.ant-btn-default {
		border-radius: 10px;
		color: #fff;
		font-weight: 700;
		font-size: 16px;
		font-style: normal;
		height: 40px;
		padding: 0 20px;
		width: 100%;
		text-transform: uppercase;
		margin-bottom: 10px;

		&.ceusa {
			background-color: #53565a;
		}
		&.portinari {
			background-color: #666666;
		}
		&.durafloor {
			background-color: #edef8e;
			color: #3e4345;
			border: #edef8e;
		}
	}
`

export const LabelFilter = styled.div`
	position: absolute;
	z-index: 9;
	font-size: 14px;
	color: black;
	font-weight: 500;
	display: flex;
	justify-content: space-between;
	width: 145px;
	align-items: center;

	&::after {
		display: ${(props) => (props.counter > 0 ? 'flex' : 'none')};
		content: '${(props) => props.counter}';
		height: 20px;
		width: 20px;
		background: gray;
		border-radius: 100%;
		justify-content: center;
		align-items: center;
		color: #f7f6f6;
		font-size: 10px;
	}
`
