import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import {
	tourReducer,
	initialTourReducer,
	stepReducer,
	// roomReducer,
} from '../../store/reducers/sharedService.reducer'
import { setCookie, getCookie } from '../../utils/cookies.manager'
import Tour from './tour'
import TourPresentation from './TourPresentation'

function TourWrap(props) {
	const { redirect, setOnCompare, sku, company } = props
	const dispatch = useDispatch()
	const intl = useIntl()
	const {
		totem,
		tour,
		step,
		initialTour,
		settings,
		// rooms,
	} = useSelector((state) => state.sharedServiceReducer)
	const [tourSteps, setTourSteps] = useState([])
	const [currentPage] = useState(window.location.pathname)

	const tourStepsDefault = [
		{
			text: intl.formatMessage({ id: 'tour.desktopOne' }),
			reference: '.rooms-component-container',
			placement: 'top',
			textButton: intl.formatMessage({ id: 'tour.next' }),
			page: '/rooms',
			changePage: true,
		},
		{
			text: intl.formatMessage({ id: 'tour.desktopTwo' }),
			reference: '.visualization-vertical-carousel-container',
			placement: 'left',
			textButton: intl.formatMessage({ id: 'tour.next' }),
			page: '/visualization',
			changePage: false,
		},
		{
			text: intl.formatMessage({ id: 'tour.desktopThree' }),
			reference: '.visualization-clear-btn',
			placement: 'top',
			textButton: intl.formatMessage({ id: 'tour.next' }),
			page: '/visualization',
			changePage: false,
		},
		{
			text: intl.formatMessage({ id: 'tour.desktopFour' }),
			reference: '.visualization-compare-btn-left',
			placement: 'bottom',
			textButton: intl.formatMessage({ id: 'tour.next' }),
			page: '/visualization',
			changePage: false,
		},
		{
			text: intl.formatMessage({ id: 'tour.desktopFive' }),
			reference: '.selection-content-container',
			placement: 'left',
			textButton: intl.formatMessage({ id: 'tour.next' }),
			page: '/visualization',
			changePage: false,
		},
		{
			text: intl.formatMessage({ id: 'tour.desktopSix' }),
			reference: '.visualization-share-container',
			placement: 'left',
			textButton: intl.formatMessage({ id: 'tour.terminate' }),
			page: '/visualization',
			changePage: false,
		},
	]

	const tourStepsMob = [
		{
			text: intl.formatMessage({ id: 'tour.mobileOne' }),
			reference: '.rooms-component-container',
			placement: 'top',
			textButton: intl.formatMessage({ id: 'tour.next' }),
			page: '/rooms',
			changePage: true,
		},
		{
			text: intl.formatMessage(company !== 'durafloor' ? { id: 'tour.mobileTwo' } : { id: 'tour.mobileTwoDurafloor' }),
			reference: company === 'durafloor' ? '.visualization-carousel' : '.visualization-horizontal-carousel-btns',
			placement: 'top',
			textButton: intl.formatMessage({ id: 'tour.next' }),
			page: '/visualization',
			changePage: false,
		},
		{
			text: intl.formatMessage({ id: 'tour.mobileThree' }),
			reference: '.visualization-filter-btn',
			placement: 'right',
			textButton: intl.formatMessage({ id: 'tour.next' }),
			page: '/visualization',
			changePage: false,
		},
		{
			text: intl.formatMessage({ id: 'tour.mobileFour' }),
			reference: '.visualization-clear-btn',
			placement: 'top',
			textButton: intl.formatMessage({ id: 'tour.next' }),
			page: '/visualization',
			changePage: false,
		},
		{
			text: intl.formatMessage({ id: 'tour.mobileFive' }),
			reference: '.visualization-compare-btn-left',
			placement: 'bottom',
			textButton: intl.formatMessage({ id: 'tour.next' }),
			page: '/visualization',
			changePage: false,
		},
		{
			text: intl.formatMessage({ id: 'tour.mobileSix' }),
			reference: '.visualization-share-btn',
			placement: 'left',
			textButton: intl.formatMessage({ id: 'tour.terminate' }),
			page: '/visualization',
			changePage: false,
		},
	]

	const tourStepsTotem = [
		{
			text: intl.formatMessage({ id: 'tour.totemOne' }),
			reference: '.rooms-component-container-totem',
			placement: 'top',
			textButton: intl.formatMessage({ id: 'tour.next' }),
			page: '/rooms',
			changePage: false,
		},
		{
			text: intl.formatMessage({ id: 'tour.totemTwo' }),
			reference: '.rooms-qr-banner-text-container',
			placement: 'top',
			textButton: intl.formatMessage({ id: 'tour.next' }),
			page: '/rooms',
			changePage: true,
		},
		{
			text: intl.formatMessage(
				company !== 'durafloor' ? { id: 'tour.totemThree' } : { id: 'tour.totemThreeDurafloor' }
			),
			reference: '.visualization-menu-left-btn',
			placement: 'right',
			textButton: intl.formatMessage({ id: 'tour.next' }),
			page: '/visualization',
			changePage: false,
		},
		{
			text: intl.formatMessage({ id: 'tour.totemFour' }),
			// reference: '.visualization-totem-surface-btn',
			reference: '.visualization-totem-search',
			placement: 'right',
			textButton: intl.formatMessage({ id: 'tour.next' }),
			page: '/visualization',
			changePage: false,
		},
		{
			text: intl.formatMessage({ id: 'tour.totemFive' }),
			reference: '.visualization-rotate-btn-totem',
			placement: 'top',
			textButton: intl.formatMessage({ id: 'tour.next' }),
			page: '/visualization',
			changePage: false,
		},
		{
			text: intl.formatMessage({ id: 'tour.totemSix' }),
			reference: '.visualization-compare-btn-totem',
			placement: 'bottom',
			textButton: intl.formatMessage({ id: 'tour.next' }),
			page: '/visualization',
			changePage: false,
		},
		{
			text: intl.formatMessage({ id: 'tour.totemSeven' }),
			reference: '.visualization-share-btn-totem',
			placement: 'left',
			textButton: intl.formatMessage({ id: 'tour.terminate' }),
			page: '/visualization',
			changePage: false,
		},
	]

	const closeTour = () => {
		dispatch(tourReducer(false))
	}

	const nextTour = () => {
		if (step === 3 && totem === '1') {
			document.querySelector('.visualization-menu-left-btn').click()
			setTimeout(() => {
				const newStep = tourSteps && step + 1 > tourSteps.length ? 1 : step + 1
				dispatch(stepReducer(newStep))
			}, 500)
			return
		}

		if (step === 4 && totem === '1') {
			document.querySelector('.ant-drawer-mask').click()
		}

		if (step === 5 && totem === '1') {
			setOnCompare(true)
		}
		if (step === 6 && totem === '1') {
			setOnCompare(false)
		}

		if (step === 3 && settings.width > 999) {
			setOnCompare(true)
		}
		if (step === 4 && settings.width > 999) {
			setOnCompare(false)
		}

		if (step === 4 && settings.width < 999) {
			setOnCompare(true)
		}
		if (step === 5 && settings.width < 999) {
			setOnCompare(false)
		}

		if (step === tourSteps.length) closeTour()
		const newStep = tourSteps && step + 1 > tourSteps.length ? 1 : step + 1
		dispatch(stepReducer(newStep))

		if (tourSteps[step - 1].changePage && typeof redirect === 'function') redirect()
	}

	// useEffect(() => {
	// 	if (step === 2 && typeof(redirect) === 'function') redirect();
	// }, [step]);

	useEffect(() => {
		if (totem === '1') {
			setTourSteps(tourStepsTotem)
		} else if (settings && settings.width < 999) {
			setTourSteps(tourStepsMob)
		} else {
			setTourSteps(tourStepsDefault)
		}
	}, [setTourSteps])

	useEffect(() => {
		if (currentPage === '/rooms') {
			dispatch(stepReducer(1))
		}
		if (currentPage === '/visualization') {
			if (totem !== '1') dispatch(stepReducer(2))
			else dispatch(stepReducer(3))
		}

		const cookieTour = getCookie('checkTourStatus')
		if (cookieTour === '') {
			dispatch(initialTourReducer(true))
		}
		if (totem !== '1') {
			setCookie('checkTourStatus', 'true', 365, 'None')
		}
	}, [])

	return (
		<>
			{tour &&
				tourSteps &&
				tourSteps[step - 1] &&
				tourSteps[step - 1].page === currentPage &&
				tourSteps.map(
					(tourStep, index) =>
						step - 1 === index && (
							<Tour
								className={totem === '1' ? 'tour totem' : 'tour'}
								paginate={`${step} ${intl.formatMessage({ id: 'tour.of' })} ${tourSteps.length}`}
								id={`tour-item-${index}`}
								reference={tourStep.reference}
								popper={`#tour-item-${index}`}
								text={tourStep.text}
								textButton={tourStep.textButton}
								placement={tourStep.placement}
								width='20vw'
								key={`tour-item-${tourSteps.length - index}`}
								elementToClick={nextTour}
								sku={sku}
							/>
						)
				)}
			{initialTour && currentPage === '/rooms' && totem !== '1' && <TourPresentation sku={sku} />}
		</>
	)
}

export default TourWrap
