import React, { useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Button } from 'antd'
import { useIntl } from 'react-intl'
import {
	companyReducer,
	visitorIdReducer,
	roomsReducer,
	productListReducer,
} from '../../store/reducers/sharedService.reducer'
import { Container, HomeContainer } from './style'
import Halfpill from '../../components/Halfpill'
import LoadingComponent from '../../components/Loading'
import Intl from '../../utils/IntlMessages'

function Home() {
	const [scale, setScale] = useState()
	const { pdv, totem, companyList, lang, exclusiveCompany} = useSelector((state) => state.sharedServiceReducer)
	const dispatch = useDispatch()
	const { search } = window.location
	const params = new URLSearchParams(search)
	const companyParam = params.get('company')
	const company = companyList.includes(companyParam)
	const intl = useIntl()
	const validsExclusiveParam = ['portinari', 'ceusa', 'durafloor']

	const calcScale = () => {
		const height = window.innerHeight
		const width = window.innerWidth
		Math.min(height / 1920, width / 1080)
		setScale(width / height >= 0.5625 ? height / 1920 : width / 1080)
	}

	const toRoomsBrand = (data, sku) => {
		const dataLayer = {
			event: 'select_brand',
			eventModel: {
				brand: data,
				pdv,
				language: lang,
				device: totem === '1',
				source_product_site: sku !== null,
			},
		}
		TagManager.dataLayer({
			dataLayer,
		})
		dispatch(companyReducer(data))
		window.location.href = `/rooms${totem === '1' ? `?pdv=${pdv}` : ''}`
	}

	useEffect(() => {
		window.addEventListener('resize', calcScale, false)
		calcScale()
		dispatch(visitorIdReducer(null))
		dispatch(roomsReducer(null))
		dispatch(productListReducer(null))
	}, [])

	useEffect(() => {
		if (company && totem === '0') {
			toRoomsBrand(companyParam)
		}
	}, [totem, company])

	const homeRenderer = () =>
		totem === '1' ? (
			<Container scala={scale}>
				<div className='pageContent' style={{background: `url('/images/background-gif-no-halfpill.gif')`}}>
					<div className={`buttonContent ${validsExclusiveParam.find(validCompany => validCompany === exclusiveCompany) ? 'exclusive' : ''}`}>
						{(exclusiveCompany === null || exclusiveCompany === 'none' || exclusiveCompany === 'portinari') && <Halfpill
							className='btn-default'
							bgTheme='portinari'
							icon={<img src='/images/marca_porti.svg' style={{ height: '57px' }} alt='' />}
							effectsColor='on'
							onClick={() => toRoomsBrand('portinari')}
						/>}
						{(exclusiveCompany === null || exclusiveCompany === 'none' || exclusiveCompany === 'ceusa') && <Halfpill
							className='btn-default'
							bgTheme='ceusa'
							icon={<img src='/images/marca_ceusa.svg' style={{ height: '46px' }} alt='' />}
							onClick={() => toRoomsBrand('ceusa')}
						/>}
						{(exclusiveCompany === null || exclusiveCompany === 'none' || exclusiveCompany === 'durafloor') && <Halfpill
							className='btn-default'
							bgTheme='durafloor'
							icon={<img src='/images/marca_dura.svg' style={{ height: '58px' }} alt='' />}
							onClick={() => toRoomsBrand('durafloor')}
						/>}
					</div>
					{(exclusiveCompany === null || exclusiveCompany === 'none') && <video width='100%' height='100%' autoPlay loop muted>
						<source src={intl.formatMessage({ id: 'home.video' })} type='video/ogg' />
					</video>}
				</div>
			</Container>
		) : (
			<HomeContainer>
				<div className='header-simple' />
				<div className='content'>
					<div className='content-wrap'>
						<Row justify='center'>
							<Col>
								<img src={intl.formatMessage({ id: 'home.logo' })} alt='Logo Simular' className='logo-simular' />
							</Col>
						</Row>
						<Row justify='center'>
							<Col className='description-action'>
								<Intl id='home.title1' />{' '}
								<b>
									<Intl id='home.title2' />
								</b>{' '}
								<Intl id='home.title3' />
							</Col>
						</Row>
						<Row gutter={[16, 24]}>
							<Col span={24}>
								<Button className='btn-brand portinari-btn' onClick={() => toRoomsBrand('portinari')}>
									<img src='/images/marca_porti.svg' alt='Portinari' />
								</Button>
							</Col>
							<Col span={24}>
								<Button className='btn-brand ceusa-btn' onClick={() => toRoomsBrand('ceusa')}>
									<img src='/images/marca_ceusa.svg' alt='Ceusa' />
								</Button>
							</Col>
							<Col span={24} style={{ paddingBottom: '24px' }}>
								<Button className='btn-brand durafloor-btn' onClick={() => toRoomsBrand('durafloor')}>
									<img src='/images/marca_dura.svg' alt='Durafloor' />
								</Button>
							</Col>
						</Row>
					</div>
				</div>
			</HomeContainer>
		)

	return totem && !company ? homeRenderer() : <LoadingComponent />
}

export default Home
