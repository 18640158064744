import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import TagManager from 'react-gtm-module'
import { Row, Col, Button, Upload, message } from 'antd'
import QRCode from 'react-qr-code'
import { LoadingOutlined } from '@ant-design/icons'
import { io } from 'socket.io-client'
import { useIntl } from 'react-intl'
import { ReactComponent as SignOutAlt } from '../../assets/icons/sign-out-alt.svg'
import { ReactComponent as PlusSign } from '../../assets/icons/plus-sign.svg'
import RoomsContainer from './style'
import LoadingComponent from '../../components/Loading'
// import { companyInit } from '../../services/roomvo/index'
import {
	visitorIdReducer,
	roomsReducer,
	roomReducer,
	initReducer,
	/* vendorIdReducer, */ companyReducer,
	productListReducer,
	tourReducer,
	langReducer,
} from '../../store/reducers/sharedService.reducer'
import { getRooms, postRoom, /* init as companyInit, */ getInitV2, getMetaDataV2 } from '../../services/BFF/index'
import LogoBrand from '../../components/LogoBrand'
import { getRoomUpload, getTotemApi } from '../../env'
import TourWrap from '../../components/TourWrap'
import Intl from '../../utils/IntlMessages'
import { langIsValid } from '../../utils/paramValidation'

const { Dragger } = Upload

const socket = io.connect(getTotemApi())
const IOEventName = {
	MESSAGE: 'message',
	RESPONSE: 'response',
}

function Rooms() {
	const { totem, company, lang, rooms, pdv, visitor_id, companyList, tour, step } = useSelector(
		(state) => state.sharedServiceReducer
	)
	const dispatch = useDispatch()
	const [uploadLoading, setUploadLoading] = useState(false)
	const [scale, setScale] = useState()
	const { search } = window.location
	const params = new URLSearchParams(search)
	const companyParam = params.get('company')
	const langParam = params.get('lang')
	const sku = params.get('sku')
	const companyValid = companyList.includes(companyParam)
	const intl = useIntl()

	const socketHandler = async (visitorId) => {
		socket.on(IOEventName.RESPONSE, async (data) => {
			if (data && data.type === 'new-data') {
				if (data.data.visitorId === visitorId) {
					getRooms(lang, company, visitorId).then((dataRooms) => {
						dispatch(roomsReducer(null))
						dispatch(roomsReducer(dataRooms.results))
					})
				}
			}
		})
	}

	const calcScale = () => {
		const height = window.innerHeight
		const width = window.innerWidth
		setScale(width / height >= 0.5625 ? height / 1920 : width / 1080)
	}

	useEffect(() => {
		window.addEventListener('resize', calcScale, false)
		calcScale()
	}, [])

	useEffect(() => {
		if (company && totem === '0') {
			const dataLayer = {
				event: 'view_page',
				eventModel: {
					brand: company,
					device: totem === '1',
				},
			}
			TagManager.dataLayer({
				dataLayer,
			})
		}
		if (companyValid && totem === '0') {
			if (company && company !== companyParam) {
				dispatch(roomsReducer(null))
				dispatch(visitorIdReducer(null))
				dispatch(productListReducer(null))
			}
			dispatch(companyReducer(companyParam))
		}
		if (!company && !companyValid) {
			window.location.href = '/'
		}
	}, [companyParam])

	useEffect(() => {
		if (langParam && langIsValid(langParam.toLowerCase()) && totem === '0') {
			if (lang && lang !== langParam) {
				dispatch(roomsReducer(null))
				dispatch(visitorIdReducer(null))
				dispatch(productListReducer(null))
				dispatch(initReducer(null))
			}
			dispatch(langReducer(langParam))
		}
		if (!company && !companyValid) {
			window.location.href = '/'
		}
	}, [langParam])

	useEffect(() => {
		if (company && lang && totem) {
			if (visitor_id && rooms && ((companyValid && company === companyParam) || !companyValid)) {
				dispatch(roomsReducer(null))
				getRooms(lang, company, visitor_id).then((dataRooms) => {
					dispatch(roomsReducer(dataRooms.results))
				})
				socketHandler(visitor_id)
			}
			if (!visitor_id && ((companyValid && company === companyParam) || !companyValid)) {
				// companyInit(company, lang).then((data)=>{
				// 	dispatch(visitorIdReducer(data.visitor_id))
				// 	const vendorData = {}
				// 	vendorData[`${company}`] = data
				// 	dispatch(initReducer(vendorData))
				// 	// dispatch(vendorIdReducer(data.vendor_id))
				// 	// getRooms(lang, company, data.visitor_id).then((dataRooms)=>{
				// 	// 	dispatch(roomsReducer(dataRooms.results))
				// 	// })
				// 	// socketHandler(data.visitor_id)
				// })

				getInitV2(company).then((data) => {
					dispatch(visitorIdReducer(data.visitor))

					getMetaDataV2(company, lang, '1,5').then((dataMetas) => {
						const obj = {
							[`${company}`]: {
								product_metadatas: [
									{ ...dataMetas[0].metadataProto },
									company !== 'durafloor' && { ...dataMetas[1].metadataProto },
								],
							},
						}
						dispatch(initReducer(obj))
					})

					getRooms(lang, company, data.visitor).then((dataRooms) => {
						dispatch(roomsReducer(dataRooms.results))
					})
					socketHandler(data.visitor)
				})
			}
		}
	}, [company, lang, totem])

	const eventExit = () => {
		const dataLayer = {
			event: 'select_exit',
			eventModel: {
				brand: company,
				device: totem === '1',
				pdv,
				language: lang,
				source_product_site: sku !== null,
			},
		}
		TagManager.dataLayer({
			dataLayer,
		})
	}

	const toVisualization = (room) => {
		const dataLayer = {
			event: 'select_room',
			eventModel: {
				brand: company,
				device: totem === '1',
				pdv,
				language: lang,
				// room_name: room?.name || room?.name !== '' ? room?.name : 'meu ambiente',
				room_name: room.name ? room.name : 'Seu Ambiente',
				room_code: room?.id ? room?.id : false,
				tour,
				step: step && false,
				source_product_site: sku !== null,
			},
		}
		TagManager.dataLayer({
			dataLayer,
		})

		dispatch(roomReducer(room))

		const queryParams = new URLSearchParams(`?room=${room.id}${totem === '1' ? `&pdv=${pdv}` : ''}`)

		window.location.href = `/visualization?${queryParams}${sku ? `&sku=${sku}` : ''}`
	}

	const handleSubmit = async ({ onSuccess, onError, file }) => {
		setUploadLoading(true)
		try {
			const result = await postRoom(file, file.name, visitor_id, company)
			if (result) {
				dispatch(roomsReducer([...rooms, result]))
				toVisualization(result)
				onSuccess('OK')
			}
			if (!result) {
				setUploadLoading(false)
				onError('error')
				return false
			}
			return true
		} catch (e) {
			setUploadLoading(false)
			onError({ error: e })
			message.error(intl.formatMessage({ id: 'rooms.pictureError' }))
			return false
		}
	}

	const getQrCode = () => {
		const qrCodeStr = `
			${getRoomUpload()}?id=${visitor_id}&
			pdv=${pdv}&
			lang=${lang}&
			company=${company}
			`.replace(/(\r\n|\n|\r|\s)/gm, '')
		// if (qrCodeStr) console.log('qrCodeStr ', qrCodeStr)
		return qrCodeStr
	}

	const openTour = () => {
		const dataLayer = {
			event: 'next_tour',
			eventModel: {
				step_next: 0,
				tour: true,
				brand: company,
				pdv,
				language: lang,
				device: totem === '1',
				source_product_site: sku !== null,
			},
		}
		TagManager.dataLayer({
			dataLayer,
		})
		dispatch(tourReducer(true))
	}

	const uploadRoomCard = (notSampleFiltered) => {
		if (totem === '0') {
			return (
				<div className='room-upload'>
					<Dragger
						customRequest={handleSubmit}
						showUploadList={false}
						multiple={false}
						accept='image/jpeg, image/jpg, image/png, image/webp'
						beforeUpload={(file) => {
							const acceptedList = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp']
							if (file.size / 1024 / 1024 > 10) {
								return false
							}

							if (!acceptedList.includes(file.type)) {
								message.error(intl.formatMessage({ id: 'rooms.pictureErrorFormat' }))
								return false
							}

							return true
						}}
					>
						{uploadLoading ? (
							<span className='room-upload-load'>
								<LoadingOutlined />
							</span>
						) : (
							<PlusSign />
						)}
						<span className='room-upload-span'>
							<Intl id='rooms.myRoom' />
						</span>
					</Dragger>
				</div>
			)
		}
		return (
			<div
				className='room-upload-totem'
				onClick={() => (notSampleFiltered.length ? toVisualization(notSampleFiltered[0]) : {})}
				onKeyDown={() => {}}
				role='button'
				tabIndex={0}
				// style={{ backgroundImage: `${notSampleFiltered.length?`url(https://www.roomvo.com${notSampleFiltered[0].thumbnailUrl})`:'url(/images/noimage.png)'}`}}>
				style={{
					backgroundImage: `${
						notSampleFiltered.length
							? `url(https://www.roomvo.com${notSampleFiltered[0].thumbnail_url})`
							: 'url(/images/noimage.png)'
					}`,
				}}
			>
				<span className='room-upload-span'>
					<Intl id='rooms.changeEnv' />
				</span>
			</div>
		)
	}

	const roomsCompoment = () => {
		const sampleFiltered = rooms.filter((room) => room.isSample)
		const notSampleFiltered = rooms.filter((room) => !room.isSample)

		let result = []
		if (totem === '0')
			result = notSampleFiltered.length
				? [].concat([{ name: 'upload' }], notSampleFiltered, sampleFiltered)
				: [{ name: 'upload' }].concat(rooms)
		if (totem === '1')
			result = notSampleFiltered.length
				? [].concat(notSampleFiltered, sampleFiltered)
				: [{ name: 'upload' }].concat(rooms)

		const res = []
		for (let index = 0; index < result.length; index += 2) {
			res.push(
				<Row key={index}>
					<Col>
						{index === 0 ? (
							uploadRoomCard(notSampleFiltered)
						) : (
							<div
								className={`room-selection${totem === '1' ? '-totem' : ''}`}
								// style={{ backgroundImage: `url(https://www.roomvo.com${result[index].thumbnailUrl})`}}
								style={{ backgroundImage: `url(https://www.roomvo.com${result[index].thumbnail_url})` }}
								onClick={() => toVisualization(result[index])}
								onKeyDown={() => {}}
								role='button'
								tabIndex={index}
							>
								<span>{result[index].name ? result[index].name : <Intl id='rooms.myRoom' />}</span>
							</div>
						)}
					</Col>
					{index + 1 !== result.length && (
						<Col>
							<div
								className={`room-selection${totem === '1' ? '-totem' : ''} right-box`}
								// style={{ backgroundImage: `url(https://www.roomvo.com${result[index+1].thumbnailUrl})`}}
								style={{ backgroundImage: `url(https://www.roomvo.com${result[index + 1].thumbnail_url})` }}
								onClick={() => toVisualization(result[index + 1])}
								onKeyDown={() => {}}
								role='button'
								tabIndex={index}
							>
								<span>{result[index + 1].name ? result[index + 1].name : <Intl id='rooms.myRoom' />}</span>
							</div>
						</Col>
					)}
				</Row>
			)
		}
		return res
	}

	return totem && company && rooms ? (
		<RoomsContainer totem={totem === '1'} scala={scale}>
			{totem === '0' && <Row justify='end' className={`${company}-header header`} />}
			{totem === '1' && (
				<Row justify='end'>
					<Col>
						<Button
							className='rooms-exit-button'
							onClick={() => {
								eventExit()
								window.location.href = `/?&totem=1&pdv=${pdv}`
							}}
						>
							<Intl id='rooms.exit' /> <SignOutAlt />
						</Button>
					</Col>
				</Row>
			)}
			<Row justify='center'>
				<Col>
					<img
						className={`logo-simular${totem === '1' ? '-totem' : ''}`}
						src={intl.formatMessage({ id: 'rooms.logo' })}
						alt='Logo Simular'
					/>
				</Col>
			</Row>
			{totem === '1' && (
				<Row justify='center'>
					<Col>
						<div className={`${company}-rooms-brand-bullet rooms-brand-bullet`}>
							<LogoBrand brand={company} />
						</div>
					</Col>
				</Row>
			)}
			<Row justify='center'>
				<Col>
					<h2>
						<Intl id='rooms.title1' />
						<b>
							<Intl id='rooms.title2' />
						</b>
						<Intl id='rooms.title3' />
					</h2>
				</Col>
			</Row>
			{totem === '1' && (
				<Row justify='start' className='rooms-qr-banner'>
					<Col className='rooms-qr-banner-code'>
						<QRCode qr={getQrCode()} value={getQrCode()} size={200} />
					</Col>
					<Col className='rooms-qr-banner-text-container'>
						<Row>
							<Col>
								<span className='rooms-qr-banner-title'>
									<Intl id='rooms.qrTitle' />
								</span>
							</Col>
						</Row>
						<Row>
							<Col>
								<span className='rooms-qr-banner-sub'>
									<Intl id='rooms.qrSubtitle' />
								</span>
							</Col>
						</Row>
					</Col>
				</Row>
			)}
			<Row justify='center' className={`rooms-component-container${totem === '1' ? '-totem' : ''}`}>
				<Col>{roomsCompoment()}</Col>
			</Row>
			<Row justify='center'>
				<Col>
					<span className='tour-span'>
						<Intl id='rooms.tourSpan' />
					</span>
					<Button className={`${company}-tour-btn tour-btn`} onClick={openTour}>
						<Intl id='rooms.tourBtn' />
					</Button>
				</Col>
			</Row>
			<TourWrap redirect={() => toVisualization(rooms[0])} sku={sku} />
		</RoomsContainer>
	) : (
		<LoadingComponent />
	)
}

export default Rooms
