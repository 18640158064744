import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import TagManager from 'react-gtm-module'
import { createPopper } from '@popperjs/core'
import { usePopper } from 'react-popper'
import { Button } from 'antd'
import { stepReducer, tourReducer } from '../../store/reducers/sharedService.reducer'
import { PopperContainer, HeaderPopper, HeaderPopperClose, PopperClose } from './style'
import { ReactComponent as CloseButton } from '../../assets/icons/svg-X.svg'
import Intl from '../../utils/IntlMessages'

function Tour(props) {
	const {
		id,
		reference,
		popper,
		text,
		textButton,
		placement,
		// width,
		// next,
		elementToClick,
		className,
		paginate,
		sku,
	} = props

	const [arrowRef, setArrowRef] = useState(null)
	const [closeTour, setCloseTour] = useState(false)
	const dispatch = useDispatch()
	const { step, tour, room, totem, company, lang, pdv } = useSelector((state) => state.sharedServiceReducer)

	const nextTour = () => {
		if ((totem === '1' && step === 7) || (totem === '0' && step === 6)) {
			const dataLayer = {
				event: 'finish_tour',
				eventModel: {
					brand: company,
					device: totem === '1',
					pdv,
					language: lang,
					room_code: room?.id ? room?.id : 'false',
					room_name: room?.name || room?.name !== '' ? room?.name : 'meu ambiente',
					source_product_site: sku !== null,
				},
			}
			TagManager.dataLayer({
				dataLayer,
			})
		}
		if ((totem === '1' && step !== 7) || (totem === '0' && step !== 6)) {
			const dataLayer = {
				event: 'next_tour',
				eventModel: {
					step_next: step,
					tour,
					brand: company,
					pdv,
					language: lang,
					device: totem === '1',
					source_product_site: sku !== null,
				},
			}
			TagManager.dataLayer({
				dataLayer,
			})
		}
		elementToClick()
	}

	const onCloseTour = () => {
		const dataLayer = {
			event: 'next_tour',
			eventModel: {
				step_next: 99,
				tour,
				room_code: room?.id ? room?.id : false,
				room_name: room?.name || room?.name !== '' ? room?.name : 'meu ambiente',
				brand: company,
				device: totem === '1',
				pdv,
				language: lang,
				source_product_site: sku !== null,
			},
		}
		TagManager.dataLayer({
			dataLayer,
		})
		setCloseTour(true)
	}

	const onContinueTour = () => {
		const dataLayer = {
			event: 'continuation_tour',
			eventModel: {
				step: step && false,
				device: totem === '1',
				pdv,
				language: lang,
				room_code: room?.id ? room?.id : false,
				room_name: room?.name || room?.name !== '' ? room?.name : 'meu ambiente',
				brand: company,
				source_product_site: sku !== null,
			},
		}
		TagManager.dataLayer({
			dataLayer,
		})
		setCloseTour(false)
	}

	const onFinishTour = () => {
		const dataLayer = {
			event: 'exit_tour',
			eventModel: {
				step,
				room_name: room?.name || room?.name !== '' ? room?.name : 'meu ambiente',
				room_code: room?.id ? room?.id : 'false',
				brand: company,
				device: totem === '1',
				pdv,
				language: lang,
				source_product_site: sku !== null,
			},
		}
		TagManager.dataLayer({
			dataLayer,
		})

		dispatch(tourReducer(false))
		dispatch(stepReducer(1))
	}

	const onCreatePopper = async () => {
		const ref = await document.querySelector(reference)
		const pop = await document.querySelector(popper)

		await createPopper(ref, pop, {
			placement,
			strategy: 'absolute',
			modifiers: [
				{
					name: 'offset',
					options: {
						offset: [0, 32],
					},
				},
			],
		})
	}

	const { styles } = usePopper(reference.current, popper.current, {
		modifiers: [
			{
				name: 'arrow',
				options: {
					element: arrowRef,
				},
			},
			{
				name: 'offset',
				options: {
					offset: [50, 0],
				},
			},
		],
	})

	useEffect(() => {
		onCreatePopper()
	}, [])

	useEffect(() => {
		if (step === 1) {
			stepReducer(step)
		}
	}, [step])

	return (
		<>
			<PopperContainer
				className={className}
				id={id}
				// style={{ width: `${props.width}` }}
				// style={{ width: '100%', maxWidth: '300px' }}
				placement={placement}
				reference={reference}
			>
				<div ref={setArrowRef} style={styles.arrow} id='arrow' />
				<HeaderPopper>
					<p>{paginate}</p>
					<span role='button' tabIndex={0} onKeyPress={onCloseTour} className='close-button-tour' onClick={onCloseTour}>
						<CloseButton />
					</span>
				</HeaderPopper>
				{/* eslint-disable-next-line */}
				<div className='text-primary' dangerouslySetInnerHTML={{ __html: text }} />
				<div className='container-button'>
					<Button className='button-close-tour' onClick={onCloseTour}>
						<i>x </i>
						<Intl id='tour.close' />
					</Button>
					<Button className='button-next' onClick={nextTour}>
						{textButton}
					</Button>
				</div>
			</PopperContainer>

			{closeTour ? (
				<PopperClose className='popper-close popper-close-mobile'>
					<HeaderPopperClose>
						<span role='button' tabIndex={0} onKeyPress={onContinueTour} className='close-x' onClick={onContinueTour}>
							<CloseButton />
						</span>
					</HeaderPopperClose>
					<h2>
						<Intl id='tour.warning' />
					</h2>
					<div className='text-primary-close'>
						<Intl id='tour.warningText1' />
						<br />
						<Intl id='tour.warningText2' />
					</div>
					<div className='container-button'>
						<Button className='button-close-tour' onClick={onContinueTour}>
							<Intl id='tour.continue' />
						</Button>
						<Button className='button-next' onClick={onFinishTour}>
							<Intl id='tour.end' />
						</Button>
					</div>
				</PopperClose>
			) : null}
		</>
	)
}

export default Tour
