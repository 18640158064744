import styled from 'styled-components'

const Container = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 999;
	/* background: rgba(0,0,0,0.3); */
	background: rgba(255, 255, 255, 0.3);
	padding: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow-y: auto;
	.welcome-tour {
		max-width: 750px;
		width: 100%;
		height: auto;
		max-height: calc(100vh - 32px);
		box-shadow: 0px 14px 40px 0px #00000026;
		@media (max-width: 999px) {
			max-width: 320px;
		}
	}

	.card {
		border-radius: 5px;
	}

	.ant-card-cover img {
		border-radius: 5px 5px 0 0;
	}

	.icon-x-close {
		position: absolute;
		top: 15px;
		right: 15px;
		border: 0;
		padding: 0;
		background-color: transparent;
		svg {
			fill: black !important;
			width: 30px;
			cursor: pointer;
		}
	}

	.title {
		font-weight: 600;
		font-size: 28px;
		color: #000000;
		text-align: center;
		margin: 24px 0 16px;
		@media (max-width: 999px) {
			font-size: 16px;
		}
		b {
			font-weight: 900;
		}
	}

	.description {
		font-size: 20px;
		font-weight: 400;
		color: #000000;
		margin: 16px 0 50px;
		@media (max-width: 999px) {
			font-size: 12px;
			margin: 16px 0 24px;
		}
	}

	.container-button-welcome-tour {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		width: 100%;
	}

	.button-close-welcome-tour {
		width: 160px;
		height: 40px;
		border: 1px solid #666666;
		background-color: #fff;
		color: #666666;
		&:hover {
			background-color: #666666;
			color: #fff;
		}
	}
	.button-next {
		width: 180px;
		height: 40px;
		border: 1px solid #666666;
		background-color: #666666;
		color: #fff;
		&:hover {
			background-color: #cdcdcd;
		}
	}
	.button-close-welcome-tour,
	.button-next {
		border-radius: 10px;
		margin: 5px;
		font-weight: 700;
		font-size: 12px;
		text-transform: uppercase;
		span:nth-child(2) {
			display: none;
		}
		@media (max-width: 999px) {
			width: 120px;
			height: 36px;
			span:nth-child(1) {
				display: none;
			}
			span:nth-child(2) {
				display: inline-block;
			}
		}
	}

	.totem {
		.welcome-tour {
			max-width: 800px;
		}
		.icon-x-close {
			svg {
				width: 30px;
				height: 30px;
			}
		}
		.title {
			font-size: 36px;
		}
		.description {
			font-size: 26px;
		}
		.button-close-welcome-tour {
			width: 290px;
			height: 70px;
			margin: 5px;
			font-size: 20px;
		}

		.button-next {
			width: 320px;
			height: 70px;
			margin: 5px;
			font-size: 20px;
		}
	}
`

export default Container
