import React, { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { IntlProvider } from 'react-intl'
import { ConfigProvider } from 'antd'
import TagManager from 'react-gtm-module'
import {
	settingsReducer,
	companyReducer,
	exclusiveCompanyReducer,
	mouseListenerReducer,
	pdvReducer,
	totemReducer,
	langReducer
} from './store/reducers/sharedService.reducer'
import './styles/global.style.css'
import Home from './pages/Home'
import Rooms from './pages/Rooms'
import RoomUpload from './pages/RoomUpload'
import Visualization from './pages/Visualization'
import NotFound from './pages/NotFound'
import Download from './pages/Download'
import mouseManager from './utils/mouse.manager'
import 'antd/dist/antd.css'
import AppLocale from './lngProvider';
import {getGtmId, getSecuritiStagingMode} from './env/index'
import { langIsValid } from './utils/paramValidation'

const gtmId = getGtmId()
const tagManagerArgs = {gtmId}
TagManager.initialize(tagManagerArgs)

function App() {
	const { pathname, search } = window.location
	const brandsArray = ['portinari', 'ceusa', 'durafloor', 'none']
	const minutes = 25
	const time = 1000 * 60 * minutes
	const dispatch = useDispatch()
	const {
		companyList,
		mouseListener,
		lang,
		exclusiveCompany: exclusiveCompanyState,
		totem: totemState
	} = useSelector((state) => state.sharedServiceReducer)

	const params = new URLSearchParams(search)
	const companyParam = params.get('company')
	const exclusiveCompany = params.get('exclusive')
	const company = companyList.includes(companyParam)
	const pdv = params.get('pdv')?params.get('pdv'):'00'
	const langParam = params.get('lang')

	let totem = params.get('totem')?params.get('totem'):'0'
	totem = params.get('pdv')?'1':totem

	const [showLGPD, setShowLGPD] = useState(true)

	const handleWatchMouse = () => {
		/*
		dispatch(companyReducer(exclusiveCompanyState ? exclusiveCompanyState : 'none'))
		*/
		if (exclusiveCompanyState) dispatch(companyReducer(exclusiveCompanyState))
		else dispatch(companyReducer('none'))
		setTimeout(() => {
			window.location.href = `/?totem=1&pdv=${pdv}`
		}, 200)
	}

	const handleMouseListenerReducer = (instance) =>
		dispatch(mouseListenerReducer(instance))

	const currentAppLocale = AppLocale[lang];

	useEffect(() => {
		if (lang) {
			document.documentElement.lang = lang;
		}
	}, [lang]);

	useEffect(() => {
		if (totem ==='1') {
			const dataLayer = {
				event: "view_page",
				eventModel:{
					brand: company,
					device: totem === '1'
				}
			}
			TagManager.dataLayer({
				dataLayer
			})
		}
		
		window.addEventListener('resize', () => {
			dispatch(
				settingsReducer({
					width: window.innerWidth,
					height: window.innerHeight,
				})
			)
		})

		if(langIsValid(langParam?.toLowerCase()) && lang !== langParam) dispatch(langReducer(langParam.toLowerCase()))

		if (pathname === '/') {
			dispatch(totemReducer(totem))
			if (totem==='1') dispatch(pdvReducer(pdv))
			if (totem==='0' && company) dispatch(companyReducer(companyParam))
			if(exclusiveCompany && brandsArray.includes(exclusiveCompany)) dispatch(exclusiveCompanyReducer(exclusiveCompany))
		}

		if (pathname === '/rooms') {
			if (totem !== '1') {
				dispatch(totemReducer('0'))
				if (company) dispatch(companyReducer(companyParam))
			}
		}

		if (pathname.includes('/share') && totemState === '1') setTimeout(() => dispatch(totemReducer('0')), 500);

	}, [])

	useEffect(() => {
		if (totemState === '1') {
			mouseManager.create(handleWatchMouse, time, handleMouseListenerReducer)
			setTimeout(document.onmousemove, 200)
		} else dispatch(mouseListenerReducer(mouseManager.clear(mouseListener)))
	}, [totemState])

	return (
		<div className="App">
		<ConfigProvider
			locale={currentAppLocale.antd}
		>
			<IntlProvider
				locale={currentAppLocale.locale}
				messages={currentAppLocale.messages}
			>
				<BrowserRouter>
					<Routes>
						<Route path="/" exact element={<Home />} />
						<Route path="/rooms" exact element={<Rooms />} />
						<Route exact path="/visualization" element={<Visualization />} />
						<Route exact path="/share" element={<RoomUpload />} />
						<Route exact path="/download" element={<Download/>} />
						<Route path='*' element={<NotFound />} />
					</Routes>
				</BrowserRouter>
			</IntlProvider>	
		</ConfigProvider>

		{/* eslint-disable-next-line */}
		{showLGPD && <div onClick={setShowLGPD(false)}>
			{totem === '0' && <script>
			{/* <!-- After adding this script, call setConsentBannerParams({uuid: <unique id> }) to set unique ID for a customer. --> */}
				{/* eslint-disable-next-line */}
				{(function () {
					/* eslint-disable-next-line */
					var s = document.createElement('script');
					s.src = 'https://cdn-prod.securiti.ai/consent/cookie-consent-sdk-loader.js';
					s.setAttribute('data-tenant-uuid', 'efd715f3-2309-47fb-bf4e-14551ef0a9ab');
					s.setAttribute('data-domain-uuid', 'a2b61833-3af7-4cbb-bbb5-18112be86bc9');
					s.setAttribute('data-backend-url', 'https://app.securiti.ai');
					s.setAttribute('data-skip-css', 'false');
					s.defer = true;
					// eslint-disable-next-line no-unused-expressions
					getSecuritiStagingMode() === 'true' && s.setAttribute('data-securiti-staging-mode', 'true');
					/* eslint-disable-next-line */
					var parent_node = document.head || document.body;
					parent_node.appendChild(s);
				})()}
			</script>}
		</div>}
		</div>
	)
}

export default App
