import styled from 'styled-components'
import { Modal } from 'antd'

export const PrivacyPolicyModalContainer = styled(Modal)`
  background-color: #fff;

  .privacy-policy-modal {
    width: 100%;
    height: auto;

		.ant-modal-body {
			border-radius: 5px;
			padding: 50px 50px 30px 50px;
			display: flex;
			justify-content: center;
    	width: 100%;
		}

		.title-modal {
			margin-top: 0 !important;
		}

		h3 {
			margin: 30px 0 12px;
			font-size: 24px;
		}

		p, ul {
			font-size: 16px;
		}

		ul {
			padding: 0 0 0 40px;
			margin: 0 0 18px 0;
		}

		table {
			font-size: 16px;
			text-align: center;
			th {
				font-weight: bold;
			}
			th, td {
				padding: 5px;
				border: 1px solid #000000d9;
			}
		}

		.box {
			margin: 20px 0;
      padding: 10px 20px;
      background-color: #e2e2e1;
			color: #000000d9;
			h4 {
				font-size: 22px;
				margin: 20px 0 11px;
			}
			p {
				font-size: 16px;
			}
		}
  }

  img {
    width: 100%;
    max-width: 750px;
    max-height: 139px;
  }

	[ant-click-animating-without-extra-node='true']::after{
		display: none;
	}
`

export default PrivacyPolicyModalContainer