export const environment = {
	// production: false,
	// SOCKET_ENDPOINT: 'https://simular-totem-api.dev.dtxlabs.com.br',
	hotjar: '1870656',
	// Upload: 'https://totem.dex.co/room-upload/',
	// devEnv: 'https://simular-totem-api.dev.dtxlabs.com.br',
};

export const getBff = () => process.env.REACT_APP_BFF_API;

export const getTotemApi = () => process.env.REACT_APP_TOTEM_API;

export const getEnvDNS = () => process.env.REACT_APP_ENV;

export const getRoomUpload = () => process.env.REACT_APP_ROOM_UPLOAD;

export const getGtmId = () => process.env.REACT_APP_GTM_ID;

export const getCompanyUrl = (company) =>{
	switch (company) {
		case 'portinari':
			
			return process.env.REACT_APP_PORTINARI_URL

		case 'ceusa':
		
			return process.env.REACT_APP_CEUSA_URL
		
		case 'durafloor':
	
			return process.env.REACT_APP_DURAFLOOR_URL
		default:

			return null
	}
}

export const getSecuritiStagingMode = () => process.env.REACT_PUBLIC_SECURITI_STAGING_MODE;
