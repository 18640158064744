import { createSlice } from '@reduxjs/toolkit';

export const sharedService = createSlice({
	name: 'sharedService',
	initialState: {
		// roomvoService
		settings: { width: window.innerWidth, height: window.innerHeight },
		compareEnv: false,
		vendor_data: '',
		lang: 'pt',
		pdv: '',
		exclusiveCompany: null,
		company: null,
		companyOld: null,
		rooms: null,
		qrCodeRoom: '',
		visitor_id: null,
		vendor_id: null,
		room: null,
		product: null,
		productCompare: null,
		productList: null,
		floorSelected: {},
		wallSelected: {},
		floorSelectedCompare: {},
		wallSelectedCompare: {},
		device: false,
		imgDataInitial: '',
		floorInitial: '',
		initialTour: false,
		tour: false,
		step: 1,
		loading: false,
		loadingDark: false,
		mouseListener: false,
		closeAllFilters: false,
		productZoom: null,
		companyList: ['ceusa', 'portinari', 'durafloor'],
		totem: null,
	},
	reducers: {
		settingsReducer: (state, action) => ({
			...state,
			settings: action.payload,
		}),
		compareEnvReducer: (state, action) => ({
			...state,
			compareEnv: action.payload,
		}),
		initReducer: (state, action) => ({ ...state, vendor_data: action.payload }),
		companyReducer: (state, action) => ({ ...state, company: action.payload }),
		exclusiveCompanyReducer: (state, action) => ({ ...state, exclusiveCompany: action.payload }),
		companyOldReducer: (state, action) => ({
			...state,
			companyOld: action.payload,
		}),
		pdvReducer: (state, action) => ({ ...state, pdv: action.payload }),
		langReducer: (state, action) => ({ ...state, lang: action.payload }),
		roomsReducer: (state, action) => ({ ...state, rooms: action.payload }),
		initialTourReducer: (state, action) => {
			state.initialTour = action.payload;
		},
		tourReducer: (state, action) => {
			state.tour = action.payload;
		},
		stepReducer: (state, action) => {
			state.step = action.payload;
		},
		qrCodeRoomReducer: (state, action) => ({
			...state,
			qrCodeRoom: action.payload,
		}),
		visitorIdReducer: (state, action) => ({
			...state,
			visitor_id: action.payload,
		}),
		vendorIdReducer: (state, action) => ({
			...state,
			vendor_id: action.payload,
		}),
		roomReducer: (state, action) => ({ ...state, room: action.payload }),
		productReducer: (state, action) => ({ ...state, product: action.payload }),
		productZoomReducer: (state, action) => ({
			...state,
			productZoom: action.payload,
		}),
		productCompareReducer: (state, action) => ({
			...state,
			productCompare: action.payload,
		}),
		productListReducer: (state, action) => {
			state.productList = action.payload;
		},
		floorSelectedReducer: (state, action) => {
			state.floorSelected = action.payload;
		},
		wallSelectedReducer: (state, action) => {
			state.wallSelected = action.payload;
		},
		floorSelectedCompareReducer: (state, action) => {
			state.floorSelectedCompare = action.payload;
		},
		wallSelectedCompareReducer: (state, action) => {
			state.wallSelectedCompare = action.payload;
		},
		deviceReducer: (state, action) => ({
			...state,
			device: action.payload,
		}),
		imgDataInitialReducer: (state, action) => {
			state.imgDataInitial = action.payload;
		},
		floorInitialReducer: (state, action) => {
			state.floorInitial = action.payload;
		},
		loadingReducer: (state, action) => {
			state.loading = action.payload;
		},
		loadingDarkReducer: (state, action) => {
			state.loadingDark = action.payload;
		},
		mouseListenerReducer: (state, action) => {
			state.mouseListener = action.payload;
		},
		closeAllFiltersReducer: (state, action) => {
			state.closeAllFilters = action.payload;
		},
		totemReducer: (state, action) => {
			state.totem = action.payload;
		},
		clear: () => ({
			// roomvoService
			compareEnv: false,
			settings: {},
			vendor_data: {},
			lang: '',
			exclusiveCompany: null,
			pdv: '',
			company: '',
			companyOld: '',
			rooms: null,
			qrCodeRoom: '',
			visitor_id: null,
			vendor_id: null,
			room: null,
			product: null,
			productCompare: null,
			productList: null,
			floorSelected: {},
			wallSelected: {},
			floorSelectedCompare: {},
			wallSelectedCompare: {},
			device: false,
			imgDataInitial: '',
			floorInitial: '',
			initialTour: false,
			tour: false,
			step: 1,
			loading: false,
			loadingDark: false,
			productZoom: null,
			totem: null,
		}),
	},
});

export const {
	settingsReducer,
	compareEnvReducer,
	initReducer,
	companyReducer,
	exclusiveCompanyReducer,
	companyOldReducer,
	pdvReducer,
	langReducer,
	roomsReducer,
	qrCodeRoomReducer,
	visitorIdReducer,
	vendorIdReducer,
	roomReducer,
	productReducer,
	productCompareReducer,
	productListReducer,
	floorSelectedReducer,
	wallSelectedReducer,
	floorSelectedCompareReducer,
	wallSelectedCompareReducer,
	deviceReducer,
	imgDataInitialReducer,
	floorInitialReducer,
	clearTour,
	clear,
	initialTourReducer,
	tourReducer,
	stepReducer,
	loadingReducer,
	mouseListenerReducer,
	loadingDarkReducer,
	closeAllFiltersReducer,
	productZoomReducer,
	totemReducer
} = sharedService.actions;

export default sharedService.reducer;
