const clear = (instance) => {
	if (instance) {
		clearTimeout(instance);
		document.onmousemove = null;
	}
	return false;
};

const create = (action, time, instanceReducer) => {
	let timeout;
	document.onmousemove = () => {
		clearTimeout(timeout);
		timeout = setTimeout(action, time);
		instanceReducer(timeout);
	};
};

export default { clear, create };
